@import url('https://fonts.googleapis.com/css?family=Rubik:400,500&display=swap');
.DriversListContainer .row {
	margin-top: 5px;
	margin-bottom: 10px;
	font-size: 0.93em;
}
.DriversListContainer {
	color: #273846cc;
	font-family: 'Rubik', 'San serif';
}
.DriversListContainer .btn-outline-primary, .DriversListContainer .btn-outline-primary {
	color: #52606b;
	border-color: #52606b;
	background-color: transparent;
}
.DriversListContainer .btn-outline-primary:not(:disabled):not(.disabled):active, .DriversListContainer .btn-outline-primary:not(:disabled):not(.disabled).active, .DriversListContainer .show > .btn-outline-primary.dropdown-toggle {
	color: #fff;
	background-color: #52606b;
	border-color: #52606b;
}
.driver-search-filter {
	position: relative;
}
.driver-search-filter .search-icon {
	position: absolute;
	right: 15px !important;
	top: 9px !important;
	color: #d7dce2;
}
.search-filter-preview {
	border: 1px dashed #52606b;
	border-radius: 15px;
	padding: 5px 10px;
	margin-right: 5px;
}
.search-filter-preview span {
	color: #273846cc;
	text-transform: capitalize;
	border-right: 1px solid #52606b;
	padding-right: 5px;
	margin-right: 5px;
}
.search-filter-preview {
	color: #0B80F9;
}
.driver-search-filter input {
	border-radius: 15px;
	border-width: 3px !important;
}
.driver-list-button {
	color: #0B80F9 !important;
	cursor: pointer;
}

.driver-list-button:hover {
	text-decoration: underline !important;
}

.DriversListContainer .btn-outline-primary:hover, .DriversListContainer .btn-outline-primary:active, .DriversListContainer .btn-outline-primary:focus,
.DriversListContainer .btn-outline-primary:not(:disabled):not(.disabled):active {
	color: #fff;
	background-color: #52606b;
	border-color: #52606b;
}

.DriversListContainer .nav-pills .nav-item {
	margin: 10px;
}
.popover {
	max-width: 500px;
}
.DriversListContainer .btn-link, .DriversListContainer a {
	color: #52606b;
	text-decoration: none;
}
.DriversListContainer .uppercase {
	text-transform: uppercase;
}
.DriversListContainer .capitalize {
	text-transform: lowercase;
}
.DriversListContainer .capitalize::first-letter {
	text-transform: capitalize;
}
.DriversListContainer .linkHoverEffect {
	position: relative;
}
.DriversListContainer .linkHoverEffect:hover:after {
	width: 100%;
	-webkit-transition: all 0.2s ease-in-out;
	-moz-transition: all 0.2s ease-in-out;
	-o-transition: all 0.2s ease-in-out;
	-ms-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out;
}
.DriversListContainer .linkHoverEffect:after {
	position: absolute;
	content: "";
	width: 0%;
	margin-top: 4px;
	height: 2px;
	background-color: #52606b;
	left: 0;
	bottom: 0;
	-webkit-transition: all 0.2s ease-in-out;
	-moz-transition: all 0.2s ease-in-out;
	-o-transition: all 0.2s ease-in-out;
	-ms-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out;
}
i {
	cursor: pointer;
}
.DriversListContainer .alert {
	text-align: center;
	border: none;
}
.DriversListContainer .alert-danger {
	background-color: #FF4F4F;
	color: #fff;

}
.DriversListContainer .danger {
	color: #FF4F4F;
}
.DriversListContainer .alert-primary {
	background-color: #273846cc;
	color: #fff;
}
.DriversListContainer .card {
	box-shadow: 3px 3px 9px 2px rgba(202, 202, 202, 0.21);
	border: none;
	border-radius: 8px;
}

.DriversListContainer .table th, .table td {
	border: none;
	padding-bottom: 10px;
	padding-top: 10px;
}

.DriversListContainer .table tr {
	border-bottom: 1px solid rgba(202, 202, 202, 0.21);
	margin: 10px;
	color: #273846cc;
}
.DriversListContainer .table th {
	text-transform: uppercase;
	color: rgba(42, 53, 84, 0.52);
	font-size: 0.8em;
	font-weight: 500;
	vertical-align: middle;
	letter-spacing: 0.005em;
}

.shortText {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	max-width: 300px;
}

.driver-detail-actions {
	padding-right: 1.5rem;
	margin: 0;
}
.driver-detail-actions li {
	display: inline-block;
	margin-right: 10px;
}
.driver-detail-actions li:not(:first-child) {
	border-left: 1px solid #e1e1e1;
	padding-left: 10px;
}

/* Style Input Range Slider */

input[type=range] {
	-webkit-appearance: none;
	width: 100%;
}
input[type=range]:focus {
	outline: none;
}
input[type=range]::-webkit-slider-runnable-track {
	width: 100%;
	height: 8.4px;
	cursor: pointer;
	animate: 0.2s;
	background: #273846cc;
	border-radius: 2px;
}
input[type=range]::-webkit-slider-thumb {
	border: 1px solid #273846cc;
	height: 25px;
	width: 25px;
	border-radius: 8px;
	background: #ffffff;
	cursor: pointer;
	-webkit-appearance: none;
	margin-top: -8px;
}
input[type=range]:focus::-webkit-slider-runnable-track {
	background: #273846cc;
}
input[type=range]::-moz-range-track {
	width: 100%;
	height: 8.4px;
	cursor: pointer;
	animate: 0.2s;
	background: #273846cc;
	border-radius: 2px;
}
input[type=range]::-moz-range-thumb {
	border: 1px solid #273846cc;
	height: 25px;
	width: 25px;
	border-radius: 8px;
	background: #ffffff;
	cursor: pointer;
}
input[type=range]::-ms-track {
	width: 100%;
	height: 8.4px;
	cursor: pointer;
	animate: 0.2s;
	background: transparent;
	border-color: transparent;
	border-width: 16px 0;
	color: transparent;
}
input[type=range]::-ms-fill-lower {
	background: #273846cc;
	border-radius: 8px;
	box-shadow: 1px 1px 1px #273846cc, 0px 0px 1px #273846cc;
}
input[type=range]::-ms-fill-upper {
	background: #273846cc;
	border-radius: 8px;
	box-shadow: 1px 1px 1px #273846cc, 0px 0px 1px #0d0d0d;
}
input[type=range]::-ms-thumb {
	box-shadow: 1px 1px 1px #273846cc, 0px 0px 1px #000000;
	height: 25px;
	width: 25px;
	border-radius: 8px;
	background: #ffffff;
	cursor: pointer;
}
input[type=range]:focus::-ms-fill-lower {
	background: #273846cc;
}
input[type=range]:focus::-ms-fill-upper {
	background: #273846cc;
}

.DriversListContainer .filter-container .driver-search-filter {
	margin-bottom: 1rem
}

.DriversListContainer row.filter-driver-container col {
	align-self: center;
}

.drivers-list-container {
	/* overflow-y: auto; */
}

.drivers-list-container::-webkit-scrollbar-track {
	-webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3);
}

.drivers-list-container::-webkit-scrollbar {
	width: 5px;
	height: 5px;
}

.drivers-list-container::-webkit-scrollbar-thumb {
	background-color: #303e67;
	outline: 1px solid #303e67;
}


.DriversListContainer .badge {
	cursor: pointer;
	margin: 3px;
	font-weight: 400 !important;
	font-size: 0.65em;
	text-transform: uppercase;
	padding: 5px;
}
.drivers-list-container {
	/* max-height: 60vh;
	overflow-y: auto; */
}
.driver-icon-wrapper {
	border-right: 1px solid #e1e1e1;
	-webkit-align-self: center;
	align-self: center;
	text-align: center;
}
.driver-detail-block {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	font-size: 13px;
}
.driver-list-search-filter {
	position: relative;
}
.driver-list-search-filter .search-icon {
	position: absolute;
	right: 15px !important;
	top: 9px !important;
	color: #d7dce2;
}
.driver-list-search-filter input {
	border-radius: 15px;
	border-width: 3px !important;
}
.DriversListContainer .filter-container .driver-list-search-filter {
	margin-bottom: 1rem
}
/* End of Input Range Slider style */