@import url(https://fonts.googleapis.com/css?family=Rubik:400,500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css?family=Rubik:400,500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css?family=Rubik:400,500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css?family=Rubik:400,500&display=swap);
@import url(https://fonts.googleapis.com/css?family=Rubik:400,500&display=swap);
@import url(https://fonts.googleapis.com/css?family=Rubik:400,500&display=swap);
@import url(https://fonts.googleapis.com/css?family=Rubik:400,500&display=swap);
@import url(https://fonts.googleapis.com/css?family=Rubik:400,500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css?family=Rubik:400,500&display=swap);
@import url(https://fonts.googleapis.com/css?family=Rubik:400,500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css?family=Rubik:400,500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css?family=Rubik:400,500&display=swap);
@import url(https://fonts.googleapis.com/css?family=Rubik:400,500&display=swap);
@import url(https://fonts.googleapis.com/css?family=Rubik:400,500&display=swap);
@import url(https://fonts.googleapis.com/css?family=Rubik:400,500&display=swap);
@import url(https://fonts.googleapis.com/css?family=Rubik:400,500&display=swap);
body, html {
  margin: 0;
  padding: 0;
  font-size: 14px;
  font-family: 'Rubik', 'San serif' !important;
  color: #525f7f !important;
  background-color: #f2f3f59c !important;
  overflow-x: hidden;
}
.bos-bg-blue-light {
  background-color: #0066cc !important;
}
.bos-bg-blue {
  background-color: #3e4c6d !important;
  border-color: #3e4c6d !important;
}
.bos-bg-black {
  background-color: #1a1c1e !important;
}
.badge {
  font-weight: 500 !important;
  background-color: #3e4c6d;
}
.badge-success {
  color: #fff;
  background-color: #28a745;
}
.badge-danger {
  color: #fff;
  background-color: #dc3545;
}
.badge-secondary {
  color: #fff;
  background-color: #6c757d;
}
.badge-primary {
  color: #fff;
  background-color: #3e4c6d;
}
.badge-blue {
  color: #fff;
  background-color: blue;
}
.badge-warning {
  color: #212529;
  background-color: #ffc107;
}
b, strong {
  font-weight: 500 !important;
}
.bos-left-nav.show {
  font-family: 'Poppins', 'Rubik', 'San serif' !important;
  display: inline-block;
  position: fixed;
  top: 0;
  background-color: #1A1C1E;
  padding-top: 40px;
  padding-right: 60px;
  height: 100vh;
  z-index: 1;
  box-shadow: rgb(50 50 93 / 10%) 15px 0px 20px;
  overflow: scroll;
}
.bos-left-nav.show .bos-logo-item {
  margin-left: -9px;
  margin-right: -9px;
  text-align: center;
  border-radius: 0px;
  background-color: #ffffff52;
  padding-top: 0.5rem;
}
.bos-btn-primary {
  background-color: #0B80F9 !important;
  border: 1px solid #0B80F9 !important;
  border-radius: 7px !important;
  color: white !important;
}
.bos-btn-primary:hover {
  color: #41505d !important;
  background-color: transparent !important;
  border: 1px solid #41505d !important;
}
.color-blue-neon {
  color: #4D4EFF !important;
}
.color-blue {
  color: #0066cc !important
}
.bos-left-nav {
  display: none;
}
.bos-left-nav .bos-left-nav-footer {
  bottom: 0px;
  position: absolute;
  width: 100%;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 17px;
  padding-right: 33px;
}
.bos-left-nav .bos-left-nav-footer a:active,
.bos-left-nav .bos-left-nav-footer a:visited,
.bos-left-nav .bos-left-nav-footer a {
  color: #0066cc !important;
  text-decoration: none;
}
.bos-left-nav .bos-left-nav-footer a:hover {
  color: #fff !important;
  text-decoration: underline;
}
.bos-left-nav .menu-list-item-pin {
  float: right;
  padding: 10px 15px;
  color: #888B90;
}
.bos-left-nav .menu-list-item-pin.active-pin {
  color: #007bff;
}
.bos-left-nav .list-item-text {
  margin-left: 0.5rem;
}
.hamburger {
  display: inline-block;
  padding: 10px 50px;
  position: absolute;
  background-color: #FFF;
  z-index: 2;
  border-radius: 0px 0px 13px 0;
}

#root > .mainContent {
  display: inline-block;
  overflow: hidden;
  background-color: #f2f3f59c;
  padding: 30px;
  min-height: 100vh;
}

@media screen and (min-width: 768px) {
  /* For desktop: */
  .bos-left-nav.show {
    height: 100vh;
    display: inline-block;
    width: 15%;
    padding: 0px 0;
    border-right: 1px solid rgb(212 224 232 / 63%);
    overflow: auto;
    background-color: #1A1C1E;
    position: fixed;
  }
  #root > .mainContent {
    margin-left: 15%;
    width: 85%;
  }
  #root > .mainContent.full-width {
    margin-left: 0%;
    width: 100%;

  }
  .hamburger {
    display: none;
  }
}

.bos-left-nav ul {
  list-style-type: none;
  padding: 0px 0.75rem;
  max-height: 90vh;
  overflow-y: auto;
}

.bos-left-nav.show ul::-webkit-scrollbar {
  width: 7px;
}

.bos-left-nav.show ul::-webkit-scrollbar-track {
  box-shadow: inset 0 0 0px #fff;
}

.bos-left-nav.show ul::-webkit-scrollbar-thumb {
  background-color: #f2f3f59c;
  margin: 0px;
  outline: 1px solid #f2f3f59c;
  border-radius: 3px;
}

.bos-left-nav ul label::after {
  content: '';
  display: block;
  position: relative;
  width: 50%;
  border-top: 1px ridge #F3763D;
}
.bos-left-nav ul label.pinned-item-label::after {
  content: '';
  display: block;
  position: relative;
  width: 100%;
  border-top: 1px ridge #0066cc;
}
.bos-left-nav ul label.pinned-item-label {
  font-size: 11px;
  display: block;
  text-align: center;
  font-weight: 500;
  letter-spacing: 3px;
}
.bos-left-nav ul label {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  padding-top: 1rem;
  padding-bottom: 0.25rem;
  text-transform: uppercase;
  margin: 0px;
  font-size: 10px;
  color: #fff;
  letter-spacing: 0.5px;
}
.bos-left-nav ul li {
  border-radius: 15px;
}
.bos-left-nav li a {
  padding: 0.65rem 0.75rem;
  font-size: 12px;
  display: inline-block;
  letter-spacing: 0.5px;
  color: #888B90;
}
.bos-left-nav a:hover,
.bos-left-nav a:focus {
  text-decoration: none;
  /* font-weight: 500; */
  /* background-color: #303334; */
  color: #FAFAFA;
}

.bos-left-nav li.active a {
  color: #FAFAFA;
}
.bos-left-nav li.active {
  background-color: #303334;
}

.bos-left-nav .sidebar-child-list {
  padding-top: 1rem;
}
.bos-left-nav .sidebar-child-list li a {
  padding: 0.65rem 0rem 0.65rem 3.5rem;
}
.bos-left-nav .sidebar-child-list li.active a {
  background-color: #3894da;
}


#root > .mainContent .mainHeader {
  display: inline-block;
  width: 100%;
  border-bottom: 1px solid #e9eaf359;
  padding-bottom: 25px;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
.modal-content {
  border: none !important;
}
/* #root > .mainContent .mainHeader .right {
  display: inline-block;
  float: right;
  text-align: right;
  width: 100%;
  color: #525f7f;
  vertical-align: -webkit-baseline-middle;
} */
/* #root > .mainContent .mainHeader span,  */

.mainContent .mainHeader .header-greetings {
  display: inline-block;
  cursor: default;
  text-transform: capitalize;
  margin: 0;
  padding-top: 17px;
}
#root > .mainContent .mainHeader a {
  color: #525f7f;
  text-decoration: none;
  cursor: pointer;
}
/* #root > .mainContent .mainHeader .branch-dropdown-wrapper {
  min-width: 175px;
} */
/* #root > .mainContent .mainHeader .bos-branch-select span {
  display: none;
} */
/* #root > .mainContent .mainHeader .bos-branch-select .bos-branch-select__option {
  background-color: #fff;
  color: #212529 !important;
  display: block;
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  font-size: .875rem;
} */
#root > .mainContent .mainHeader .bos-branch-select .bos-branch-select__control {
  background-color: #06c !important;
  color: #fff;
  border: none;
  border-radius: 0;
  min-width: 200px;
}
#root > .mainContent .mainHeader .bos-branch-select .bos-branch-select__multi-value {
  background-color: transparent;
  border: 1px solid #f2f3f59c;
}
#root > .mainContent .mainHeader .bos-branch-select .bos-branch-select__multi-value__label,
#root > .mainContent .mainHeader .bos-branch-select .bos-branch-select__single-value,
#root > .mainContent .mainHeader .bos-branch-select .bos-branch-select__value-container,
#root > .mainContent .mainHeader .bos-branch-select .bos-branch-select__input,
#root > .mainContent .mainHeader .bos-branch-select .bos-branch-select__dropdown-indicator,
#root > .mainContent .mainHeader .bos-branch-select .bos-branch-select__placeholder,
#root > .mainContent .mainHeader .bos-branch-select .bos-branch-select__indicator {
  color: #fff;
  text-align: left;
}

/* #root > .mainContent .mainHeader .bos-branch-select .bos-branch-select__indicator:focus-visible,
#root > .mainContent .mainHeader .bos-branch-select:focus-visible {
  outline: none;
}
#root > .mainContent .mainHeader .bos-branch-select .bos-branch-select__indicator {
  background-color: #1a1c1e;
}
#root > .mainContent .mainHeader .bos-branch-select .bos-branch-select__option {
  background-color: #fff;
  padding-left: 15px;
  text-align: left;
}
#root > .mainContent .mainHeader .bos-branch-select .bos-branch-select__option:hover {
  background-color: #f8f9fa;
}
 */
@media (min-width: 1200px) {
  .container {
    max-width: 1400px !important;
  }
}
.Checkout {
  margin: 0 auto;
  max-width: 800px;
  box-sizing: border-box;
  padding: 0 5px;
}

.StripeElement {
  display: block;
  margin: 10px 0 20px 0;
  max-width: 500px;
  padding: 10px 14px;
  box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px, rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
  border-radius: 4px;
  background: white;
}

.StripeElement--focus {
  box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px, rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
  transition: all 150ms ease;
}

.linkHoverEffect {
  position: relative;
  color: #52606b;
  text-decoration: none;
  font-weight: 400;
}
.linkHoverEffect:hover:after {
  width: 100%;
  transition: all 0.2s ease-in-out;
}
.linkHoverEffect:after {
  position: absolute;
  content: "";
  width: 0%;
  margin-top: 4px;
  height: 2px;
  background-color: #52606b;
  left: 0;
  bottom: 0;
  transition: all 0.2s ease-in-out;
}
body::-webkit-scrollbar {
  width: 10px;
}

body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #f2f3f59c;
}

body::-webkit-scrollbar-thumb {
  background-color: #4f6092;
  margin: 5px;
  outline: 1px solid #4f6092;
  border-radius: 10px;
}
.section-404 {
  background-color: white;
  text-align: center;
  text-transform: uppercase;
  height: 50vh;
  padding-top: 13vh;
  margin-top: 10vh;
  border-radius: 55px;
  box-shadow: 0 0 25px 0px #e7e7e7;
  -webkit-box-shadow: 0 0 25px 0px #e7e7e7;
  -moz-box-shadow: 0 0 25px 0px #e7e7e7;
}
.section-404 p {
  font-size: 72px;
  font-weight: 600;
}
.section-404 .btn {
  border-radius: 20px;
  font-size: 14px;
  padding: 5px 25px;
}
.limit-text {
  max-width: 125px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}
.limit-text-long {
  max-width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}
.bos-badge-blue {
  background-color: #0066cc !important;
  font-size: 12px !important;
  letter-spacing: 0.5px;
  text-transform: uppercase;
}
.bos-badge-small {
  font-size: 9px !important;
  letter-spacing: 0.25px;
  padding: 0.25em !important;
}

.bos-custom-select .bos-select__dropdown-indicator {
  padding: 4px 8px;
}
.bos-custom-select .bos-select__control {
  border-radius: 7px;
  border-color: hsl(0deg 6% 91%);
  min-height: 33px;
}
.bos-custom-select .bos-select__indicator {
  color: #303e67 !important;
}
.bos-custom-select, .bos-custom-select .bos-select__value-container, .bos-custom-select .bos-select__placeholder {
  font-weight: 500;
  color: #303e67 !important;
  font-size: 13px;
}
.bos-custom-select .bos-select__multi-value {
  border-radius: 3px !important;
}
.bos-custom-select .bos-select__placeholder {
  text-transform: uppercase;
}
.bos-custom-select .bos-select__multi-value__label {
  color: #79829d !important;
  font-size: 12px !important;
}
.bos-custom-select .bos-select__multi-value__label small {
  padding-top: 2px;
}
.bos-custom-select .select-selected {
  background-color: DodgerBlue;
}
.bos-custom-tooltip {
  opacity: 1 !important;
}
.bos-custom-tooltip .tooltip-inner {
  min-width: 175px;
  max-width: 300px;
  padding: 0.25rem 1rem 0.5rem 1rem;
  width: auto;
  text-align: left;
  background-color: #F2F2F2;
  color: black;
  border: 1px solid #d7dce2
}
.bos-custom-tooltip .tooltip-inner label {
  font-size: 1.5em;
  display: block;
  border-bottom: 1px solid #d7dce2;
  text-align: center;
}
.bos-custom-tooltip .tooltip-inner .bos-custom-tooltip-header {
  font-size: 1em;
  /* font-weight: 500; */
}
.bos-custom-tooltip .tooltip-inner .bos-custom-tooltip-body {
  padding-top: 1rem;
  font-size: 0.85em;
  line-height: 1.5;
  margin-bottom: 0.5rem;
}
.bos-custom-tooltip .tooltip-inner .bos-custom-tooltip-footer button {
  font-size: 0.95em;
  padding: 0.25rem 1rem;
  background-color: #F2F2F2;
  color: black;
  border: 1px solid #d7dce2
}
.bos-custom-tooltip .tooltip-inner .bos-custom-tooltip-footer button:focus,
.bos-custom-tooltip .tooltip-inner .bos-custom-tooltip-footer button:active,
.bos-custom-tooltip .tooltip-inner .bos-custom-tooltip-footer button:hover {
  background-color: #cacaca;
  color: black;
}
.listing-checkbox-wrapper {
  vertical-align: middle;
  text-align: center;
}
.listing-checkbox-wrapper:hover .listing-checkbox .unchecked,
.listing-checkbox-wrapper .listing-counter {
  display: block;
  font-size: 12px;
}
.listing-checkbox-wrapper:hover .listing-counter,
.listing-checkbox-wrapper .listing-checkbox .unchecked {
  display: none;
}
.bos-custom-link {
  color: inherit !important;
  text-decoration: none;
  font-weight: 500;
}
.bos-custom-link:hover {
  color: blue !important;
  text-decoration: underline;
  cursor: pointer;
}
.driver-in-office-row {
  background-color: #1a87542e;
  border-bottom: 1px solid white !important;
}
.form-control-file {
  font-size: 1.1em;
  opacity: .8;
}
.NotificationUpdate {
    position: fixed !important;
    top: 20px;
    min-width: 30px;
    min-height: 30px;
    right: -1000px;
    opacity: 0.1;
    margin: 0;
    z-index: 10000;
    background: #3e4c6d;
    border: none;
    border-radius: 40px 0px 0px 40px;
    color: #ffffff;
    box-shadow: 4px 4px 9px 3px rgb(205 211 226 / 68%);
    padding: 8px 20px;
    transition: right .35s ease-in-out;
}
.NotificationUpdate.show {
    right: 0;
    opacity: 1;
}
.NotificationUpdate p {
    margin: 0;
}
.DriversListContainer .row {
	margin-top: 5px;
	margin-bottom: 10px;
	font-size: 0.93em;
}
.DriversListContainer {
	color: #273846cc;
	font-family: 'Rubik', 'San serif';
}
.DriversListContainer .btn-outline-primary, .DriversListContainer .btn-outline-primary {
	color: #52606b;
	border-color: #52606b;
	background-color: transparent;
}
.DriversListContainer .btn-outline-primary:not(:disabled):not(.disabled):active, .DriversListContainer .btn-outline-primary:not(:disabled):not(.disabled).active, .DriversListContainer .show > .btn-outline-primary.dropdown-toggle {
	color: #fff;
	background-color: #52606b;
	border-color: #52606b;
}
.driver-search-filter {
	position: relative;
}
.driver-search-filter .search-icon {
	position: absolute;
	right: 15px !important;
	top: 9px !important;
	color: #d7dce2;
}
.search-filter-preview {
	border: 1px dashed #52606b;
	border-radius: 15px;
	padding: 5px 10px;
	margin-right: 5px;
}
.search-filter-preview span {
	color: #273846cc;
	text-transform: capitalize;
	border-right: 1px solid #52606b;
	padding-right: 5px;
	margin-right: 5px;
}
.search-filter-preview {
	color: #0B80F9;
}
.driver-search-filter input {
	border-radius: 15px;
	border-width: 3px !important;
}
.driver-list-button {
	color: #0B80F9 !important;
	cursor: pointer;
}

.driver-list-button:hover {
	text-decoration: underline !important;
}

.DriversListContainer .btn-outline-primary:hover, .DriversListContainer .btn-outline-primary:active, .DriversListContainer .btn-outline-primary:focus,
.DriversListContainer .btn-outline-primary:not(:disabled):not(.disabled):active {
	color: #fff;
	background-color: #52606b;
	border-color: #52606b;
}

.DriversListContainer .nav-pills .nav-item {
	margin: 10px;
}
.popover {
	max-width: 500px;
}
.DriversListContainer .btn-link, .DriversListContainer a {
	color: #52606b;
	text-decoration: none;
}
.DriversListContainer .uppercase {
	text-transform: uppercase;
}
.DriversListContainer .capitalize {
	text-transform: lowercase;
}
.DriversListContainer .capitalize::first-letter {
	text-transform: capitalize;
}
.DriversListContainer .linkHoverEffect {
	position: relative;
}
.DriversListContainer .linkHoverEffect:hover:after {
	width: 100%;
	transition: all 0.2s ease-in-out;
}
.DriversListContainer .linkHoverEffect:after {
	position: absolute;
	content: "";
	width: 0%;
	margin-top: 4px;
	height: 2px;
	background-color: #52606b;
	left: 0;
	bottom: 0;
	transition: all 0.2s ease-in-out;
}
i {
	cursor: pointer;
}
.DriversListContainer .alert {
	text-align: center;
	border: none;
}
.DriversListContainer .alert-danger {
	background-color: #FF4F4F;
	color: #fff;

}
.DriversListContainer .danger {
	color: #FF4F4F;
}
.DriversListContainer .alert-primary {
	background-color: #273846cc;
	color: #fff;
}
.DriversListContainer .card {
	box-shadow: 3px 3px 9px 2px rgba(202, 202, 202, 0.21);
	border: none;
	border-radius: 8px;
}

.DriversListContainer .table th, .table td {
	border: none;
	padding-bottom: 10px;
	padding-top: 10px;
}

.DriversListContainer .table tr {
	border-bottom: 1px solid rgba(202, 202, 202, 0.21);
	margin: 10px;
	color: #273846cc;
}
.DriversListContainer .table th {
	text-transform: uppercase;
	color: rgba(42, 53, 84, 0.52);
	font-size: 0.8em;
	font-weight: 500;
	vertical-align: middle;
	letter-spacing: 0.005em;
}

.shortText {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	max-width: 300px;
}

.driver-detail-actions {
	padding-right: 1.5rem;
	margin: 0;
}
.driver-detail-actions li {
	display: inline-block;
	margin-right: 10px;
}
.driver-detail-actions li:not(:first-child) {
	border-left: 1px solid #e1e1e1;
	padding-left: 10px;
}

/* Style Input Range Slider */

input[type=range] {
	-webkit-appearance: none;
	width: 100%;
}
input[type=range]:focus {
	outline: none;
}
input[type=range]::-webkit-slider-runnable-track {
	width: 100%;
	height: 8.4px;
	cursor: pointer;
	animate: 0.2s;
	background: #273846cc;
	border-radius: 2px;
}
input[type=range]::-webkit-slider-thumb {
	border: 1px solid #273846cc;
	height: 25px;
	width: 25px;
	border-radius: 8px;
	background: #ffffff;
	cursor: pointer;
	-webkit-appearance: none;
	margin-top: -8px;
}
input[type=range]:focus::-webkit-slider-runnable-track {
	background: #273846cc;
}
input[type=range]::-moz-range-track {
	width: 100%;
	height: 8.4px;
	cursor: pointer;
	animate: 0.2s;
	background: #273846cc;
	border-radius: 2px;
}
input[type=range]::-moz-range-thumb {
	border: 1px solid #273846cc;
	height: 25px;
	width: 25px;
	border-radius: 8px;
	background: #ffffff;
	cursor: pointer;
}
input[type=range]::-ms-track {
	width: 100%;
	height: 8.4px;
	cursor: pointer;
	animate: 0.2s;
	background: transparent;
	border-color: transparent;
	border-width: 16px 0;
	color: transparent;
}
input[type=range]::-ms-fill-lower {
	background: #273846cc;
	border-radius: 8px;
	box-shadow: 1px 1px 1px #273846cc, 0px 0px 1px #273846cc;
}
input[type=range]::-ms-fill-upper {
	background: #273846cc;
	border-radius: 8px;
	box-shadow: 1px 1px 1px #273846cc, 0px 0px 1px #0d0d0d;
}
input[type=range]::-ms-thumb {
	box-shadow: 1px 1px 1px #273846cc, 0px 0px 1px #000000;
	height: 25px;
	width: 25px;
	border-radius: 8px;
	background: #ffffff;
	cursor: pointer;
}
input[type=range]:focus::-ms-fill-lower {
	background: #273846cc;
}
input[type=range]:focus::-ms-fill-upper {
	background: #273846cc;
}

.DriversListContainer .filter-container .driver-search-filter {
	margin-bottom: 1rem
}

.DriversListContainer row.filter-driver-container col {
	-webkit-align-self: center;
	        align-self: center;
}

.drivers-list-container {
	/* overflow-y: auto; */
}

.drivers-list-container::-webkit-scrollbar-track {
	-webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3);
}

.drivers-list-container::-webkit-scrollbar {
	width: 5px;
	height: 5px;
}

.drivers-list-container::-webkit-scrollbar-thumb {
	background-color: #303e67;
	outline: 1px solid #303e67;
}


.DriversListContainer .badge {
	cursor: pointer;
	margin: 3px;
	font-weight: 400 !important;
	font-size: 0.65em;
	text-transform: uppercase;
	padding: 5px;
}
.drivers-list-container {
	/* max-height: 60vh;
	overflow-y: auto; */
}
.driver-icon-wrapper {
	border-right: 1px solid #e1e1e1;
	-webkit-align-self: center;
	align-self: center;
	text-align: center;
}
.driver-detail-block {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: row;
	        flex-direction: row;
	-webkit-justify-content: space-between;
	        justify-content: space-between;
	font-size: 13px;
}
.driver-list-search-filter {
	position: relative;
}
.driver-list-search-filter .search-icon {
	position: absolute;
	right: 15px !important;
	top: 9px !important;
	color: #d7dce2;
}
.driver-list-search-filter input {
	border-radius: 15px;
	border-width: 3px !important;
}
.DriversListContainer .filter-container .driver-list-search-filter {
	margin-bottom: 1rem
}
/* End of Input Range Slider style */
.update-btn {
    background-color: #0B80F9;
    border: 1px solid #0B80F9;
    border-radius: 7px;
}
.update-btn:hover {
    color: #41505d;
    background-color: transparent;
    border: 1px solid #41505d;
}
.car-document-modal {
    font-family: "Poppins" !important;
    min-width: 1300px;
}

.car-document-modal .modal-body {
    min-height: 33vh;
}
.car-document-modal .modal-body {
    border-radius: 0px !important;
    max-height: 550px;
    overflow-y: auto;
    overflow-x: hidden;
}
.car-document-modal .modal-content .btn {
    border-radius: 13px;
}
.car-document-modal .modal-header {
    -webkit-justify-content: center;
            justify-content: center;
    text-transform: uppercase;
}
.car-document-modal .card-body {
    text-align: center;
    padding: 0;
}

.car-document-modal .doc-toggle-btn {
    width: 100%;
    font-size: 80%;
    font-weight: 600;
    margin: 0.5rem 0px;
    border-radius: 0px !important;
    border: none;
    color: black;
    background-color: rgb(213, 236, 255);
}
.car-document-modal .doc-toggle-btn.deleted {
    background-color: rgb(255 213 213);
}
.car-document-modal .doc-toggle-btn:hover,
.car-document-modal .doc-toggle-btn.active {
    background-color: rgb(0, 140, 255) !important;
}
.car-document-modal .doc-toggle-btn.deleted:hover,
.car-document-modal .doc-toggle-btn.deleted.active {
    background-color: rgb(255, 64, 64) !important;
}
.car-document-modal .card-body .image-details-wrapper {
    margin-top: 1rem;
    border-top: 2px solid rgb(196, 196, 196);
    background-color: aliceblue;
    text-align: left;
    padding: 0.5rem;
}
.car-document-modal .image-details-wrapper label {
    font-size: 80%;
    font-weight: 600;
    margin-bottom: 0px;
    display: block;
}
.car-document-modal .image-details-wrapper small {
    display: block;
    padding-bottom: 3px !important;
}
.car-document-modal .modal-body::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}
.car-document-modal .modal-body::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3);
}
.car-document-modal .modal-body::-webkit-scrollbar-thumb {
    background-color: #303e67;
    outline: 1px solid #303e67;
}
/* # Document card container should have three cards in a row */
.DocumentCardContainer {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-justify-content: start;
            justify-content: start;
    -webkit-align-items: center;
            align-items: center;
    margin: 0 0 20px 0;
}

.DocumentCard {
    width: calc(33.33% - 2em);
    margin: 0 2em 2em 0;
    box-sizing: border-box;
    padding: 15px 30px;
    background-color: rgb(230, 235, 242);
    border: none;
    border-radius: 20px;
    cursor: pointer;
}
/* on mobile DocumentCard width should be 100% */



.DocumentCardPreviewEmbed {
    vertical-align: middle;
    border-style: none;
    min-width: 100%;
    min-height: 100%;
    overflow: hidden !important;
}

.DocumentCardPreview {
    margin: 20px 0;
    height: 200px;
    display: inline-block;
    width: 100%;
    cursor: pointer;
    overflow: hidden;
    border-radius: 10px;
}

.DocumentCardHeader {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
    font-size: 1.3rem;
}

.DocumentCardTitle {
    font-weight: 500;
    color: rgb(19, 23, 29);
}

.DocumentCardFooter {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
    font-size: 12px;
    min-height: 20px;
}
.DocumentCardFooter .badge {
    font-size: 12px;
}
.modal-backdrop.show {
    opacity: 0.9;
}
.DocViewerModal .modal-content {
    width: 100%;
    height: 100%;
    margin: 0 auto;
}

.DocViewerModal.modal-dialog {
    width: 95vw;
    height: 95vh;
    margin: 0 auto;
    padding: 5vh;
    overflow: hidden;
    max-width: 100vw;
}

.DocViewerModal .modal-body {
    overflow: scroll;
}
.DocViewerModal .DocViewerFrame {
    width: 100%;
    min-height: 100%;
}
.DocViewerImage {
    margin: 0 auto;
    display: block;
    border-radius: 4px;
    max-height: 100vh;
}
.DocViewerModalHeader {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
    font-size: 1.3rem;
    margin-bottom: 20px;
    padding: 0 1rem;
    border-bottom: 1px solid #dee2e6;
}

.DocViewerModalActions {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
    font-size: 1.3rem;
    grid-gap: 20px;
    gap: 20px;
}

.DocViewerModalFooter {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
    font-size: 1.3rem;
    margin-top: 20px;
    padding: 1rem 1rem;
    border-top: 1px solid #dee2e6;
}

.DocViewerFooterAction {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
    font-size: 1.3rem;
    grid-gap: 20px;
    gap: 20px;
}
/* .datetime-renderer #custom-tooltip-top .conversion-table td, */
#datetime-conversion-tooltip{
    min-width: 275px !important;
    background-color: #fff;
    color: black;
    padding: 0 !important;
    margin: 0 !important;
    border: 1px solid #d7dce2;
    font-size: 80% !important;
}
#datetime-conversion-tooltip table{
    width: 100%;
    text-align: left !important;
    margin: 0px !important;
    padding-top: 5px !important;
    padding-bottom: 5px !important;
}
#datetime-conversion-tooltip table th{
    background-color: #d7dce2 !important;
}
#datetime-conversion-tooltip table tr{
    border-bottom: 1px solid #d7dce2;
}
#datetime-conversion-tooltip table th,
#datetime-conversion-tooltip table td{
    padding-left:10px;
    padding-right:10px;
    padding-top: 5px;
    padding-bottom: 5px;
}
.confirmation-popup {
    border: solid 10px white !important;
    border-radius: 5px !important;
    background-color: white;
}
.confirmation-popup .modal-content {
    padding: 0px !important;
    background-color: white;

}
.confirmation-popup .modal-header {
    text-transform: uppercase;
    border-radius: 5px;
    color: #303e67;
}
.confirmation-popup .modal-footer .primary-btn {
    background-color: #303e67 !important;
    border-radius: 7px !important;
    font-size: 13px !important;
    font-weight: 400 !important;
    color: white !important;
    cursor: pointer !important;
    height: 35px !important;
    width: 60px !important;
    text-transform: uppercase;
}
.confirmation-popup .modal-footer .primary-action-btn {
    border-radius: 7px !important;
    font-size: 13px !important;
    font-weight: 400 !important;
    height: 35px !important;
    color: white !important;
    cursor: pointer !important;
    text-transform: uppercase;
}
.confirmation-popup .modal-footer .secondary-btn {
    background-color: white !important;
    border-radius: 7px !important;
    font-size: 13px !important;
    font-weight: 400 !important;
    color: black !important;
    cursor: pointer !important;
    height: 35px !important;
    width: 60px !important;
    text-transform: uppercase;
}
.confirmation-popup .modal-body p {
    font-size: 15px !important;
    font-weight: 500 !important;
    text-align: left;
}
.search-places-wrapper {
    position: relative;
    width: 100%;
}

.bos-custom-input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
}

.autocomplete-suggestions {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: white;
    border: 1px solid #ccc;
    border-top: none;
    border-radius: 4px;
    max-height: 200px;
    overflow-y: auto;
    z-index: 1000;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    padding: 0;
    margin: 0;
}

.suggestion-item {
    padding: 10px;
    cursor: pointer;
}

.suggestion-item:hover {
    background-color: #f0f0f0;
}
.NewReservationModal {
    min-width: 1000px !important;
}
.NewReservationModal .modal-header .modal-title button {
    position: absolute;
    right: 2%;
    color: white;
    font-size: 25px;
    top: 5px;
    border: none;
}
.NewReservationModal .modal-body {
    padding: 1.5rem;
    /* max-height: 900px; */
    overflow-y: scroll;
}
.NewReservationModal .modal-content {
    padding: 0;
    min-width: 999px !important;
    min-height: 700px;
}
.NewReservationModal .modal-footer {
    display: block;
    border: none;
}
.NewReservationModal .modal-header {
    -webkit-justify-content: center;
            justify-content: center;
}
.NewReservationModal .modal-header .modal-title {
    font-weight: 400;
    font-size: 2em;
    text-transform: uppercase;
}
.NewReservationModal .reservation-modal-btn-primary {
    background-color: #303e67;
    border-radius: 7px;
    font-size: 13px;
    font-weight: 400 !important;
    cursor: pointer;
}
.NewReservationModal .reservation-modal-btn-secondary {
    border-radius: 7px;
    font-size: 13px;
    font-weight: 400 !important;
    cursor: pointer;
}
.NewReservationModal .price-card {
    background: linear-gradient(145deg, rgba(31, 27, 27, 1) 0%, #475579, #465579);
    border-color: transparent;
    box-shadow: -10px 10px 6px 0px rgb(152 173 214 / 36%);
    color: white;
    padding: 0.8em 1em;
    border-radius: 10px;
    margin: 0px 0px 15px 35px;
}

.NewReservationModal .promo-card {
    background: linear-gradient(145deg, rgba(31, 27, 27, 1) 0%, #0082a5, #465579);
    border-color: transparent;
    box-shadow: -10px 10px 6px 0px rgb(152 173 214 / 36%);
    color: white;
    padding: 0.8em 1em;
    border-radius: 10px;
    margin: 0px 0px 15px 35px;

}

.NewReservationModal .price-card button {
    background-color: white;
    border-color: white;
    color: #3e4c6d;
    padding: 5px 20px;
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 500;
}
.NewReservationModal .promo-card button {
    background-color: white;
    border-color: white;
    color: #3e4c6d;
    padding: 5px 20px;
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 500;
}

.NewReservationModal .price-card .price {
    background-color: #E5E5E5;
    color: #000000;
}
.NewReservationModal .price-card .card-body {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
}
.NewReservationModal .promo-card .card-body {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
}

.NewReservationModal .price-card p, .NewReservationModal .card-title {
    font-size: 1.3em;
    margin-bottom: 0;
    line-height: 2em;
}
.NewReservationModal .promo-card p, .NewReservationModal .card-title {
    font-size: 1.3em;
    margin-bottom: 0;
    line-height: 2em;
}
.NewReservationModal .price-card .price-card-count {
    background: #c2ff71;
    padding: 5px 10px;
    border-radius: 15px;
    font-size: 12px;
    font-weight: 500;
    color: black;
    float: right;
    top: -2px;
    right: -7px;
    position: relative;
}
.NewReservationModal .card {
    border-color: transparent
}

.NewReservationModal .plan-detail-card {
    /* background: #475579; */
    background: linear-gradient(145deg, rgba(31, 27, 27, 1) 0%, #475579, #465579);
    color: white;
    padding: 0.75rem 1rem;
    margin-top: 20px;
    border-radius: 10px;
}
.NewReservationModal .plan-detail-card.active {
    background: #475579;
}

.NewReservationModal .promo-detail-card {
    /* background: #0082a5; */
    background: linear-gradient(145deg, rgba(30, 27, 27, 1) 0%, #0082a5, #465579);
    color: white;
    padding: 0.75rem 1rem;
    margin-top: 20px;
    border-radius: 10px;
}
.NewReservationModal .promo-detail-card .card-header {
    background: transparent !important;
    margin-left: 0;
    padding-left: 0;
}
.NewReservationModal .plan-detail-card .card-header {
    background: transparent !important;
    margin-left: 0;
    padding-left: 0;
}

.NewReservationModal .promo-detail-card.active {
    background: #0082a5;
}

.NewReservationModal .promo-detail-card label,
.NewReservationModal .plan-detail-card label {
    font-size: 1rem;
    font-weight: 500 !important;
    padding-bottom: 7px;
}
.NewReservationModal .price-card label,
.NewReservationModal .promo-detail-card label,
.NewReservationModal .plan-detail-card label {
    color: white;
    font-weight: 100;
    opacity: 1;
}
.NewReservationModal .plan-detail-card .plan-tiers label {
    display: block;
}
.NewReservationModal .plan-detail-card .plan-tiers p {
    font-size: 14px;
    width: 25%;
    display: inline-block;
    padding: 0px 5px;
    margin-right: 10px;
}

.NewReservationModal .plan-detail-card button {
    background-color: white;
    border-color: white;
    color: #3e4c6d;
    width: 55%;
    padding: 0;
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 500;
}
.NewReservationModal .plan-detail-card .card-body {
    padding: 0;
}
.NewReservationModal .promo-detail-card .card-body {
    padding: 0;
}
.NewReservationModal .plan-detail-card i.remove-plan span {
    font-family: 'Rubik';
}
.NewReservationModal .promo-detail-card i.remove-plan span {
    font-family: 'Rubik';
}
.NewReservationModal .plan-detail-card i.remove-plan {
    position: absolute;
    bottom: 20px;
    right: 25px;
    color: white;
    border: none;
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
}

.NewReservationModal .review-plan-details {
    border: 2px solid #e4c83c87;
    border-radius: 5px;
    margin: 0px 0px 15px 0px;
    padding-top: 15px;
    width: 100%;
}
.NewReservationModal .plan-detail-card i.remove-plan {
    position: absolute;
    bottom: 0px;
    right: 25px;
    color: white;
    border: none;
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
}
.NewReservationModal .card-header {
    font-weight: 600 !important;
    font-size: 15px !important;
    line-height: 2rem !important;
}
.NewReservationModal .card-footer {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
}
.NewReservationModal .card-text b {
    display: block;
    line-height: 10px;
    padding-top: 15px;
}
.NewReservationModal i.fa-check-square {
    color: #6dec6d;
    font-size: 18px;
    padding-top: 5px;
    padding-right: 5px;
}
.NewReservationModal .card-text b span {
    font-size: 24px;
}
/* .swiper-button-prev {
    left: -2px !important;
    color: black !important;
}
.swiper-button-next {
    right: -2px !important;
    color: black !important;
} */
.NewReservationModal i.switch {
    font-size: 25px;
    border: none;
}
.NewReservationModal .form-control {
    font-size: 14px;
}
.NewReservationModal a {
    cursor: pointer;
    text-decoration: underline;
}

.NewReservationModal .drivers-list {
    border: none;
    border-radius: 10px;
    padding: 10px;
    box-shadow: -1px 4px 10px -1px rgba(0, 0, 0, 0.4);
    -webkit-box-shadow: -1px 4px 10px -1px rgba(0, 0, 0, 0.4);
    -moz-box-shadow: -1px 4px 10px -1px rgba(0, 0, 0, 0.4);
    /* min-width: 250px; */
}

.NewReservationModal label {
    opacity: .85;
    letter-spacing: .75px;
    text-transform: uppercase;
    font-size: 80%;
    font-weight: 500;
    color: #000;
}
.NewReservationModal .car-name-years .car-name {
    max-width: 170px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.NewReservationModal .selection-container {
    border: 1px solid #E5E5E5;
    border-radius: 10px;
    text-align: center;
}

.NewReservationModal .selection-container .disable-car, .NewReservationModal .selection-container .disable-car:hover {
    color: gray;
    cursor: text;
}

.NewReservationModal .selected {
    color: #ffffff;
    background-color: #3e4c6d;
    border-radius: 10px;
}

.NewReservationModal .user-icon .fa {
    padding: 10px 13px;
    border-radius: 50%;
    position: relative;
    color: #3e4c6d;
    background-color: #ffffff;
}

.NewReservationModal .edit-item {
    position: relative;
}
.NewReservationModal .edit-item .edit-icon {
    position: absolute;
    right: 10px;
    top: 10px;
}
.NewReservationModal .edit-item .edit-icon {
    position: relative;
    position: absolute;
    right: 10px;
    top: 10px;
}
.selection-box {
    min-height: 100px;
    max-height: 150px;
    width: 533px;
    border-radius: 10px;
    margin: 15px;
    background-color: #FFFFFF;
    -webkit-justify-content: space-evenly;
            justify-content: space-evenly;
    padding: 2rem;
    cursor: pointer;
    z-index: 1000;
    border-radius: 10px;
    box-shadow: 0px 5px 5px 1px rgba(0, 0, 0, 0.25);
    -webkit-box-shadow: 0px 5px 10px 1px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0px 5px 3px 1px rgba(0, 0, 0, 0.25);
}
.selection-box-text {
    font-weight: 500;
    font-size: 15px;
    line-height: 24px;
    color: #949494;
}
.selection-box-icon {
    line-height: 41.75px;
    font-weight: 400;
    font-size: 20px;
}
.close-selection-box-icon {
    position: absolute;
    top: -35px;
    right: 5px;
    font-size: 20px;
}
.grey-link {
    color: #ffffff
}
.search {
    border: none;
    border-radius: 10px;
    padding: 10px;
    background-color: #ffffff;
    box-shadow: -1px 4px 10px -1px rgba(0, 0, 0, 0.4);
    -webkit-box-shadow: -1px 4px 10px -1px rgba(0, 0, 0, 0.4);
    -moz-box-shadow: -1px 4px 10px -1px rgba(0, 0, 0, 0.4);
}

.option-list {
    max-height: 150px;
    overflow-y: auto;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
}

.option-list::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

.option-list::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px rgb(0, 0, 0, 0.3);
}

.option-list::-webkit-scrollbar-thumb {
    background-color: #303e67;
    outline: 1px solid #303e67;
}

.option-list .option {
    font-weight: 500;
    font-size: 15px;
    font-family: 'Poppins';
    border-bottom: 1px solid;
    text-align: left;
    padding:  10px;
    text-transform: uppercase;
}

.option-list .option:hover {
    cursor: pointer;
    background-color: #303e67;
    color: white;
}

.option-list .option .label {
    font-size: .75em;
}

.option-list-wrapper-text {
    margin-left: 10px;
}
.option-list-wrapper-text p {
    font-size: 15px;
    font-weight: 600;
    font-family: "Poppins";
    padding-left: 5px !important;
    margin: 0px !important;
}
.option-list-wrapper-text small {
    padding-left: 5px !important;
}

.search .snippet {
    position: "absolute";
    right: 50;
    top: 20;
}

.dot-pulse {
    position: relative;
    left: -9999px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #9880ff;
    color: #9880ff;
    box-shadow: 9999px 0 0 -5px;
    -webkit-animation: dot-pulse 1.5s infinite linear;
            animation: dot-pulse 1.5s infinite linear;
    -webkit-animation-delay: 0.25s;
            animation-delay: 0.25s;
}
.dot-pulse::before, .dot-pulse::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 0;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #9880ff;
    color: #9880ff;
}
.dot-pulse::before {
    box-shadow: 9984px 0 0 -5px;
    -webkit-animation: dot-pulse-before 1.5s infinite linear;
            animation: dot-pulse-before 1.5s infinite linear;
    -webkit-animation-delay: 0s;
            animation-delay: 0s;
}
.NewReservationModal .dot-pulse::after {
    box-shadow: 10014px 0 0 -5px;
    -webkit-animation: dot-pulse-after 1.5s infinite linear;
            animation: dot-pulse-after 1.5s infinite linear;
    -webkit-animation-delay: 0.5s;
            animation-delay: 0.5s;
}

@-webkit-keyframes dot-pulse-before {
    0% {
        box-shadow: 9984px 0 0 -5px;
    }
    30% {
        box-shadow: 9984px 0 0 2px;
    }
    60%, 100% {
        box-shadow: 9984px 0 0 -5px;
    }
}

@keyframes dot-pulse-before {
    0% {
        box-shadow: 9984px 0 0 -5px;
    }
    30% {
        box-shadow: 9984px 0 0 2px;
    }
    60%, 100% {
        box-shadow: 9984px 0 0 -5px;
    }
}
@-webkit-keyframes dot-pulse {
    0% {
        box-shadow: 9999px 0 0 -5px;
    }
    30% {
        box-shadow: 9999px 0 0 2px;
    }
    60%, 100% {
        box-shadow: 9999px 0 0 -5px;
    }
}
@keyframes dot-pulse {
    0% {
        box-shadow: 9999px 0 0 -5px;
    }
    30% {
        box-shadow: 9999px 0 0 2px;
    }
    60%, 100% {
        box-shadow: 9999px 0 0 -5px;
    }
}
@-webkit-keyframes dot-pulse-after {
    0% {
        box-shadow: 10014px 0 0 -5px;
    }
    30% {
        box-shadow: 10014px 0 0 2px;
    }
    60%, 100% {
        box-shadow: 10014px 0 0 -5px;
    }
}
@keyframes dot-pulse-after {
    0% {
        box-shadow: 10014px 0 0 -5px;
    }
    30% {
        box-shadow: 10014px 0 0 2px;
    }
    60%, 100% {
        box-shadow: 10014px 0 0 -5px;
    }
}
.dtac-modal.modal-dialog {
	max-width: 600px !important;
}

.dtac-modal .modal-content {
	background-color: white;
	color: #525f6f;
}

.dtac-modal .modal-content .modal-header .modal-title {
	font-weight: 400 !important;
	font-size: 1.75em !important;
	text-transform: uppercase !important;
    text-align: left;
}
.dtac-modal-primary-btn {
	background-color: #303e67;
	border-radius: 7px;
	font-size: 13px;
	font-weight: 400;
	color: white;
	cursor: pointer;
}
.dtac-modal-secondary-btn {
	background-color: white;
	border-radius: 7px;
	font-size: 13px;
	font-weight: 400;
	color: black;
	cursor: pointer;
}
.RunPickupModal label {
    margin-bottom: .2rem;
    font-size: .85em;
    opacity: .6;
    color: rgba(39, 56, 70, .8);
    font-family: "Rubik", "San serif";
    font-weight: 500;
}
.RunPickupModal .form-group {
    margin-bottom: 2rem;
}
.RunPickupModal .modal-body {
    padding: 20px 30px;
}
.RunPickupModal .modal-header {
    font-weight: 500;
    background-color: #344A5C;
    color: #ffffff;
}
.RunPickupModal {
    font-family: "Rubik", "San serif";
}
.RunPickupModal .modal-content {
    padding: 0;
}



.pickup-model {
    margin-bottom: .2rem;
    font-size: .85em;
    opacity: .6;
    color: rgba(39, 56, 70, .8);
    font-family: "Rubik", "San serif";
    font-weight: 500;
}
.pickup-model .modal-header {
    font-weight: 500;
    background-color: #344A5C;
    color: #ffffff;
}

.payment-method {
    border: 1px solid #dadada;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    padding: 5px;
}
.pickup-modal label {
    opacity: .85;
    letter-spacing: .75px;
    text-transform: uppercase;
    font-size: 80%;
    font-weight: 500;
    color: #000;
}
.pickup-model .nav-item {
    text-transform: uppercase;
    font-family: Poppins;
    color: #000;
    text-align: center;
    margin-right: 0.75rem;
    border: 1px solid #dadada;
    border-top-left-radius: 10px !important;
    border-top-right-radius: 10px !important;
    background-color: #c6cbde;
}

.nav-item {
    font-size: 14px;
    font-weight: 400;
    text-transform: uppercase;
    font-family: "Poppins";
    color: #000000;
    text-align: center;
    margin-right: 0.75rem;
    border: 1px solid #DADADA;
    border-top-left-radius: 10px !important;
    border-top-right-radius: 10px !important;
    background-color: #C6CBDE;
}
.nav-tabs {
    border: none;
}
.nav-item:hover {
    border-top-left-radius: 10px !important;
    border-top-right-radius: 10px !important;
}
.nav-tabs .nav-link.active {
    font-weight: 500;
    background-color: white;
    border: 1px solid #DADADA;
    border-top-left-radius: 10px !important;
    border-top-right-radius: 10px !important;
}

.pickup-model .nav-tabs .nav-item .nav-link {
    padding: 0.5rem 2.25rem !important;
}
.pickup-model-primary-btn {
	background-color: #303e67;
	border-radius: 7px;
	font-size: 13px;
	font-weight: 400;
	color: white;
	cursor: pointer;
}
.pickup-model-secondary-btn {
	background-color: white;
	border-radius: 7px;
	font-size: 13px;
	font-weight: 400;
	color: black;
	cursor: pointer;
}
.pickup-model-danger-btn {
	background-color: red;
	border-radius: 7px;
	font-size: 13px;
	font-weight: 400;
	color: white;
	cursor: pointer;
	border: #ffffff;
}
.card-component {
	font-family: "Roboto";

}
.card-component label {
	text-transform: uppercase;
	letter-spacing: 0.15px;
	word-wrap: break-word
}
.card-component-btn {
	border-radius: 7px;
	font-size: 13px;
	font-weight: 400;
	color: white;
	cursor: pointer;
	border: 0;
}
.card-component .set-as-default {
	color: #0000EE !important;
	text-decoration: underline !important;
	text-transform: capitalize !important;
	cursor: pointer;
}
.card-component .toggle-section-heading i {
	float: right;
	margin-top: 0.25rem;
}
.card-component .toggle-section-heading {
	display: block;
	color: #ffffff;
	background-color: #2DAFD3;
	border-radius: 5px;
	padding: 0.7rem 1rem;
	font-size: 1em;
	margin-bottom: 1rem;
}
.card-component .toggle-section-heading.active {
	background-color: #2DAFD3;
	border-bottom-left-radius: 0px;
	border-bottom-right-radius: 0px;
	color: #ffffff;
}

.card-component .stipe-charge-history-wrapper {
	max-height: 500px;
}
.card-component .stipe-charge-history-row {
	background-color: #F0F0EB;
	padding-top: 0.5rem;
	margin: 0.5rem 0.15rem;
	border-bottom: 3px solid rgb(133, 133, 133);
}
.payment-plan.modal-dialog {
    min-width: 800px !important;
}
.payment-plan .modal-content {
    background-color: white;
    color: #525f6f;
}
.payment-plan .modal-content .modal-body {
    overflow-y: auto;
    overflow-x: hidden;
    font-size: 15px;
}
.payment-plan .modal-content label {
    color: #525f6f;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    font-size: 80%;
    font-weight: 600;
}
.payment-plan .modal-content p {
    margin-bottom: 0.25rem;

}
.payment-plan.modal-dialog row {
    margin-bottom: 15px !important;
}

.payment-plan .modal-content .modal-header {
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    display: -webkit-flex;
    display: flex;
}

.payment-plan .modal-content .modal-header .modal-title {
    font-weight: 400;
    font-size: 1.75em;
    text-transform: uppercase;
}

.payment-plan-modal-primary-btn {
    background-color: #1B4D7E;
    border-radius: 7px;
    font-size: 13px;
    font-weight: 400;
    color: white;
    cursor: pointer;
}
.payment-plan-modal-secondary-btn {
    background-color: white;
    border-radius: 7px;
    font-size: 13px;
    font-weight: 400;
    color: black;
    cursor: pointer;
}
.payment-plan-modal-danger-btn {
    background-color: red;
    border-radius: 7px;
    font-size: 13px;
    font-weight: 400;
    color: white;
    cursor: pointer;
    border: #ffffff;
}

.payment-plan .modal-content .toggle-section-heading i {
    float: right;
    margin-top: 0.25rem;
}
.payment-plan .modal-content .toggle-section-heading.active {
    background-color: #303e67;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    color: #ffffff;
}
.payment-plan .modal-content .toggle-section-heading {
    display: block;
    color: #525f6f;
    background-color: #D6D6D6;
    border-radius: 10px;
    padding: 0.5rem 1rem;
    font-size: 1em;
    margin-bottom: 1rem;
}
.notes_history {
    box-shadow: -1px 4px 20px -1px rgba(0, 0, 0, 0.8);
    -webkit-box-shadow: -1px 4px 20px -1px rgba(0, 0, 0, 0.8);
    -moz-box-shadow: -1px 4px 20px -1px rgba(0, 0, 0, 0.8);
}

.notes_history .notes_container {
    max-height: 300px;
    overflow-x: hidden;
    overflow-y: auto;
}
.notes_history .notes_container p {
    padding: 7px 20px;
    margin: 0px;
    border-bottom: 2px solid #028b8b;
}

.notes_history .popover-body .show_more {
    background-color: #303e67;
    border-radius: 7px;
    font-size: 10px;
    font-weight: 400;
    color: white;
    cursor: pointer;
}
.notes_history .popover-body .close_history {
    background-color: white;
    border-radius: 7px;
    font-size: 10px;
    font-weight: 400;
    color: black;
    cursor: pointer;
}

.notes_history .popover-body .close_history:hover {
    background-color: gray;
    color: white;
}
.mass-message-btn {
	background-color: #0B80F9;
	border: 1px solid #0B80F9;
	border-radius: 7px;
}

.mass-message-btn:hover {
	color: #41505d;
	background-color: transparent;
	border: 1px solid #41505d;
}

.mass-message-modal.modal-dialog {
	max-width: 45% !important;
}
.mass-message-modal .btn {
	border-radius: 7px;
	font-size: 13px;
	font-weight: 400 !important;
}

.mass-message-modal .btn.btn-primary {
	background-color: #0B80F9;
}

.mass-message-modal .modal-content {
	background-color: white;
	border-radius: 0px;
	color: black;
}

.mass-message-modal .modal-content .modal-body {
	max-height: 75vh;
	overflow-y: auto;
	overflow-x: hidden;
}

.mass-message-modal .modal-content .modal-body::-webkit-scrollbar {
	width: 5px;
	height: 5px;
}

.mass-message-modal .modal-content .modal-body::-webkit-scrollbar-track {
	box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3);
}

.mass-message-modal .modal-content .modal-body::-webkit-scrollbar-thumb {
	background-color: #303e67;
	outline: 1px solid #303e67;
}

.mass-message-modal .modal-content small,
.mass-message-modal .modal-content label {
	color: rgb(73, 73, 73);
	text-transform: uppercase;
	letter-spacing: 0.75px;
}

.mass-message-modal .modal-content .modal-header {
	-webkit-justify-content: center;
	        justify-content: center;
}

.mass-message-modal .modal-content .modal-header .modal-title {
	font-weight: 400;
	font-size: 1.75em;
	text-transform: uppercase;
}

.mass-message-modal .modal-content .issue-modal-jobs-wrapper .card {
	margin: 7px 0px;
}

.mass-message-modal .modal-content .issue-modal-jobs-wrapper .card-body {
	padding: 7px 15px;
	background-color: #0000000e;
}

.mass-message-modal .modal-content .issue-modal-jobs-wrapper .card-body .row {
	padding-top: 0.45rem;
}

.mass-message-modal .modal-content .issue-modal-jobs-wrapper label {
	margin-bottom: 0;
}

.mass-message-modal .modal-content .issue-modal-collapse-btn {
	width: 100%;
	border-radius: 0px;
	text-transform: uppercase;
	background-color: #0000008c;
	border: none;
}

.mass-message-modal .issue-extra-info-wrapper {
	margin-top: 1.5rem;
	padding-top: 1.25rem;
	border-top: 1px solid #dee2e6;
}


.mass-message-modal .toggle-switch {
	position: relative;
	width: 60px;
	padding: 0px 9px;
	display: inline-block;
	vertical-align: middle;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	text-align: left;
}
.mass-message-modal .toggle-switch-checkbox {
	display: none;
}
.mass-message-modal .toggle-switch-label {
	display: block;
	overflow: hidden;
	cursor: pointer;
	border: 0 solid #bbb;
	border-radius: 20px;
	margin: 0;
}
.mass-message-modal .toggle-switch-inner {
	display: block;
	width: 200%;
	margin-left: -100%;
	transition: margin 0.1s ease-in 0s;
}
.mass-message-modal .toggle-switch-inner:before, .toggle-switch-inner:after {
	display: block;
	float: left;
	width: 50%;
	height: 24px;
	padding: 0;
	line-height: 24px;
	font-size: 14px;
	color: white;
	font-weight: 500;
	box-sizing: border-box;
}
.mass-message-modal .toggle-switch-inner:before {
	content: "";
	text-transform: uppercase;
	padding-left: 10px;
	background-color: coral;
	color: #fff;
}
.mass-message-modal .toggle-switch-disabled {
	background-color: #ddd;
	cursor: not-allowed;
}
.mass-message-modal .toggle-switch-disabled:before {
	background-color: #ddd;
	cursor: not-allowed;
}
.mass-message-modal .toggle-switch-inner:after {
	content: "";
	text-transform: uppercase;
	padding-right: 10px;
	background-color: #2acd72;
	color: #fff;
	text-align: right;
}
.mass-message-modal .toggle-switch-switch {
	display: block;
	width: 18px;
	margin: 3px;
	background: #fff;
	position: absolute;
	top: 0;
	bottom: 0;
	right: 25px;
	border: 0 solid #bbb;
	border-radius: 20px;
	transition: all 0.1s ease-in 0s;
}
.mass-message-modal .toggle-switch-checkbox:checked + .toggle-switch-label .toggle-switch-inner {
	margin-left: 0;
}
.mass-message-modal .toggle-switch-checkbox:checked + .toggle-switch-label .toggle-switch-switch {
	right: 10px;
}
.vgm-primary-listing-container .vgm-primary-listing-container__body {
    overflow-y: auto !important;
    max-height: 65vh !important;
    display: -webkit-flex !important;
    display: flex !important;
    -webkit-flex-direction: column !important;
            flex-direction: column !important;
    background-color: white;
    border-radius: 10px;
    border: 1px solid #d7dce2;
}

.vgm-primary-listing-container .vgm-primary-listing-container__body table {
    width: 100% !important;
}
.vgm-primary-listing-container .vgm-primary-listing-container__body thead th {
    position: -webkit-sticky !important;
    position: sticky !important;
    background-color: #f2f2f2 !important;
    top: -1px !important;
    cursor: pointer !important;
    padding-bottom: 15px !important;
    padding-top: 15px !important;
    border: none;
    text-transform: uppercase;
    color: rgba(42, 53, 84, .52);
    font-size: .8em;
    font-weight: 500;
    vertical-align: middle;
    letter-spacing: .005em;

}
.vgm-primary-listing-container .vgm-primary-listing-container__body thead tr th:first-child {
    border-top-left-radius: 10px;
}
.vgm-primary-listing-container .vgm-primary-listing-container__body thead tr th:last-child {
    border-top-right-radius: 10px;
}
.vgm-primary-listing-container .vgm-primary-listing-container__body tr:hover {
    background-color: #f5f5f5 !important;
}
.vgm-primary-listing-container .vgm-primary-listing-container__body::-webkit-scrollbar {
    width: 5px !important;
    height: 5px !important;
}
.vgm-primary-listing-container .vgm-primary-listing-container__body::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3) !important;
}
.vgm-primary-listing-container .vgm-primary-listing-container__body::-webkit-scrollbar-thumb {
    background-color: #303e67 !important;
    outline: 1px solid #303e67 !important;
}
.vgm-primary-listing-container .vgm-primary-listing-container__body::-webkit-scrollbar {
    margin-left: 10px !important;
}
.vgm-primary-listing-container .vgm-primary-listing-container__footer {
    margin-top: 2rem;
    margin-right: 1rem;
    margin-left: 1rem;
    text-align: center;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 1px;
}
.vgm-primary-listing-container .vgm-primary-listing-container__footer a {
    cursor: pointer;
    color: #007bff;
    text-decoration: none;
    background-color: transparent;
    background-color: initial;
}
.vgm-primary-listing-container .vgm-primary-listing-container__footer a:hover {
    color: #0056b3;
    text-decoration: underline;
    background-color: transparent;
    background-color: initial;
}
/* CSS for notes history component */
.notes-history .popover {
    max-width: none !important;
}
.notes-history .popover-inner, .popover {
    min-height: inherit;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
}
.notes-history .popover-header {
    font-size: 15px;
    text-transform: uppercase;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    background-color: #f0f0eb;
    color: #1B4D7E;
}
.notes-history .main-container {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: stretch;
            align-items: stretch;
    height: 100%;
}
.notes-history .side-bar {
    max-height: 230px !important;
    width: 300px !important;
    overflow-y: auto;
    padding-right: 5px;
}
.notes-history .more-notes {
    float: right;
    margin-top: 10px !important;
    cursor: pointer !important;
}

.notes-history .toggle-section-heading {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    background-color: #D6D6D6;
    padding: 0.5rem 1rem;
    font-size: 1em;
    margin-bottom: 1rem;
}

.notes-history .toggle-section-heading.active {
    background-color: #303e67;
    color: #ffffff;
}

.notes-history .left-content {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
}

.notes-history .truncate {
    display: inline-block;
    max-width: 120px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.notes-history .right-icons {
    margin-left: auto;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
}


/* css for notes detail component */
.notes-history-details {
    margin-top: -10px !important;
    margin-left: 7px;
}
.notes-history-details label {
    font-family: 'Poppins';
    font-size: 10px;
    line-height: 5px;
    font-weight: 600;
    font-style: normal;
    cursor: pointer !important;
    text-transform: uppercase;
}
.notes-history-details .btn.primary {
    background-color: #1B4D7E;
    color: #fff;
    border-color: transparent;
    text-transform: uppercase;
    font-size: 10px;
}
.container {
    height: 8vh;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
}

.slider {
    position: relative;
    width: 200px;
}

.slider__track,
.slider__range,
.slider__left-value,
.slider__right-value {
    position: absolute;
}

.slider__track,
.slider__range {
    border-radius: 3px;
    height: 5px;
}

.slider__track {
    background-color: #ced4da;
    width: 100%;
    z-index: 1;
}

.slider__range {
    background-color: #9fe5e1;
    z-index: 2;
}

.slider__left-value,
.slider__right-value {
    color: #414552;
    font-size: 12px;
    margin-top: 20px;
}

.slider__left-value {
    left: 6px;
}

.slider__right-value {
    right: -4px;
}

/* Removing the default appearance */
.thumb,
.thumb::-webkit-slider-thumb {
    -webkit-appearance: none;
    -webkit-tap-highlight-color: transparent;
}

.thumb {
    pointer-events: none;
    position: absolute;
    height: 0;
    width: 200px;
    outline: none;
}

.thumb--zindex-3 {
    z-index: 3;
}

.thumb--zindex-5 {
    z-index: 5;
}

.thumb::-webkit-slider-thumb {
    background-color: #f1f5f7;
    border: none;
    border-radius: 50%;
    box-shadow: 0 0 1px 1px #ced4da;
    cursor: pointer;
    height: 18px;
    width: 18px;
    margin-top: 4px;
    pointer-events: all;
    position: relative;
}
.listing-filters {
    border: none;
    box-shadow: -1px 4px 20px -1px rgba(0, 0, 0, 0.8);
    -webkit-box-shadow: -1px 4px 20px -1px rgba(0, 0, 0, 0.8);
    -moz-box-shadow: -1px 4px 20px -1px rgba(0, 0, 0, 0.8);
    min-width: 250px;


}
.listing-filters .popover-header a {
    cursor: pointer;
}
.listing-filters .popover-header a:hover {
    text-decoration: underline !important;
}
.listing-filters .popover-body {
    padding: 0;
    max-height: 300px;
    overflow-x: hidden;
    overflow-y: auto;
}
.listing-filters .filter__wrapper {
    min-width: 100px;
    padding: 0.5rem 1rem 0.5rem 0.5rem;
    border-bottom: 1px solid #C0C8D2;
    /* padding: .5rem; */
}
.listing-filters .filter__wrapper .filter-title {
    color: #414552;
    text-transform: uppercase;
    font-size: 12px;
    padding-left: 0.5rem;
}
.Add-driver-modal.modal-dialog {
	max-width: 800px !important;
}
.Add-driver-modal .modal-content {
	background-color: white;
	color: #525f6f;
}
/* .Add-driver-modal .modal-content .modal-body {
	max-height: 75vh;
	overflow-y: auto;
	overflow-x: hidden;
} */
.Add-driver-modal .modal-content .modal-body::-webkit-scrollbar {
	width: 5px;
	height: 5px;
	/* display: none; */
}
.Add-driver-modal .modal-content .modal-body::-webkit-scrollbar-thumb {
	background-color: #303e67;
	outline: 1px solid #303e67;
	/* display: none; */
}

.Add-driver-modal .modal-content .toggle-section-heading i {
	float: right;
	margin-top: 0.25rem;
}
.Add-driver-modal .modal-content .toggle-section-heading {
	display: block;
	color: #525f6f;
	background-color: #D6D6D6;
	border-radius: 10px;
	padding: 0.5rem 1rem;
	font-size: 1em;
	margin-bottom: 1rem;
}

.Add-driver-modal .modal-content .toggle-section-heading.active {
	/* background-color: #1A1C1E; */
	background-color: #303e67;
	border-bottom-left-radius: 0px;
	border-bottom-right-radius: 0px;
	color: #ffffff;
}

.Add-driver-modal .modal-content label {
	color: #525f6f;
	text-transform: uppercase;
	letter-spacing: 0.5px;
	font-size: 80%;
	font-weight: 600;
	margin-bottom: 0.25rem;
}
.Add-driver-modal .modal-content p {
	margin-bottom: 0.25rem;

}
.Add-driver-modal.modal-dialog row {
	margin-bottom: 15px !important;
}

.Add-driver-modal .modal-content .modal-header {
	-webkit-justify-content: center;
	        justify-content: center;
}

.Add-driver-modal .modal-content .modal-header .modal-title {
	font-weight: 400;
	font-size: 1.75em;
	text-transform: uppercase;
	/* color: white; */
}
.Add-driver-modal .issue-extra-info-wrapper {
    margin-top: 1.5rem;
    padding-top: 1.25rem;
    border-top: 1px solid #dee2e6;
}

.Add-driver-modal-primary-btn {
	background-color: #303e67;
	border-radius: 7px;
	font-size: 13px;
	font-weight: 400;
	color: white;
	cursor: pointer;
}
.Add-driver-modal-secondary-btn {
	background-color: white;
	border-radius: 7px;
	font-size: 13px;
	font-weight: 400;
	color: black;
	cursor: pointer;
}
.Add-driver-modal-danger-btn {
	background-color: red;
	border-radius: 7px;
	font-size: 13px;
	font-weight: 400;
	color: white;
	cursor: pointer;
	border: #ffffff;
}
.repAssignmentModal .multiSelectButton{
    color: #52606b;
	border-color: #52606b;
    background-color: transparent;
    margin:10px
}
.repAssignmentModal .multiSelectButton.active{
    color: #fff;
	border-color: none;
    background-color: #52606b;
    margin:10px
}
.repAssignmentModal .btn-outline-primary, .repAssignmentModal .btn-outline-primary{
	color: #52606b;
	border-color: #52606b;
	background-color: transparent;
}
.repAssignmentModal .btn-outline-primary:not(:disabled):not(.disabled):active, .repAssignmentModal .btn-outline-primary:not(:disabled):not(.disabled).active, .repAssignmentModal .show > .btn-outline-primary.dropdown-toggle{
	color: #fff;
    background-color: #52606b;
    border-color: #52606b;
}

.repAssignmentModal .btn-outline-primary:hover,.repAssignmentModal .btn-outline-primary:active,.repAssignmentModal .btn-outline-primary:focus,
.repAssignmentModal .btn-outline-primary:not(:disabled):not(.disabled):active
{
	color: #fff;
    background-color: #52606b;
    border-color: #52606b;
}

.submit-button{
    border-radius: 8px;
    background-color: #1B4D7E;
}
.table-preferences-popover .popover-header {
    min-width: 450px;
}

.table-preferences-popover .popover-body {
    padding: 20px;
    color: #333;
}
.table-preferences-popover label {
    font-size: 13px;
    font-weight: 500;
    text-transform: uppercase;
}
.listing-action-items {
    border: none;
    box-shadow: -1px 4px 20px -1px rgba(0, 0, 0, 0.8);
    -webkit-box-shadow: -1px 4px 20px -1px rgba(0, 0, 0, 0.8);
    -moz-box-shadow: -1px 4px 20px -1px rgba(0, 0, 0, 0.8);
    min-width: 250px;
    max-height: 300px;
    overflow-x: hidden;
    overflow-y: auto;
}

.listing-action-items ul {
    list-style-type: none;
    padding-left: 0;
}
.listing-action-items ul li {
    min-width: 100px;
    padding: 0.5rem 1rem 0.5rem 0.5rem;
    border-bottom: 1px solid #C0C8D2;
}
.table-container {
  overflow-y: auto !important; 
  max-height: 65vh !important;
  display: -webkit-flex !important;
  display: flex !important;
  -webkit-flex-direction: column !important;
          flex-direction: column !important;
} 

.table-container table {
  width: 100% !important;
}
.table-container thead th {
  position: -webkit-sticky !important;
  position: sticky !important;
  background-color: #f2f2f2 !important; 
  top: -1px !important;
  cursor: pointer !important;
  padding-bottom: 15px !important;
  padding-top: 15px !important;
}
.table-container thead tr th:first-child{
  border-top-left-radius: 10px;
}
.table-container thead tr th:last-child{
  border-top-right-radius: 10px;
}
.table-container tr:hover {
  background-color: #f5f5f5 !important;
}

::-webkit-scrollbar {
  width: 5px !important;
  height: 5px !important;
}
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3) !important;
}
::-webkit-scrollbar-thumb {
  background-color: #303e67 !important;
  outline: 1px solid #303e67 !important;
}

::-webkit-scrollbar {
  margin-left: 10px !important;
}

::-webkit-scrollbar {
  margin-left: 10px !important;
} 






.cars-data-table-container {
	/* max-height: 67vh;
	overflow-y: auto; */
}
.data-table-filters-container .bulk-action-wrapper {
	padding: 15px 0px 0px 5px;
	font-weight: 500;
}
.data-table-filters-container .bulk-action-wrapper a:hover i,
.data-table-filters-container .bulk-action-wrapper a:hover {
	background-color: #2acd72;
	color: white !important;
}
.data-table-filters-container .bulk-action-wrapper a {
	cursor: pointer;
	font-size: 13px;
	text-transform: uppercase;
	border: 1px solid #2acd72;
	padding: 5px 10px;
	border-radius: 7px;
}
.data-table-filters-container .bulk-action-wrapper i {
	color: #79ff4859 !important;
}
.cars-data-table-container td {
	font-size: 13px !important;
}
.cars-data-table-container::-webkit-scrollbar,
.data-table-filters-container::-webkit-scrollbar {
	width: 5px;
	height: 5px;
}
.cars-data-table-container::-webkit-scrollbar-track,
.data-table-filters-container::-webkit-scrollbar-track {
	box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3);
}

.cars-data-table-container::-webkit-scrollbar-thumb,
.data-table-filters-container::-webkit-scrollbar-thumb {
	background-color: #303e67;
	outline: 1px solid #303e67;
}
.cars-management-container .progress,
.cars-data-table-container .progress {
	height: 0.5vh;
}
.cars-data-table-container .car-name {
	max-width: 175px;
	margin-right: 5px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
.cars-data-table-container .notes .note-text {
	max-width: 125px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	display: inline-block;
	vertical-align: middle;
}
.ReservedCarsContainer.CarListContainer .row,
.FleetManagementContainer.CarListContainer .row {
	margin-bottom: 0;
}

.CarListContainer .table th, .table td {
	border: none;
	padding-bottom: 10px;
	padding-top: 10px;
}

.CarListContainer .table tr {
	border-bottom: 1px solid rgba(202, 202, 202, 0.21);
	margin: 10px;
	color: #273846cc;
}
.CarListContainer .table th {
	text-transform: uppercase;
	color: rgba(42, 53, 84, 0.52);
	font-size: 0.8em;
	font-weight: 500;
	vertical-align: middle;
	letter-spacing: 0.005em;
}

.ReservedCarsContainer.CarListContainer .card,
.FleetManagementContainer.CarListContainer .card {
	margin-bottom: .5rem;
	box-shadow: 3px 3px 9px 2px rgba(202, 202, 202, 0.21);
	border: none;
	border-radius: 8px;
}

.date-range-group .input-group-text i {
	color: white !important;
}
.date-range-group .input-group-text {
	background-color: #2acd72 !important;
}
.CarListContainer .date-range-group input {
	font-weight: 500;
	color: #303e67 !important;
	font-size: 13px;
}

.available-car-wrap.nav-pills .nav-item .btn.btn-primary {
	padding: 9px 25px;
	font-size: 14px;
	/* font-weight: 500; */
	color: #303e67;
	background-color: #c6ccde;
	border: none;
	border-radius: 8px;
	margin-top: -10px;
}
.available-car-wrap.nav-pills .nav-item .btn.btn-primary:focus {
	box-shadow: none;
}
.available-car-wrap.nav-pills .nav-item .btn.btn-primary.active {
	background: #fff;
}
.available-car-wrap.nav-pills .nav-item {
	margin: 0.5rem 25px;
	margin-left: 0;
}
.AvailableCarsContainer .card {
	margin-bottom: 0.5rem;
}
.reset-date-range {
	position: absolute;
	right: -5px;
	top: -5px;
	font-size: 22px;
}
/* .cars-wrapper {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
} */
.reservation-driver-icon,
.return-driver-icon,
.cars-wrapper .car-info {
	text-align: center;
	float: left;
	padding-right: 10px;
	margin-top: 6px;
}
.view-prices .all-prices {
	display: inline-block;
	padding-right: 3px;
}
.cars-wrapper .car-actions {
	float: right;
}
.cars-wrapper .car-actions ul {
	padding-top: 5px;
	padding-right: 1.5rem;
	margin: 0;
	/* padding: 0 40px 0 0; */
}
.old-view {
	position: absolute;
	bottom: 10px;
	right: 125px;
	border-right: 1px solid #DC3546;
	padding-right: 10px;
	color: #DC3546 !important;
	font-size: 12px;
	font-weight: 500;
	text-transform: uppercase;
	text-decoration: underline !important;
	cursor: pointer;
}
.more-filter-button {
	position: absolute;
	bottom: 10px;
	right: 25px;
	color: #303E67 !important;
	font-size: 12px;
	font-weight: 500;
	text-transform: uppercase;
	text-decoration: underline !important;
	cursor: pointer;
}

.reservation-driver .reservation-pickup {
	color: #303E67;
}
.reservation-driver,
.return-driver {
	float: left;
	text-align: left;
	padding-left: 10px;
	border-left: 1px solid #e1e1e1;
}
.return-driver .return-reason {
	width: 75px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	display: inline-block;
	vertical-align: middle;
}

.reservation-driver .reservation-driver-name,
.return-driver .return-driver-name {
	max-width: 175px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	vertical-align: middle;
}
.cars-wrapper .car-id-detail {
	float: left;
	text-align: center;
	margin-top: 8px;
}
.view-prices .car-pricing-default {
	text-align: center;
	display: inline-block;
	padding-left: 10px;
	vertical-align: middle;
	border-left: 1px solid #e1e1e1;
}
.cars-wrapper .car-name-years {
	float: left;
	padding-left: 20px;
}
.ReservationsListContainer {
	font-family: "Poppins" !important;
}
.ReservationsListContainer .bos-badge-blue {
	font-size: 10px !important;
}
.cars-wrapper .car-name-years .car-name {
	font-weight: 500;
}
.car-location.not-bold {
	font-weight: 400;
}
.car-location, .car-tags {
	font-weight: 500;
	display: -webkit-flex;
	display: flex;
	max-width: 250px;
}
.view-prices a {
	font-weight: 500;
	font-size: 14px;
	color: #232323;
	text-decoration: none;
	padding-left: 5px;
	display: inline-block;
	cursor: pointer;
}
.attachment {
	padding-top: 17px;
	padding-left: 10px;
}
.attachment a {
	cursor: pointer;
}
.return-date {
	margin-top: 23px;
}
.reason {
	margin-top: 10px;
}
.drivers {
	margin-top: 15px;
	width: 160px;
	position: relative;
}
.drivers .explation-mark i {
	position: absolute;
	top: 12px;
	right: -18px;
}
.edit-location-icon {
	font-size: 20px;
	display: inline-block;
	border-right: 1px solid #e1e1e1;
	margin-right: 10px;
	padding-right: 10px;
}
.actions {
	padding-left: 0;
	padding-left: 10px;
}
.actions li:not(:first-child) {
	border-left: 1px solid #e1e1e1;
	padding-left: 10px;
}
.actions li {
	display: inline-block;
	margin-right: 10px;
}
.actions li a {
	cursor: pointer;
}
.modalcarinfo .modal-content {
	padding: 0px;
}
.search-button {
	position: absolute;
	right: 0;
	top: 0;
	background: #303e67;
	border-color: #303e67;
	margin-bottom: 30px;
}
.toggle-switch {
	position: relative;
	width: 60px;
	padding: 0px 9px;
	display: inline-block;
	vertical-align: middle;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	text-align: left;
}
.toggle-switch-checkbox {
	display: none;
}
.toggle-switch-label {
	display: block;
	overflow: hidden;
	cursor: pointer;
	border: 0 solid #bbb;
	border-radius: 20px;
	margin: 0;
}
.toggle-switch-inner {
	display: block;
	width: 200%;
	margin-left: -100%;
	transition: margin 0.1s ease-in 0s;
}
.toggle-switch-inner:before, .toggle-switch-inner:after {
	display: block;
	float: left;
	width: 50%;
	height: 24px;
	padding: 0;
	line-height: 24px;
	font-size: 14px;
	color: white;
	font-weight: 500;
	box-sizing: border-box;
}
.toggle-switch-inner:before {
	content: "";
	text-transform: uppercase;
	padding-left: 10px;
	background-color: #DC3546;
	color: #fff;
}
.toggle-switch-disabled {
	background-color: #ddd;
	cursor: not-allowed;
}
.toggle-switch-disabled:before {
	background-color: #ddd;
	cursor: not-allowed;
}
.toggle-switch-inner:after {
	content: "";
	text-transform: uppercase;
	padding-right: 10px;
	background-color: #2acd72;
	color: #fff;
	text-align: right;
}
.toggle-switch-switch {
	display: block;
	width: 18px;
	margin: 3px;
	background: #fff;
	position: absolute;
	top: 0;
	bottom: 0;
	right: 25px;
	border: 0 solid #bbb;
	border-radius: 20px;
	transition: all 0.1s ease-in 0s;
}
.toggle-switch-checkbox:checked + .toggle-switch-label .toggle-switch-inner {
	margin-left: 0;
}
.toggle-switch-checkbox:checked + .toggle-switch-label .toggle-switch-switch {
	right: 10px;
}
.nav.nav-pills .nav-item {
	margin-right: 20px;
	border-radius: 8px;
}
.nav.nav-pills .nav-item .nav-item {
	border-radius: 8px 8px 0px 0px;
	padding: 20px 30px;
	background-color: #c6ccde;
	color: #303e67;
}
.nav.nav-pills .nav-item .nav-item span {
	margin-top: 6px;
	display: inline-block;
}
.nav.nav-pills .nav-item .nav-item.active {
	background-color: #fff;
	color: #303e67;
}
@media (min-width: 576px) {
	.modalcarinfo {
		max-width: 1080px !important;
	}
	.modalcarinfo .modal-body {
		padding: 40px 50px;
	}
}
.modalcarinfo label {
	display: block;
	width: 100%;
	font-weight: 500;
}
.modalcarinfo .modal-body .input-group {
	margin-bottom: 30px;
}
.modalcarinfo .modal-body .input-group input.readonly {
	background-color: #e9ecef;
}
.modalcarinfo .modal-body .input-group input {
	border-radius: 4px;
	padding: 10px 20px;
	height: auto;
}
/* .modalcarinfo .modal-body .input-group .input-group-prepend .btn.btn-secondary {
	 background-color: #e9ecef;
	 color: #ced4da;
	 border: none;
	 border-radius: 8px;
} */
.modalcarinfo .modal-body .driver-wrapper.green span {
	color: #fff;
}
.modalcarinfo .modal-body .driver-wrapper.green strong {
	color: #fff;
}
.modalcarinfo .modal-body .driver-wrapper.green {
	background-color: #198754;
}
.modalcarinfo .modal-body .driver-wrapper {
	padding: 25px 10px;
	border: 1px solid #ced4da;
	border-radius: 5px;
	margin-bottom: 20px;
	/* float: left; */
	/* width: 100%; */
}
.modalcarinfo .modal-body .driver-wrapper .driver-info {
	float: left;
	padding-left: 20px;
}
.modalcarinfo .modal-body .driver-wrapper .driver-info span {
	display: inline-block;
}
/* .modalcarinfo .modal-body .driver-wrapper .driver-info-license {
	 float: left;
	 width: 50%;
	 padding-bottom: 14px;
} */
.modalcarinfo .modal-body .driver-wrapper .driver-info-license strong {
	display: block;
	margin-top: 15px;
}
.modalcarinfo .modal-body h4 {
	font-size: 16px;
	margin-bottom: 17px;
}
.modalcarinfo .modal-header {
	background-color: #303e67;
	color: #fff;
	padding-bottom: 20px;
}
.modalcarinfo .modal-header .close {
	color: #fff;
	opacity: 1;
	margin-right: 10px;
	/* margin-top: 0px; */
}
.modalcarinfo .modal-footer {
	border: none;
	padding: 20px;
}
/* .modalcarinfo .modal-footer .btn.btn-secondary {
	 background-color: #303e67;
	 border-color: #303e67;
	 color: #fff;
	 padding: 13px 22px;
	 font-weight: 500;
	 font-size: 15px;
	 margin-right: 20px;
} */
/* .modalcarinfo .modal-footer .btn.btn-primary {
	 background-color: #198754;
	 border-color: #198754;
	 color: #fff;
	 padding: 13px 22px;
	 font-weight: 500;
	 font-size: 15px;
	 margin-right: 20px;
}
 .modalcarinfo .modal-footer .btn.btn-danger {
	 background-color: #DC3546;
	 border-color: #DC3546;
	 color: #fff;
	 padding: 13px 22px;
	 font-weight: 500;
	 font-size: 15px;
	 margin-right: 20px;
} */
.modalcarinfo .carissue-open {
	background-color: transparent;
	color: #DC3546;
	display: block;
	width: 100%;
	text-align: left;
	padding: 15px 30px;
	text-transform: uppercase;
	border: 1px solid#DC3546;
	border-radius: 6px;
	font-weight: 500;
}
.modalcarinfo .carissue-open:hover, .modalcarinfo .carissue-open:focus, .modalcarinfo .carissue-open:active {
	box-shadow: none;
	background-color: #dc354648 !important;
	border-color: #DC3546 !important;
}
.modalcarinfo .carissue-open span {
	float: right;
	font-weight: 400;
}
.modalcarinfo .carissue-open span strong {
	font-weight: 700;
}
.modalcarinfo .carissue-closed {
	background-color: transparent;
	color: #198754;
	display: block;
	width: 100%;
	text-align: left;
	padding: 15px 30px;
	text-transform: uppercase;
	border: 1px solid #198754;
	border-radius: 6px;
}
.modalcarinfo .carissue-closed:hover, .modalcarinfo .carissue-closed:focus, .modalcarinfo .carissue-closed:active {
	box-shadow: none;
	background-color: #1987543c !important;
	border-color: #198754 !important;
}
.modalcarinfo .carissue-closed span {
	float: right;
	font-weight: 400;
}
.modalcarinfo .carissue-closed span strong {
	font-weight: 700;
}
.search-filter {
	position: relative;
}
.search-filter .search-icon.btn {
	background-color: #303e67;
}
.search-filter .search-icon {
	position: absolute;
	right: 0;
	top: 0;
}
.cargadgets-button {
	background-color: transparent;
	padding: 0;
	margin-top: -28px;
	border: none;
}
.cargadgets-button:hover, .cargadgets-button:focus, .cargadgets-button:active {
	background-color: transparent;
	outline: none;
	box-shadow: none;
}
.popover.cargadgets-popover {
	width: 500px;
}
.cargadgets-popover.popover {
	max-width: 350px !important;
}
.cargadgets-popover.popover .popover-header {
	margin-bottom: 15px;
	font-size: 17px;
	text-align: center;
}
.cargadgets-popover.popover .popover-body {
	padding: 0 20px;
	text-align: center;
}
.pricing-details {
	background-color: #e9edf0;
}
.pricing-details .pricing-heading {
	background-color: #313e68;
	border-radius: 7px 7px 0 0;
	padding: 20px 0;
	color: #fff;
	text-align: center;
}
.pricing-details .pricing-price {
	display: block;
	padding: 30px 0;
	text-align: center;
}
.pricing-details span:not(.pricing-price) {
	padding: 0 0 20px 0;
	font-size: 14px;
	text-align: center;
	display: block;
}
.car-location .location-button {
	display: inline-block;
}
.fa-wrench.fa-2x {
	font-size: 1.5em !important;
}
.btn-outline-primary {
	border-color: #52606b;
	background-color: transparent;
	color: #52606b
}
.btn-outline-primary:hover, .btn-outline-primary:focus {
	color: #fff;
	background-color: #52606b;
	border-color: #52606b;

}

/* .modalcarinfo .btn.btn-secondary.carinfo-label {
	 background-color: #313e68;
	 display: block;
	 width: 100%;
	 border-radius: 5px;
	 padding: 10px 0px;
	 margin-top: 20px;
} */
/* .return-behaviour {
	 width: 100%;
	 display: block;
}
 .return-behaviour-popover .popover {
	 width: 800px;
	 max-width: 800px !important;
}
 .return-behaviour-popover .popover .cars-wrapper {
	 padding: 10px 0;
	 float: left;
}
 .return-behaviour-popover .popover .cars-wrapper .car-info {
	 float: left;
	 padding-left: 10px;
	 padding-right: 22px;
	 margin-top: 6px;
}
 .return-behaviour-popover .popover .cars-wrapper .car-id-detail {
	 float: left;
	 text-align: center;
	 padding-right: 20px;
	 border-right: 1px solid #e1e1e1;
	 margin-top: 10px;
}
 .return-behaviour-popover .popover .cars-wrapper .car-id-detail span {
	 font-weight: 500;
}
 .return-behaviour-popover .popover .cars-wrapper .car-id-detail span img {
	 margin-right: 10px;
}
 .return-behaviour-popover .popover .cars-wrapper .car-name-years {
	 float: left;
	 padding-left: 20px;
}
 .return-behaviour-popover .popover .cars-wrapper .car-name-years .car-name {
	 font-weight: 500;
} */
/* .rbc-calendar {
	 margin-bottom: 80px;
}
 .rbc-calendar .rbc-time-content {
	 display: none;
} */
.returning-cars-table.table th, .returning-cars-table.table td {
    padding: 0.75rem 0.25rem;
}

.returning-cars-table .cars-wrapper .car-id-detail {
    border: none;
    padding-left: 0;
}
.returning-cars-table .branch-badge {
    text-transform: uppercase;
    padding: 5px;
    background-color: #0b80f9;
    margin-left: 0;
}
/* .car-management-table{
    overflow-x: scroll;
    overflow-y: scroll;
    max-height: 300px !important;
    display: block;
}
.car-management-table::-webkit-scrollbar {
  width: 5px;
  height: 7px;
}
.car-management-table::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3);
}

.car-management-table::-webkit-scrollbar-thumb {
	background-color: #303e67;
	outline: 1px solid #303e67;
} */

.car-management-table,
.car-management-table tr td {
  font-size: 12px !important;
  font-family: "Poppins";
  border: 1px solid #dee2e64e;
  padding: 0;
}
.car-management-table tr th {
  background-color: #3e4c6d;
  color: white !important;
  white-space: nowrap;
  /* border: none !important; */
}
.car-management-table td button {
  color: #3e4c6d !important;
}
.btn-add-new-car {
  margin-bottom: 15px;
  border: none !important;
  font-size: 12px;
  letter-spacing: 1px !important;
  font-weight: 600 !important;
  text-align: center;
}
.car-management-table td button,
.car-management-table tr th {
  position: -webkit-sticky;
  position: sticky;
  top: -1px;
  font-size: 12px;
  letter-spacing: 1px !important;
  font-weight: 600 !important;
  border: 1px solid #dee2e64e !important;
  text-align: center;
}
.car-management-table tr:hover {
  background-color: #f5f5f5 !important;
}
.car-management-table td button {
  border: none !important;
  padding: 0;
}
.car-management-table td .column-text {
  margin: 0;
}
.car-management-table td .column-text .car-id .fa {
  padding-left: 3px;
  padding-top: 3px;
}
.car-management-table td .column-text .car-id .fa,
.car-management-table td .column-text .car-id {
  color: blue;
  font-size: 12px !important;
  text-decoration: underline;
  float: right;
}
.car-management-table td.disabled {
  background-color: #3e4c6d1f;
  color: black;
}
.car-management-table td.car-info-deal-column {
  background-color: #489aff52;
}
.car-management-table td.car-info-plate-column {
  background-color: #48d8ff52;
}
.car-management-table td {
  padding: 5px 7px !important;
  white-space: nowrap;
  text-align: center;
}
.car-management-table td .limit-text {
  max-width: 125px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.car-management-table td input:focus {
  border: none !important;
  box-shadow: none;
}

.car-management-table td select,
.car-management-table td input {
  /* border: 2px solid rgba(77, 77, 253, 0.46) !important; */
  border: none;
  margin: 0 !important;
  padding: 0rem;
  font-size: 12px !important;
}
.car-management-table td .input-group {
  width: 150px !important;
}
.car-management-table td .input-group-text {
  background: transparent !important;
  border: none;
}

.car-management-table td .fa-exclamation-circle{
  color: red !important;
  font-size: 14px;

}
.car-management-table td.invalid-value select,
.car-management-table td.invalid-value input{
  background-color: #ff000005;
}
.car-management-table td.invalid-value{
  background-color: #ff000056;
  border: 1px solid red;
}
.car-management-table td.valid-value select,
.car-management-table td.valid-value input{
  background-color: #00800000;
}
.car-management-table td.valid-value {
  background-color: #00800063;
}

.bos-car-attr-modal label,
.bos-car-attr-modal .modal-header {
  text-transform: capitalize;
}

.bos-manage-plates-table-wrapper{
  max-height: 35vh;
  overflow: auto;
}
.bos-manage-plates-table{
  margin-top: 7px;
  text-align: center;
}
.bos-manage-plates-table th{
  padding: 5px 10px;
  font-weight: 500;
  text-transform: uppercase;
  font-size: 75% !important;
}
.bos-manage-plates-table td{
  border: 1px solid #dee2e6 !important;
  padding: 5px!important;
  font-size: 85% !important;
}
.bos-manage-plates-table tr{
  margin: 0px !important;
}

.bos-manage-plates-table-wrapper::-webkit-scrollbar,
.car-management-table::-webkit-scrollbar {
	width: 7px;
  height: 10px;
}
   
.bos-manage-plates-table-wrapper::-webkit-scrollbar-track,
.car-management-table::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #f2f3f59c;
}

.bos-manage-plates-table-wrapper::-webkit-scrollbar-thumb,
.car-management-table::-webkit-scrollbar-thumb {
	background-color: #adadad;
	outline: 1px solid #adadad;
  border-radius: 13px;
}

.bos-manage-plates-item.selected,
.bos-manage-plates-item:hover{
  background-color: #4662a27e;
  color: black;
  /* font-weight: 500; */
}
.audit-search-filter {
    position: relative;
}
.audit-search-filter .search-icon {
    position: absolute;
    right: 15px !important;
    top: 9px !important;
    color: #d7dce2;
}
.audit-search-filter input {
    border-radius: 15px;
    border-width: 3px !important;
}
.search-filter-preview {
    border: 1px dashed #52606b;
    border-radius: 15px;
    padding: 5px 10px;
    margin-right: 5px;
}
.search-filter-preview span {
    color: #273846cc;
    text-transform: capitalize;
    border-right: 1px solid #52606b;
    padding-right: 5px;
    margin-right: 5px;
}
.search-filter-preview {
    color: #0B80F9;
}

.audit-list-button {
    color: #0B80F9 !important;
    cursor: pointer;
}
.audit-list-button:hover {
    text-decoration: underline !important;
}
.select-all-checkbox {
    text-align: center;
}

.audit-data-table-container {
    max-height: 75vh;
    overflow-y: auto;
    margin-bottom: .5rem;
    margin-top: .5rem;
    box-shadow: 3px 3px 9px 2px rgba(202, 202, 202, 0.21);
    border: none;
    border-radius: 8px;
}

.audit-data-table-container td {
    font-size: 12px !important;
}
.audit-data-table-container::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}
.audit-data-table-container::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3);
}

.audit-data-table-container::-webkit-scrollbar-thumb {
    background-color: #303e67;
    outline: 1px solid #303e67;
}
.audit-data-table-container .progress {
    height: 0.5vh;
}

.audit-data-table-container .table th, .table td {
    border: none;
    padding-bottom: 10px;
    padding-top: 10px;
}

.audit-data-table-container .table tr {
    border-bottom: 1px solid rgba(202, 202, 202, 0.21);
    margin: 10px;
    color: #273846cc;
}
.audit-data-table-container .table th {
    text-transform: uppercase;
    color: rgba(42, 53, 84, 0.52);
    font-size: 0.8em;
    font-weight: 500;
    vertical-align: middle;
    letter-spacing: 0.005em;
}
.ReservationsListContainer .row {
	margin: 5px 0px 10px;
	font-size: 0.93em;
}
.ReservationsListContainer {
	color: #273846cc;
	font-family: 'Rubik', 'San serif';

}
.ReservationsListContainer .btn-outline-primary, .ReservationsListContainer .btn-outline-primary {
	color: #52606b;
	border-color: #52606b;
	background-color: transparent;
}
.ReservationsListContainer .btn-outline-primary:not(:disabled):not(.disabled):active, .ReservationsListContainer .btn-outline-primary:not(:disabled):not(.disabled).active, .ReservationsListContainer .show > .btn-outline-primary.dropdown-toggle {
	color: #fff;
	background-color: #52606b;
	border-color: #52606b;
}

.ReservationsListContainer .btn-outline-primary:hover, .ReservationsListContainer .btn-outline-primary:active, .ReservationsListContainer .btn-outline-primary:focus,
.ReservationsListContainer .btn-outline-primary:not(:disabled):not(.disabled):active {
	color: #fff;
	background-color: #52606b;
	border-color: #52606b;
}

.ReservationsListContainer .nav-pills .nav-item {
	margin: 10px;
}
.popover {
	max-width: 500px;
}
.ReservationsListContainer .uppercase {
	text-transform: uppercase;
}
.ReservationsListContainer .capitalize {
	text-transform: lowercase;
}
.ReservationsListContainer .capitalize::first-letter {
	text-transform: capitalize;
}
.ReservationsListContainer .linkHoverEffect {
	position: relative;
}
.ReservationsListContainer .linkHoverEffect:hover:after {
	width: 100%;
	transition: all 0.2s ease-in-out;
}
.ReservationsListContainer .linkHoverEffect:after {
	position: absolute;
	content: "";
	width: 0%;
	margin-top: 4px;
	height: 2px;
	background-color: #52606b;
	left: 0;
	bottom: 0;
	transition: all 0.2s ease-in-out;
}
i {
	cursor: pointer;
}
.ReservationsListContainer .alert {
	text-align: center;
	border: none;
}
.ReservationsListContainer .alert-danger {
	background-color: #FF4F4F;
	color: #fff;

}
.ReservationsListContainer .alert-primary {
	background-color: #273846cc;
	color: #fff;
}
.ReservationsListContainer .card {
	box-shadow: 3px 3px 9px 2px rgba(202, 202, 202, 0.21);
	border: none;
	border-radius: 8px;
}

.ReservationsListContainer .table th, .table td {
	vertical-align: middle;
	border: none;
	padding-bottom: 10px;
	padding-top: 10px;
}

.ReservationsListContainer .table tr {
	border-bottom: 1px solid rgba(202, 202, 202, 0.21);
	margin: 10px;
	color: #273846cc;
}
.ReservationsListContainer .table th {
	text-transform: uppercase;
	color: rgba(42, 53, 84, 0.52);
	font-size: 0.8em;
	font-weight: 500;
	vertical-align: middle;
	letter-spacing: 0.005em;
}

.shortText {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	max-width: 300px;
}

/* Style Input Range Slider */

input[type=range] {
	-webkit-appearance: none;
	width: 100%;
}
input[type=range]:focus {
	outline: none;
}
input[type=range]::-webkit-slider-runnable-track {
	width: 100%;
	height: 8.4px;
	cursor: pointer;
	animate: 0.2s;
	background: #273846cc;
	border-radius: 2px;
}
input[type=range]::-webkit-slider-thumb {
	border: 1px solid #273846cc;
	height: 25px;
	width: 25px;
	border-radius: 8px;
	background: #ffffff;
	cursor: pointer;
	-webkit-appearance: none;
	margin-top: -8px;
}
input[type=range]:focus::-webkit-slider-runnable-track {
	background: #273846cc;
}
input[type=range]::-moz-range-track {
	width: 100%;
	height: 8.4px;
	cursor: pointer;
	animate: 0.2s;
	background: #273846cc;
	border-radius: 2px;
}
input[type=range]::-moz-range-thumb {
	border: 1px solid #273846cc;
	height: 25px;
	width: 25px;
	border-radius: 8px;
	background: #ffffff;
	cursor: pointer;
}
input[type=range]::-ms-track {
	width: 100%;
	height: 8.4px;
	cursor: pointer;
	animate: 0.2s;
	background: transparent;
	border-color: transparent;
	border-width: 16px 0;
	color: transparent;
}
input[type=range]::-ms-fill-lower {
	background: #273846cc;
	border-radius: 8px;
	box-shadow: 1px 1px 1px #273846cc, 0px 0px 1px #273846cc;
}
input[type=range]::-ms-fill-upper {
	background: #273846cc;
	border-radius: 8px;
	box-shadow: 1px 1px 1px #273846cc, 0px 0px 1px #0d0d0d;
}
input[type=range]::-ms-thumb {
	box-shadow: 1px 1px 1px #273846cc, 0px 0px 1px #000000;
	height: 25px;
	width: 25px;
	border-radius: 8px;
	background: #ffffff;
	cursor: pointer;
}
input[type=range]:focus::-ms-fill-lower {
	background: #273846cc;
}
input[type=range]:focus::-ms-fill-upper {
	background: #273846cc;
}
.microDashboard card {
	border: none;
}
/* End of Input Range Slider style */
.NewAgreementPrice {
    min-width: 1000px !important;
}
.NewAgreementPrice .modal-header .modal-title button {
    position: absolute;
    right: 2%;
    color: white;
    font-size: 25px;
    top: 5px;
    border: none;
}
.NewAgreementPrice .modal-body {
    padding: 1.5rem;
    /* max-height: 900px; */
    overflow-y: scroll;
}
.NewAgreementPrice .modal-content {
    padding: 0;
    min-width: 999px !important;
    min-height: 700px;
}
.NewAgreementPrice .modal-footer {
    display: block;
    border: none;
}
.NewAgreementPrice .modal-header {
    -webkit-justify-content: center;
            justify-content: center;
}
.NewAgreementPrice .modal-header .modal-title {
    font-weight: 400;
    font-size: 2em;
    text-transform: uppercase;
}
.NewAgreementPrice .reservation-modal-btn-primary {
    background-color: #303e67;
    border-radius: 7px;
    font-size: 13px;
    font-weight: 400 !important;
    cursor: pointer;
}
.NewAgreementPrice .reservation-modal-btn-secondary {
    border-radius: 7px;
    font-size: 13px;
    font-weight: 400 !important;
    cursor: pointer;
}
.NewAgreementPrice .price-card {
    background: linear-gradient(145deg, rgba(31, 27, 27, 1) 0%, #475579, #465579);
    border-color: transparent;
    box-shadow: -10px 10px 6px 0px rgb(152 173 214 / 36%);
    color: white;
    padding: 0.8em 1em;
    border-radius: 10px;
    margin: 0px 0px 15px 35px;
}

.NewAgreementPrice .promo-card {
    background: linear-gradient(145deg, rgba(31, 27, 27, 1) 0%, #0082a5, #465579);
    border-color: transparent;
    box-shadow: -10px 10px 6px 0px rgb(152 173 214 / 36%);
    color: white;
    padding: 0.8em 1em;
    border-radius: 10px;
    margin: 0px 0px 15px 35px;

}

.NewAgreementPrice .price-card button {
    background-color: white;
    border-color: white;
    color: #3e4c6d;
    padding: 5px 20px;
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 500;
}
.NewAgreementPrice .promo-card button {
    background-color: white;
    border-color: white;
    color: #3e4c6d;
    padding: 5px 20px;
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 500;
}

.NewAgreementPrice .price-card .price {
    background-color: #E5E5E5;
    color: #000000;
}
.NewAgreementPrice .price-card .card-body {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
}
.NewAgreementPrice .promo-card .card-body {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
}

.NewAgreementPrice .price-card p, .NewAgreementPrice .card-title {
    font-size: 1.3em;
    margin-bottom: 0;
    line-height: 2em;
}
.NewAgreementPrice .promo-card p, .NewAgreementPrice .card-title {
    font-size: 1.3em;
    margin-bottom: 0;
    line-height: 2em;
}
.NewAgreementPrice .price-card .price-card-count {
    background: #c2ff71;
    padding: 5px 10px;
    border-radius: 15px;
    font-size: 12px;
    font-weight: 500;
    color: black;
    float: right;
    top: -2px;
    right: -7px;
    position: relative;
}
.NewAgreementPrice .card {
    border-color: transparent
}

.NewAgreementPrice .plan-detail-card {
    /* background: #475579; */
    background: linear-gradient(145deg, rgba(31, 27, 27, 1) 0%, #475579, #465579);
    color: white;
    padding: 0.75rem 1rem;
    margin-top: 20px;
    border-radius: 10px;
}
.NewAgreementPrice .plan-detail-card.active {
    background: #475579;
}

.NewAgreementPrice .promo-detail-card {
    /* background: #0082a5; */
    background: linear-gradient(145deg, rgba(30, 27, 27, 1) 0%, #0082a5, #465579);
    color: white;
    padding: 0.75rem 1rem;
    margin-top: 20px;
    border-radius: 10px;
}
.NewAgreementPrice .promo-detail-card .card-header {
    background: transparent !important;
    margin-left: 0;
    padding-left: 0;
}
.NewAgreementPrice .plan-detail-card .card-header {
    background: transparent !important;
    margin-left: 0;
    padding-left: 0;
}

.NewAgreementPrice .promo-detail-card.active {
    background: #0082a5;
}

.NewAgreementPrice .promo-detail-card label,
.NewAgreementPrice .plan-detail-card label {
    font-size: 1rem;
    font-weight: 500 !important;
    padding-bottom: 7px;
}
.NewAgreementPrice .price-card label,
.NewAgreementPrice .promo-detail-card label,
.NewAgreementPrice .plan-detail-card label {
    color: white;
    font-weight: 100;
    opacity: 1;
}
.NewAgreementPrice .plan-detail-card .plan-tiers label {
    display: block;
}
.NewAgreementPrice .plan-detail-card .plan-tiers p {
    font-size: 14px;
    width: 25%;
    display: inline-block;
    padding: 0px 5px;
    margin-right: 10px;
}

.NewAgreementPrice .plan-detail-card button {
    background-color: white;
    border-color: white;
    color: #3e4c6d;
    width: 55%;
    padding: 0;
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 500;
}
.NewAgreementPrice .plan-detail-card .card-body {
    padding: 0;
}
.NewAgreementPrice .promo-detail-card .card-body {
    padding: 0;
}
.NewAgreementPrice .plan-detail-card i.remove-plan span {
    font-family: 'Rubik';
}
.NewAgreementPrice .promo-detail-card i.remove-plan span {
    font-family: 'Rubik';
}
.NewAgreementPrice .plan-detail-card i.remove-plan {
    position: absolute;
    bottom: 20px;
    right: 25px;
    color: white;
    border: none;
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
}

.NewAgreementPrice .review-plan-details {
    border: 2px solid #e4c83c87;
    border-radius: 5px;
    margin: 0px 0px 15px 0px;
    padding-top: 15px;
    width: 100%;
}
.NewAgreementPrice .plan-detail-card i.remove-plan {
    position: absolute;
    bottom: 0px;
    right: 25px;
    color: white;
    border: none;
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
}
.NewAgreementPrice .card-header {
    font-weight: 600 !important;
    font-size: 15px !important;
    line-height: 2rem !important;
}
.NewAgreementPrice .card-footer {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
}
.NewAgreementPrice .card-text b {
    display: block;
    line-height: 10px;
    padding-top: 15px;
}
.NewAgreementPrice i.fa-check-square {
    color: #6dec6d;
    font-size: 18px;
    padding-top: 5px;
    padding-right: 5px;
}
.NewAgreementPrice .card-text b span {
    font-size: 24px;
}
/* .swiper-button-prev {
    left: -2px !important;
    color: black !important;
}
.swiper-button-next {
    right: -2px !important;
    color: black !important;
} */
.NewAgreementPrice i.switch {
    font-size: 25px;
    border: none;
}
.NewAgreementPrice .form-control {
    font-size: 14px;
}
.NewAgreementPrice a {
    cursor: pointer;
    text-decoration: underline;
}

.NewAgreementPrice .drivers-list {
    border: none;
    border-radius: 10px;
    padding: 10px;
    box-shadow: -1px 4px 10px -1px rgba(0, 0, 0, 0.4);
    -webkit-box-shadow: -1px 4px 10px -1px rgba(0, 0, 0, 0.4);
    -moz-box-shadow: -1px 4px 10px -1px rgba(0, 0, 0, 0.4);
    /* min-width: 250px; */
}

.NewAgreementPrice label {
    opacity: .85;
    letter-spacing: .75px;
    text-transform: uppercase;
    font-size: 80%;
    font-weight: 500;
    color: #000;
}
.NewAgreementPrice .car-name-years .car-name {
    max-width: 170px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.NewAgreementPrice .selection-container {
    border: 1px solid #E5E5E5;
    border-radius: 10px;
    text-align: center;
}

.NewAgreementPrice .selection-container .disable-car, .NewAgreementPrice .selection-container .disable-car:hover {
    color: gray;
    cursor: text;
}

.NewAgreementPrice .selected {
    color: #ffffff;
    background-color: #3e4c6d;
    border-radius: 10px;
}

.NewAgreementPrice .user-icon .fa {
    padding: 10px 13px;
    border-radius: 50%;
    position: relative;
    color: #3e4c6d;
    background-color: #ffffff;
}

.NewAgreementPrice .edit-item {
    position: relative;
}
.NewAgreementPrice .edit-item .edit-icon {
    position: absolute;
    right: 10px;
    top: 10px;
}
.NewAgreementPrice .edit-item .edit-icon {
    position: relative;
    position: absolute;
    right: 10px;
    top: 10px;
}
.selection-box {
    min-height: 100px;
    max-height: 150px;
    width: 533px;
    border-radius: 10px;
    margin: 15px;
    background-color: #FFFFFF;
    -webkit-justify-content: space-evenly;
            justify-content: space-evenly;
    padding: 2rem;
    cursor: pointer;
    z-index: 1000;
    border-radius: 10px;
    box-shadow: 0px 5px 5px 1px rgba(0, 0, 0, 0.25);
    -webkit-box-shadow: 0px 5px 10px 1px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0px 5px 3px 1px rgba(0, 0, 0, 0.25);
}
.selection-box-text {
    font-weight: 500;
    font-size: 15px;
    line-height: 24px;
    color: #949494;
}
.selection-box-icon {
    line-height: 41.75px;
    font-weight: 400;
    font-size: 20px;
}
.close-selection-box-icon {
    position: absolute;
    top: -35px;
    right: 5px;
    font-size: 20px;
}
.grey-link {
    color: #ffffff
}
.rental-list-container .row {
	margin-top: 5px;
	margin-bottom: 10px;
	font-size: 0.93em;
}
.rental-list-container {
	color: #273846cc;
	font-family: 'Rubik', 'San serif';
}
.rental-list-container .btn-outline-primary, .rental-list-container .btn-outline-primary {
	color: #52606b;
	border-color: #52606b;
	background-color: transparent;
}
.rental-list-container .btn-outline-primary:not(:disabled):not(.disabled):active, .rental-list-container .btn-outline-primary:not(:disabled):not(.disabled).active, .rental-list-container .show > .btn-outline-primary.dropdown-toggle {
	color: #fff;
	background-color: #52606b;
	border-color: #52606b;
}
.rental-list-search-filter {
	position: relative;
}
.rental-list-search-filter .search-icon {
	position: absolute;
	right: 15px !important;
	top: 9px !important;
	color: #d7dce2;
}
.search-filter-preview {
	border: 1px dashed #52606b;
	border-radius: 15px;
	padding: 5px 10px;
	margin-right: 5px;
}
.search-filter-preview span {
	color: #273846cc;
	text-transform: capitalize;
	border-right: 1px solid #52606b;
	padding-right: 5px;
	margin-right: 5px;
}
.search-filter-preview {
	color: #0B80F9;
}
.rental-list-search-filter input {
	border-radius: 15px;
	border-width: 3px !important;
}
.rental-list-button {
	color: #0B80F9 !important;
	cursor: pointer;
}

.rental-list-button:hover {
	text-decoration: underline !important;
}

.rental-list-container .btn-outline-primary:hover, .rental-list-container .btn-outline-primary:active, .rental-list-container .btn-outline-primary:focus,
.rental-list-container .btn-outline-primary:not(:disabled):not(.disabled):active {
	color: #fff;
	background-color: #52606b;
	border-color: #52606b;
}

.rental-list-container .nav-pills .nav-item {
	margin: 10px;
}
.popover {
	max-width: 500px;
}
.rental-list-container .btn-link, .rental-list-container a {
	color: #52606b;
	text-decoration: none;
}
.rental-list-container .uppercase {
	text-transform: uppercase;
}
.rental-list-container .capitalize {
	text-transform: lowercase;
}
.rental-list-container .capitalize::first-letter {
	text-transform: capitalize;
}
.rental-list-container .linkHoverEffect {
	position: relative;
}
.rental-list-container .linkHoverEffect:hover:after {
	width: 100%;
	transition: all 0.2s ease-in-out;
}
.rental-list-container .linkHoverEffect:after {
	position: absolute;
	content: "";
	width: 0%;
	margin-top: 4px;
	height: 2px;
	background-color: #52606b;
	left: 0;
	bottom: 0;
	transition: all 0.2s ease-in-out;
}
i {
	cursor: pointer;
}
.rental-list-container .alert {
	text-align: center;
	border: none;
}
.rental-list-container .alert-danger {
	background-color: #FF4F4F;
	color: #fff;

}
.rental-list-container .danger {
	color: #FF4F4F;
}
.rental-list-container .alert-primary {
	background-color: #273846cc;
	color: #fff;
}
.rental-list-container .card {
	box-shadow: 3px 3px 9px 2px rgba(202, 202, 202, 0.21);
	border: none;
	border-radius: 8px;
}

.rental-list-container .table th, .table td {
	border: none;
	padding-bottom: 10px;
	padding-top: 10px;
}

.rental-list-container .table tr {
	border-bottom: 1px solid rgba(202, 202, 202, 0.21);
	margin: 10px;
	color: #273846cc;
}
.rental-list-container .table th {
	text-transform: uppercase;
	color: rgba(42, 53, 84, 0.52);
	font-size: 0.8em;
	font-weight: 500;
	vertical-align: middle;
	letter-spacing: 0.005em;
}

.shortText {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	max-width: 300px;
}

.rental-detail-actions {
	padding-right: 1.5rem;
	margin: 0;
}
.rental-detail-actions li {
	display: inline-block;
	margin-right: 10px;
}
.rental-detail-actions li:not(:first-child) {
	border-left: 1px solid #e1e1e1;
	padding-left: 10px;
}

/* Style Input Range Slider */

input[type=range] {
	-webkit-appearance: none;
	width: 100%;
}
input[type=range]:focus {
	outline: none;
}
input[type=range]::-webkit-slider-runnable-track {
	width: 100%;
	height: 8.4px;
	cursor: pointer;
	animate: 0.2s;
	background: #273846cc;
	border-radius: 2px;
}
input[type=range]::-webkit-slider-thumb {
	border: 1px solid #273846cc;
	height: 25px;
	width: 25px;
	border-radius: 8px;
	background: #ffffff;
	cursor: pointer;
	-webkit-appearance: none;
	margin-top: -8px;
}
input[type=range]:focus::-webkit-slider-runnable-track {
	background: #273846cc;
}
input[type=range]::-moz-range-track {
	width: 100%;
	height: 8.4px;
	cursor: pointer;
	animate: 0.2s;
	background: #273846cc;
	border-radius: 2px;
}
input[type=range]::-moz-range-thumb {
	border: 1px solid #273846cc;
	height: 25px;
	width: 25px;
	border-radius: 8px;
	background: #ffffff;
	cursor: pointer;
}
input[type=range]::-ms-track {
	width: 100%;
	height: 8.4px;
	cursor: pointer;
	animate: 0.2s;
	background: transparent;
	border-color: transparent;
	border-width: 16px 0;
	color: transparent;
}
input[type=range]::-ms-fill-lower {
	background: #273846cc;
	border-radius: 8px;
	box-shadow: 1px 1px 1px #273846cc, 0px 0px 1px #273846cc;
}
input[type=range]::-ms-fill-upper {
	background: #273846cc;
	border-radius: 8px;
	box-shadow: 1px 1px 1px #273846cc, 0px 0px 1px #0d0d0d;
}
input[type=range]::-ms-thumb {
	box-shadow: 1px 1px 1px #273846cc, 0px 0px 1px #000000;
	height: 25px;
	width: 25px;
	border-radius: 8px;
	background: #ffffff;
	cursor: pointer;
}
input[type=range]:focus::-ms-fill-lower {
	background: #273846cc;
}
input[type=range]:focus::-ms-fill-upper {
	background: #273846cc;
}

.rental-list-container .filter-container .rental-list-search-filter {
	margin-bottom: 1rem
}

.rental-list-container row.filter-rental-container col {
	-webkit-align-self: center;
	        align-self: center;
}

.rental-list {
	/* overflow-y: auto; */
}

.rental-list::-webkit-scrollbar-track {
	-webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3);
}

.rental-list::-webkit-scrollbar {
	width: 5px;
	height: 5px;
}

.rental-list::-webkit-scrollbar-thumb {
	background-color: #303e67;
	outline: 1px solid #303e67;
}
.rental-list-container .stage-badge {
	margin: 5px;
	font-weight: 400 !important;
	font-size: 14px;
	padding: 5px;
    text-transform: uppercase;
}
.rental-list-container .branch-badge {
	text-transform: uppercase;
	padding: 5px;
    background-color: #0B80F9;
    margin-left: 0px;
}
.rental-list {
	/* max-height: 60vh;
	overflow-y: auto; */
}
.rental-icon-wrapper {
	border-right: 1px solid #e1e1e1;
	-webkit-align-self: center;
	align-self: center;
	text-align: center;
}
.rental-detail-block {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: row;
	        flex-direction: row;
	-webkit-justify-content: space-between;
	        justify-content: space-between;
	font-size: 13px;
}
/* End of Input Range Slider style */
.NotificationsListModal {
    margin-right: 20px;
    top: 10vh;
}
.NotificationsListModal .modal-header {
    background-color: rgb(65, 80, 93);
    color: #ffffff;
}
.NotificationsListModal .modal-body {
    padding: 10px 0;
}
.notificationContent {
    margin-bottom: 10px;
}
.NotificationsListModal .unRead {
    background-color: #596c7d;
    color: #FFF;
}
.unRead a, .unRead a:link, .unRead a:visited {
    text-decoration: none;
    color: #91efcc !important;
}

.notificationContent a, .notificationContent a:link, .notificationContent a:visited {
    text-decoration: none;
    color: #5b3a88;
}

.NotificationUpdate a, .NotificationUpdate a:link, .NotificationUpdate a:visited {
    text-decoration: none;
    color: #91efcc;
}
.StyledToast{
    padding: 20px 15px;
    background-color: rgb(225 235 239 / 67%);
    border-radius: 8px;
}

.StyledToast .toastHeader{
    border-bottom: 1px solid hsla(0,0%,79.2%,.21);
    font-weight: 500;
    padding-bottom: 5px;
}
.form-control:disabled {
    background-color: #DCDCDC !important;
}
.driver-detail {
    color: #525f7f;
    font-family: 'Poppins', 'Rubik', 'San serif' !important;
    font-size: 13px;
}
.driver-detail .badge {
    font-size: 14px;
    padding: 6px 10px;
    border-radius: 6px;
    font-weight: 400;
}
.driver-detail .badge.black {
    background-color: #263456;
    color: #FFF;
}
.driver-detail .badge.success {
    background-color: #02BD7D;
    color: #FFF;
}
.driver-detail .badge.danger {
    background-color: #FF0000;
    color: #FFF;
}
.driver-detail .badge.warning {
    background-color: #FFA500;
    color: #FFF;
}
.driver-detail .danger {
    color: #FF5661
}
.driver-detail .success {
    color: #02BD7D
}
.driver-detail .black {
    color: #263456
}
.driver-detail a.ghost, .driver-detail .btn.ghost {
    background-color: transparent;
    color: #525f7f;
    border: 1px solid #525f7f;
    border-radius: 4px;
    padding: 4px 8px;
    text-decoration: none;
}
.driver-detail .btn.primary {
    background-color: #525f7f;
    color: #FFF;
    border-color: transparent;
}
.driver-detail .btn.success {
    background-color: #02BD7D;
    color: #FFF;
    border-color: transparent;
}
.driver-detail-container .info-container {
    background-color: #F0F0EB;
    padding: 2rem;
    margin-bottom: 2rem;
    border-radius: 10px;
    box-shadow: 0px 5px 5px -1px rgb(0 0 0 / 14%);
    -webkit-box-shadow: 0px 5px 5px -1px rgb(0 0 0 / 14%);
    -moz-box-shadow: 0px 5px 5px -1px rgb(0 0 0 / 14%);
}
.driver-detail-container .info-container.set-max-height {
    max-height: 250px;
    overflow-y: scroll;
}
.balance-box-wrapper {
    height: 120px;
    width: 533px;
    border-radius: 10px;
    margin: 15px;
    background-color: #FFFFFF;
    -webkit-justify-content: space-evenly;
            justify-content: space-evenly;
    padding: 2rem;
    border-radius: 10px;
    border-top: solid 0px #C9C9C9;
    box-shadow: 0px 5px 5px 1px rgba(0, 0, 0, 0.25);
    -webkit-box-shadow: 0px 5px 10px 1px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0px 5px 3px 1px rgba(0, 0, 0, 0.25);
}
.balance-box-wrapper-text {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #949494;
}
.balance-box-wrapper-icon {
    line-height: 51.75px;
    font-weight: 400;
    font-size: 45px;
}
.input-alternative-box {
    height: 35px;
    width: 100%;
    background-color: #DCDCDC;
    border-radius: 0.25rem;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    padding-left: 10px;
    cursor: pointer;
}
.driver-header {
    font-family: 'Poppins';
    display: -webkit-flex;
    display: flex;
    background-color: #E0E0E0;
    color: black;
    margin-bottom: 54px;
    padding: 0px 20px 10px 20px;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
    border-radius: 12px;
    position: relative;
}
.driver-header .driver-id-wrapper {
    background-color: #2DAFD3;
    color: white;
    top: 15%;
    right: 0px;
    padding: 0.25rem 1.75rem 0.25rem 1.5rem;
    border-top-left-radius: 7px;
    border-bottom-left-radius: 7px;
    position: absolute;
}
.driver-header .driver-header-icon {
    background-color: #1B4D7E;
    color: white;
    width: 40px;
    height: 30px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
}
.driver-header .driver-header-label {
    font-family: 'Poppins';
    font-size: 18px;
    line-height: 20px;
    font-weight: 500;
    font-style: normal;
    cursor: pointer !important;
}
.driver-header .driver-header-label:hover {
    text-decoration: underline;
}
.driver-header .driver-header-input {
    /* min-width: 270px !important; */
    background-color: white;
}
.driver-header .btn {
    font-size: 0.85em;
}
.driver-header .btn.ghost:hover, .driver-header .btn.ghost:focus, .driver-header .btn.ghost:active {
    background-color: #263456;
    color: #FFF;
    border-color: transparent;
}
.driver-header .btn.ghost {
    background-color: transparent;
    color: #525f7f;
    border: 1px solid #525f7f;
    border-radius: 4px;
    padding: 4px 8px;
    text-decoration: none;
}
.driver-header div {
    -webkit-align-self: flex-end;
            align-self: flex-end;
}
.driver-header .driver-image {
    border: 5px solid #f2f3f6;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    position: relative;
    top: 40px;
    background-color: #C6CBDE;
}
.driver-name {
    font-weight: 500;
    line-height: 25px;
    margin-right: auto;
    margin-left: 0.5em;
    /* position: absolute;
    left: 155px;
    bottom: 0px; */
    font-size: 1.25em;
}
.driver-header .badge.success {
    background-color: #02BD7D;
    color: #FFF;
}

.driver-header .badge.black {
    background-color: #263456;
    color: #FFF;
}

.driver-header .header-detail {
    font-size: 14px;
    margin-bottom: 0;
}
.tab-container {
    width: 100%;
    background-color: #FFFFFF;
    padding: 2.5rem;
    border: 1px solid #DADADA;
    min-height: 150px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}
.tab-container .blue-highlighted-header {
    background-color: #2DAFD3;
    color: white;
    font-size: 16px !important;
    font-weight: 500;
    text-transform: uppercase;
    padding: 0.5rem 1.5rem;
}
.tab-container .green-highlighted-header {
    background-color: green;
    color: white;
    font-size: 16px !important;
    font-weight: 500;
    text-transform: uppercase;
    padding: 0.5rem 1.5rem;
}
.driver-detail-container .tab-container .info-container button {
    background-color: #263456;
    border-radius: 6px;
    padding: 6px 10px;
}
.driver-detail-container .tab-container .info-container button:hover {
    background-color: #f2f3f6;
    color: #000000
}
.driver-detail .nav-item {
    /* font-size: 14px;
    font-weight: 400; */
    text-transform: uppercase;
    font-family: "Poppins";
    /* color: #000000; */
    text-align: center;
    /* margin-right: 0.75rem; */
    border: none;
    /* border-radius: 0px !important; */
    background-color: transparent;
    /* border: 1px solid #DADADA; */
    /* border-bottom: 0px; */
    /* border-top-left-radius: 10px !important;
    border-top-right-radius: 10px !important; */
    /* background-color: #F0F0EB; */
}
.driver-detail .nav-tabs {
    border: none;
}

.nav-tabs .nav-link {
    color: #1B4D7E;
}
.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    font-weight: 500;
    color: #1B4D7E;
    border: none;
    border-bottom: 3px solid #1B4D7E;
    background-color: transparent;
}

.driver-detail .nav-tabs .nav-item .nav-link:hover {
    /* color: #1B4D7E;
    border: none;
    border-radius: 0px !important; */
    border-bottom: 3px solid #1B4D7E;
}
.driver-detail .nav-tabs .nav-item .nav-link {
    cursor: pointer;
    font-size: 0.85em;
    padding: 0.25rem 1.25rem !important;
    border-radius: 0px !important;
    border-right: 1px solid #DADADA;
}

.driver-detail .nav-item:nth-child(n+2):after {
    margin-left: 2rem;
}
.driver-detail-container .info-container label {
    opacity: 0.85;
    letter-spacing: 0.75px;
    text-transform: uppercase;
    font-size: 80%;
    font-weight: 500;
    line-height: 18.75px;
    color: #000000;
}
.tab-container label {
    opacity: 0.85;
    letter-spacing: 0.75px;
    text-transform: uppercase;
    font-size: 80%;
    font-weight: 500;
    color: #000000;
}
.driver-detail-container .tab-container .info-container > .row {
    margin-bottom: 1rem;
}
.driver-detail-container .tab-container .info-container .status-stage {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
}
.driver-detail-container .tab-container .info-container .row:last-child {
    margin-bottom: 0rem;
}
.driver-detail-container .tab-container .info-container input[type="text"]:disabled {
    background-color: #FFFFFF;
}
.zero-padding {
    padding: 0px
}
.driver-detail .tab-container:last-child {
    margin-bottom: 0rem;
}
.driver-detail .tab-container table {
    width: 100%;
}
.driver-detail .tab-container table thead {
    background-color: #F0F0EB;
    color: #000000;
}
.driver-detail .tab-container table tr {
    border: 1px solid rgba(202, 202, 202, 0.21);
    margin: 10px;
}
.driver-detail .tab-container table th, table td {
    border: none;
    padding-bottom: 10px;
    padding-top: 10px;
    padding-left: 15px;
}
.driver-detail .tab-container table th {
    font-size: 15px;
    font-weight: 500;
}
.driver-detail .tab-container table td {
    padding-left: 20px;
}
.driverHeader {
    border-radius: 18px;
    border: 1px solid #E0E0E0;
    background: #E0E0E0;
    min-height: 20rem;
    height: 25rem;
    margin-bottom: 15px;
}
.driver-detail .btn.ghost:hover, .driver-detail .btn.ghost:focus, .driver-detail .btn.ghost:active {
    background-color: #263456;
    color: #FFF;
    border-color: transparent;
}
.toggle-switch {
    position: relative;
    width: 60px;
    padding: 0px 9px;
    display: inline-block;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    text-align: left;
}
.toggle-switch-checkbox {
    display: none;
}
.toggle-switch-label {
    opacity: 0.85;
    display: block;
    overflow: hidden;
    cursor: pointer;
    border: 0 solid #bbb;
    border-radius: 20px;
    margin: 0;
}
.toggle-switch-inner {
    display: block;
    width: 200%;
    margin-left: -100%;
    transition: margin 0.1s ease-in 0s;
}
.toggle-switch-inner:before, .toggle-switch-inner:after {
    display: block;
    float: left;
    width: 50%;
    height: 24px;
    padding: 0;
    line-height: 24px;
    font-size: 14px;
    color: white;
    font-weight: 500;
    box-sizing: border-box;
}
.toggle-switch-inner:before {
    content: "";
    text-transform: uppercase;
    padding-left: 10px;
    background-color: #DC3546;
    color: #fff;
}
.toggle-switch-disabled {
    background-color: #ddd;
    cursor: not-allowed;
}
.toggle-switch-disabled:before {
    background-color: #ddd;
    cursor: not-allowed;
}
.toggle-switch-switch {
    display: block;
    width: 18px;
    margin: 3px;
    background: #fff;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 25px;
    border: 0 solid #bbb;
    border-radius: 20px;
    transition: all 0.1s ease-in 0s;
}
.toggle-switch-checkbox:checked + .toggle-switch-label .toggle-switch-inner {
    margin-left: 0;
}
.toggle-switch-checkbox:checked + .toggle-switch-label .toggle-switch-switch {
    right: 10px;
}
.confirm, .confirm:hover {
    background-color: #dc3545;
}
.cancel, .cancel:hover {
    background-color: rgb(27, 77, 126);
}
.driver-detail-container .change-view {
    margin-bottom: 15px;
}
.driver-detail i.switch {
    font-size: 25px;
    border: none;
}
.rental-container .car-agreement {
    border-bottom: 1px solid #D6D6D6 !important;
    padding: 0.5rem 1.5rem !important;
    display: -webkit-flex !important;
    display: flex !important;
    -webkit-flex-direction: row !important;
            flex-direction: row !important;
}
#more-agreements {
    margin-top: 10px !important;
    cursor: pointer !important;
    position: absolute;
    right: 20px;
}
.rental-container .car-agreement.active,
.rental-container .car-agreement:focus,
.rental-container .car-agreement:hover {
    background-color: #F0F0EB !important;
}
.rental-container .car-agreement span {
    -webkit-align-self: center !important;
            align-self: center !important;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}
.rental-container .car-agreement h4 {
    font-family: "Poppins" !important;
    padding-left: 1rem !important;
    font-weight: 500 !important;
    padding-top: 0.55rem !important;
    max-width: 200px !important;
    font-size: 15px !important;

}

.charge-day-history {
    max-height: 300px;
    overflow-x: hidden;
    overflow-y: auto;
}


.sidebar-listing-wrapper-icon {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}
.sidebar-listing-wrapper-text {
    margin-left: 10px;
}
.sidebar-listing-wrapper-text p {
    font-size: 15px;
    font-weight: 600;
    font-family: "Poppins";
    padding: 5px !important;
    margin: 0px !important;
}
.sidebar-listing-wrapper-text small {
    padding: 5px !important;
}

.submit-btn {
    background-color: #0B80F9;
    border: 1px solid #0B80F9;
    border-radius: 7px;
}
.submit-btn:hover {
    color: #41505d;
    background-color: transparent;
    border: 1px solid #41505d;
}
.driverAddress .btn {
    border-radius: 7px;
    font-size: 13px;
    font-weight: 400 !important;
}
.driverAddress .btn.btn-primary {
    background-color: #0B80F9;
}
.driverAddress .modal-content {
    background-color: white;
    border-radius: 0px;
    color: black;
}
.driverAddress .modal-content .modal-body {
    max-height: 75vh;
    overflow-y: auto;
    overflow-x: hidden;
}
.driverAddress .modal-content small,
.driverAddress .modal-content label {
    color: rgb(73, 73, 73);
    text-transform: uppercase;
    letter-spacing: 0.75px;
}
.driverAddress .modal-content .modal-header {
    -webkit-justify-content: center;
            justify-content: center;
}
.driverAddress .modal-content .modal-header .modal-title {
    font-weight: 400;
    font-size: 1.75em;
    text-transform: uppercase;
}
.driver-tracker-modal .modal-content .modal-body > .row:hover .selectButtonParent, .driver-tracker-modal .modal-content .modal-body > .row:active .selectButtonParent {
    left: 0;
    opacity: 1;
}
.tracker-actions {
    margin: 0px;
    padding: 0px;
}
.tracker-wrapper .tracker-header {
    margin-bottom: 1.2rem;
}
.tracker-actions li:not(:first-child) {
    border-left: 1px solid #e1e1e1;
    padding-left: 10px;
}
.tracker-actions li {
    display: inline-block;
}
.TransactionListNew .table-responsive {
    max-height: 500px;
    overflow-y: auto;
}
.TransactionListNew .table thead th {
    text-transform: uppercase;
    padding-top: 1rem;
    padding-bottom: 1rem;
    font-family: 'Poppins';
    font-weight: 600;
    font-size: 15px;
    line-height: 22px;
    letter-spacing: 0.15px;
    position: "sticky";
    top: -1;
}
.TransactionListNew .no_decoration {
    color: #525f7f !important;
}
.TransactionListNew tr.success td {
    color: #02BD7D;
}
.TransactionListNew tr.danger td {
    color: #FF5661;
}
.TransactionListNew .table th, .table td {
    color: #525f7f;
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
    font-size: 14px !important;
}
.TransactionListNew .shortText {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 300px;
}
.TransactionListNew .table th, .table td {
    border: none;
}
.TransactionListNew .table tr th {
    opacity: .8;
}
.TransactionListNew .table tr {
    border-bottom: 1px solid rgba(187, 187, 187, 0.32);
}
.TransactionListNew .btn.btn-link {
    margin: 0;
    color: #525f7f;
    font-weight: 500;
    text-decoration: none;
}
.TransactionListNew .btn.ghost:focus, .TransactionListNew .btn.ghost:active {
    background-color: transparent;
    color: #525f7f;
    border-color: #525f7f;
}

.stripe-payment-modal .btn {
    border-radius: 7px;
    font-size: 13px;
    font-weight: 400 !important;
}
.stripe-payment-modal .btn.btn-primary {
    background-color: #0B80F9;
}
.stripe-payment-modal .modal-content {
    background-color: white;
    border-radius: 0px;
    color: black;
}
.stripe-payment-modal .modal-content .modal-body {
    max-height: 75vh;
    overflow-y: auto;
    overflow-x: hidden;
}
.stripe-payment-modal .modal-content .modal-body::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}
.stripe-payment-modal .modal-content .modal-body::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3);
}
.stripe-payment-modal .modal-content .modal-body::-webkit-scrollbar-thumb {
    background-color: #303e67;
    outline: 1px solid #303e67;
}
.stripe-payment-modal .modal-content small,
.stripe-payment-modal .modal-content label {
    color: rgb(73, 73, 73);
    text-transform: uppercase;
    letter-spacing: 0.75px;
}
.stripe-payment-modal .modal-content .modal-header {
    -webkit-justify-content: center;
            justify-content: center;
    text-transform: uppercase;
}
.stripe-payment-modal .modal-content .modal-header .modal-title {
    font-weight: 400;
    font-size: 1.75em;
    text-transform: uppercase;
}
.stripe-payment-modal .modal-content .issue-modal-jobs-wrapper .card {
    margin: 7px 0px;
}
.stripe-payment-modal .modal-content .issue-modal-jobs-wrapper .card-body {
    padding: 7px 15px;
    background-color: #0000000e;
}
.stripe-payment-modal .modal-content .issue-modal-jobs-wrapper .card-body .row {
    padding-top: 0.45rem;
}
.stripe-payment-modal .modal-content .issue-modal-jobs-wrapper label {
    margin-bottom: 0;
}
.stripe-payment-modal .modal-content .issue-modal-collapse-btn {
    width: 100%;
    border-radius: 0px;
    text-transform: uppercase;
    background-color: #0000008c;
    border: none;
}
.stripe-payment-modal .issue-extra-info-wrapper {
    margin-top: 1.5rem;
    padding-top: 1.25rem;
    border-top: 1px solid #dee2e6;
}
.aggregate-info-preview {
    float: right;
    background-color: #f0f0eb;
    font-weight: 600;
}
.transaction-action-items ul {
    list-style-type: none;
    padding-left: 0;
}
.transaction-action-items ul li {
    min-width: 100px;
    padding: 0.5rem 1rem 0.5rem 0.5rem;
    border-bottom: 1px solid #C0C8D2;
}

.transaction-info-modal.modal-dialog {
    min-width: 720px !important;
}
.transaction-info-modal .modal-content {
    background-color: white;
    color: #525f6f;
}
.transaction-info-modal .modal-content .modal-body {
    max-height: 75vh;
    overflow-y: auto;
    overflow-x: hidden;
}
.transaction-info-modal .modal-content .modal-body::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}
.transaction-info-modal .modal-content .modal-body::-webkit-scrollbar-thumb {
    background-color: #303e67;
    outline: 1px solid #303e67;
}
.transaction-info-modal .modal-content .toggle-section-heading i {
    float: right;
    margin-top: 0.25rem;
}
.transaction-info-modal .modal-content .toggle-section-heading {
    display: block;
    color: #525f6f;
    background-color: #D6D6D6;
    border-radius: 10px;
    padding: 0.5rem 1rem;
    font-size: 1em;
    margin-bottom: 1rem;
}
.transaction-info-modal .modal-content .toggle-section-heading.active {
    background-color: #303e67;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    color: #ffffff;
}

.transaction-info-modal .modal-content label {
    color: #525f6f;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    font-size: 80%;
    font-weight: 600;
}
.transaction-info-modal .modal-content p {
    margin-bottom: 0.25rem;

}
.transaction-info-modal.modal-dialog row {
    margin-bottom: 15px !important;
}

.transaction-info-modal .modal-content .modal-header {
    -webkit-justify-content: center;
            justify-content: center;
}

.transaction-info-modal .modal-content .modal-header .modal-title {
    font-weight: 400;
    font-size: 1.75em;
    text-transform: uppercase;
}
.transaction-info-modal-primary-btn {
    background-color: #303e67;
    border-radius: 7px;
    font-size: 13px;
    font-weight: 400;
    color: white;
    cursor: pointer;
}
.transaction-info-modal-secondary-btn {
    background-color: white;
    border-radius: 7px;
    font-size: 13px;
    font-weight: 400;
    color: black;
    cursor: pointer;
}
.transaction-info-modal-danger-btn {
    background-color: red;
    border-radius: 7px;
    font-size: 13px;
    font-weight: 400;
    color: white;
    cursor: pointer;
    border: #ffffff;
}
.btn-container {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    margin: 20px;
}
.insurance-primary-btn {
    background-color: #303e67;
    width: 50% !important;
    color: #ffffff;
    height: 150px !important;
    font-size: large;
    border-radius: 10px;
    font-weight: 400;
    text-transform: uppercase;
    opacity: 0.7;
    border: none;
}
#transaction-info-modal-popover {
    width: 100%;
    margin-bottom: 10px;
    border: solid 1px #303e67;
}
#transaction-info-modal-popover-header {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    text-transform: uppercase;
}
#transaction-info-modal-popover-body {
    font-size: 13px;
}
.charge-driver-modal.modal-dialog {
	max-width: 800px !important;
    border-radius: 0px !important;
    padding: 0px !important;
}
.charge-driver-modal .modal-content {
	background: #FFF;
	color: #525f6f;
    /* padding: 0px !important; */
}
.charge-driver-modal .modal-content .modal-body {
	max-height: 75vh;
	overflow-y: auto;
	overflow-x: hidden;
}
​
.charge-driver-modal .modal-content .modal-header {
    background-color: #1B4D7E !important;
    color: green !important;
    font-size: large !important;
}
.charge-driver-modal-primary-btn {
	background-color: #1B4D7E;
	border-radius: 7px;
	font-size: 13px;
	font-weight: 400;
	color: white;
	cursor: pointer;
}
.charge-driver-modal-secondary-btn {
	background-color: white;
	border-radius: 7px;
	font-size: 13px;
	font-weight: 400;
	color: black;
	cursor: pointer;
}
.charge-driver-modal-danger-btn {
	background-color: red;
	border-radius: 7px;
	font-size: 13px;
	font-weight: 400;
	color: white;
	cursor: pointer;
	border: #ffffff;
}
.NewTransaction{
	color:#273846cc;
	font-family: 'Rubik', 'San serif';
}
.NewTransaction .modal{
	background-color:#273846cc;
}
.modal-content{
    padding: 20px;
}
.form-group {
    margin-bottom: 2rem;
}
.form-control{
    border: 1px solid #d7dce2;
}
.categoryOption{
    background-color: rgba(239, 241, 251, .31);
    border: none;
    color: #A143FF;
    font-weight: 500;
    font-size: 16px;
    margin-right: 7px;
}
.categoryOption:hover {
    color: #fff;
    background-color: #000;
    border-color: transparent;
}
.categoryOption.active,.categoryOption:not(:disabled):not(.disabled):active, .categoryOption:not(:disabled):not(.disabled).active, .show > .categoryOption.dropdown-toggle {
    color: #fff;
    background-color: #a143ff;
    border-color: transparent;
}
.modal-dialog {
    max-width: 600px;
}
.payment-schedule-modal-primary-btn {
    background-color: #1B4D7E;
    border-radius: 7px;
    font-size: 13px;
    font-weight: 400;
    color: white;
    cursor: pointer;
}
.payment-schedule-modal-secondary-btn {
    background-color: white;
    border-radius: 7px;
    font-size: 13px;
    font-weight: 400;
    color: black;
    cursor: pointer;
}
.payment-schedule-modal-danger-btn {
    background-color: red;
    border-radius: 7px;
    font-size: 13px;
    font-weight: 400;
    color: white;
    cursor: pointer;
    border: #ffffff;
}

.payment-schedule-modal .modal-content {
    background-color: white;
    border-radius: 0px;
    color: black;
}
.payment-schedule-modal .modal-content .modal-body {
    max-height: 75vh;
    overflow-y: auto;
    overflow-x: hidden;
}
.payment-schedule-modal .modal-content .modal-body::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}
.payment-schedule-modal .modal-content .modal-body::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3);
}
.payment-schedule-modal .modal-content .modal-body::-webkit-scrollbar-thumb {
    background-color: #303e67;
    outline: 1px solid #303e67;
}
.payment-schedule-modal .modal-content small,
.payment-schedule-modal .modal-content label {
    color: rgb(73, 73, 73);
    text-transform: uppercase;
    letter-spacing: 0.75px;
}
.payment-schedule-modal .modal-content .modal-header {
    -webkit-justify-content: center;
            justify-content: center;
}
.payment-schedule-modal .modal-content .modal-header .modal-title {
    font-weight: 400;
    font-size: 1.75em;
    text-transform: uppercase;
}
.merge-driver-modal.modal-dialog {
    max-width: 30% !important;
}
.merge-driver-modal .modal-content {
    background-color: white;
    border-radius: 0px;
}

.merge-driver-modal .modal-content .modal-body {
    max-height: 75vh;
    overflow-y: auto;
    overflow-x: hidden;
}
.merge-driver-modal .modal-content small,
.merge-driver-modal .modal-content label {
    color: rgb(73, 73, 73);
    letter-spacing: 0.75px;
}

.merge-driver-modal .modal-content .modal-header {
    -webkit-justify-content: center;
            justify-content: center;
}

.merge-driver-modal .modal-content .modal-header .modal-title {
    font-weight: 400;
    font-size: 1.75em;
    text-transform: uppercase;
}

.merge-driver-modal-primary-btn {
    background-color: #303e67;
    border-radius: 7px;
    font-size: 13px;
    font-weight: 400;
    color: white;
    cursor: pointer;
}
.merge-driver-modal-secondary-btn {
    background-color: white;
    border-radius: 7px;
    font-size: 13px;
    font-weight: 400;
    color: black;
    cursor: pointer;
}
.filter-container {
    background-color: white;
    border-radius: 10px;
    width: 100%;
}

.filter-container .date-picker {
    width: 100%;
    color: #303e67 !important;
}

.filters {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    padding: 1.5rem;
}

.display-filter {
    color: #303E67 !important;
    text-decoration: underline !important;
    text-transform: uppercase;
}

.display-filter:hover {
    cursor: pointer
}


.waveform-container {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    height: 60px;
    width: 100%;
    background: transparent;
}

.waveform-container .play-button {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    width: 40px;
    height: 30px;
    background: #06c;
    border-radius: 50%;
    border: none;
    outline: none;
    cursor: pointer;
    padding-bottom: 3px;
}

.waveform-container .play-button i {
    color: #fff;
}

.waveform-container .wave {
    width: 100%;
    height: 60px;
}

.attachment-viewer-modal {
    max-width: 80%;
}
.attachment-viewer-modal .modal-body {
    text-align: center;
}










.bos-interaction-wrapper {
    /* font-family: "Poppins"; */
    font-size: .8em;
    max-height: 45rem;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 0rem 1.25rem 0rem 1.25rem;
    font-size: 12px;
}
.bos-interaction-wrapper::-webkit-scrollbar {
    width: 10px;
}

.bos-interaction-wrapper::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #f2f3f59c;
}

.bos-interaction-wrapper::-webkit-scrollbar-thumb {
    background-color: #92b1ff;
    margin: 5px;
    outline: 1px solid #92b1ff;
    border-radius: 10px;
}
.bos-interaction-wrapper p {
    margin-bottom: 0.25rem;
    word-wrap: break-word;
    white-space: pre-line
}
.bos-interaction-wrapper a {
    display: block;
    margin-bottom: 0.5rem;
}
.bos-interaction-wrapper label {
    font-weight: 500;
    margin: 0;
}
.bos-interaction-wrapper .inbound-interaction .interaction-type-icon {
    float: left;
}
.bos-interaction-wrapper .outbound-interaction .interaction-type-icon {
    float: right;
}
.bos-interaction-wrapper .interaction-type-icon {
    text-align: center;
    padding: 0.35rem 0.5rem;
    display: inline-block;
    width: 5%;
}
.bos-interaction-wrapper .interaction-type-icon i {
    font-size: 18px;
    cursor: default;
}
.bos-interaction-wrapper .interaction-date {
    background-color: #f3773d3d;
    border: 1px solid #f3773d51;
    color: black;
    margin-bottom: 0.5rem;
    border-radius: 7px;
    text-align: center;
}
.bos-interaction-wrapper .interaction {
    display: flow-root;
}
.bos-interaction-wrapper .interaction-details-wrapper {
    width: 95%;
    display: inline-block;
}
.bos-interaction-wrapper .interaction-details {
    padding: 0.35rem 1rem 0rem 1rem;
    border-radius: 13px;
    min-width: 200px;
}
.bos-interaction-wrapper .interaction-details span {
    display: inline-block;
}
.bos-interaction-wrapper span.interaction-time {
    width: 100%;
    font-size: 10px;
    display: flow-root;
}
.bos-interaction-wrapper span.interaction-time p {
    margin: 0;
}
.bos-interaction-wrapper .outbound-interaction span.interaction-time p {
    float: right;
}
.bos-interaction-wrapper .interaction-details span.interaction-files {
    min-width: 150px;
}
.bos-interaction-wrapper .interaction-details span.interaction-user {
    min-width: 120px;
    font-size: 11px;
}
.bos-interaction-wrapper .interaction-details span.interaction-msg {
    display: block;
    width: 100%;
    max-width: 400px;
    color: black;
}
/* .bos-interaction-wrapper .inbound-interaction .interaction-type-icon{
    color: rgb(0, 255, 0);
}
.bos-interaction-wrapper .outbound-interaction .interaction-type-icon{
    color: #0048ff;
} */
.bos-interaction-wrapper .inbound-interaction .interaction-details {
    background-color: rgb(191 240 191 / 45%);
    float: left;
    border-top-left-radius: 0px;
}
.bos-interaction-wrapper .outbound-interaction .interaction-details {
    background-color: #004aff1f;
    border-top-right-radius: 0px;
    float: right;
}
.bos-interaction-wrapper .office-interaction .interaction-type-icon {
    color: #ceb600;
}
.bos-interaction-wrapper .office-interaction .interaction-details {
    background-color: #eedd5b;
}
.argyle-integration #argyle-logo {
    width: 60px;
    height: 60px;
    margin-right: 10px;
}

.argyle-integration .card-header {
    background-color: rgb(49, 58, 73);
    border-radius: 10px 10px 0 0;
}

.argyle-integration .card {
    border-radius: 10px;
}

.argyle-integration .btn {
    padding: 8px 20px;
    margin-right: 15px;
    font-size: 16px;
    border: none;
}
.lead-preview-modal.modal-dialog {
	min-width: 920px !important;
}
.lead-preview-modal .modal-content {
	background-color: white;
	color: #525f6f;
}
.lead-preview-modal .modal-content .modal-body {
	max-height: 75vh;
	overflow-y: auto;
	overflow-x: hidden;
}
.lead-preview-modal .modal-content .modal-body::-webkit-scrollbar {
	width: 5px;
	height: 5px;
	/* display: none; */
}
.lead-preview-modal .modal-content .modal-body::-webkit-scrollbar-thumb {
	background-color: #303e67;
	outline: 1px solid #303e67;
	/* display: none; */
}

.lead-preview-modal .modal-content .toggle-section-heading i {
	float: right;
	margin-top: 0.25rem;
}
.lead-preview-modal .modal-content .toggle-section-heading {
	display: block;
	color: #525f6f;
	/* color: white; */
	background-color: #D6D6D6;
	/* background-color: #565d60; */
	border-radius: 10px;
	padding: 0.5rem 1rem;
	font-size: 1em;
	margin-bottom: 1rem;
}
.lead-preview-modal .modal-content .toggle-section-heading.active {
	/* background-color: #1A1C1E; */
	background-color: #303e67;
	border-bottom-left-radius: 0px;
	border-bottom-right-radius: 0px;
	color: #ffffff;
}

.lead-preview-modal .modal-content label {
	color: #525f6f;
	text-transform: uppercase;
	letter-spacing: 0.5px;
	font-size: 80%;
	font-weight: 600;
	/* margin-bottom: 0.25rem; */
}
.lead-preview-modal .modal-content p {
	margin-bottom: 0.25rem;

}
.lead-preview-modal.modal-dialog row {
	margin-bottom: 15px !important;
}

.lead-preview-modal .modal-content .modal-header {
	-webkit-justify-content: center;
	        justify-content: center;
	/* background-color: #303e67;
    width: 100% !important;
    border-radius: 5px;
    margin-bottom: 5px; */
}

.lead-preview-modal .modal-content .modal-header .modal-title {
	font-weight: 400;
	font-size: 1.75em;
	text-transform: uppercase;
	/* color: white; */
}
.lead-preview-modal-primary-btn {
	background-color: #303e67;
	border-radius: 7px;
	font-size: 13px;
	font-weight: 400;
	color: white;
	cursor: pointer;
}
.lead-preview-modal-secondary-btn {
	background-color: white;
	border-radius: 7px;
	font-size: 13px;
	font-weight: 400;
	color: black;
	cursor: pointer;
}
.lead-preview-modal-danger-btn {
	background-color: red;
	border-radius: 7px;
	font-size: 13px;
	font-weight: 400;
	color: white;
	cursor: pointer;
	border: #ffffff;
}
.btn-container {
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-align-items: center;
	        align-items: center;
	margin: 20px;
}
.insurance-primary-btn {
	background-color: #303e67;
	width: 50% !important;
	color: #ffffff;
	height: 150px !important;
	font-size: large;
	border-radius: 10px;
	font-weight: 400;
	text-transform: uppercase;
	opacity: 0.7;
	border: none;
}
#lead-preview-modal-popover {
	width: 100%;
	margin-bottom: 10px;
	border: solid 1px #303e67;
}
#lead-preview-modal-popover-header {
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: center;
	        align-items: center;
	-webkit-justify-content: center;
	        justify-content: center;
	text-transform: uppercase;
}
#lead-preview-modal-popover-body {
	font-size: 13px;
}
/* .lead-preview-modal .lead-preview-modal-nav {
	background-color: #ffffff;
} */
/* .lead-preview-modal .lead-preview-modal-nav-item {
	border: solid 0px #303e67;
	background-color: #D6D6D6;
	color: #303e67;
}

.lead-preview-modal .lead-preview-modal-nav-item .active {
	background-color: #303e67;
	border: solid 1px #303e67;
} */
.submitToInsuranceForm .switchButton{
    color: #52606b;
	border-color: #52606b;
    background-color: transparent;
    margin:10px
}
.submitToInsuranceForm .switchButton.active{
    color: #fff;
	border-color: none;
    background-color: #52606b;
    margin:10px
}

.submitToInsuranceForm .multiSelectButton{
    color: #52606b;
	border-color: #52606b;
    background-color: transparent;
    margin:10px
}
.submitToInsuranceForm .multiSelectButton.active{
    color: #fff;
	border-color: none;
    background-color: #52606b;
    margin:10px
}
.submitToInsuranceForm .btn-outline-primary, .submitToInsuranceForm .btn-outline-primary{
	color: #52606b;
	border-color: #52606b;
	background-color: transparent;
}
.submitToInsuranceForm .btn-outline-primary:not(:disabled):not(.disabled):active, .submitToInsuranceForm .btn-outline-primary:not(:disabled):not(.disabled).active, .submitToInsuranceForm .show > .btn-outline-primary.dropdown-toggle{
	color: #fff;
    background-color: #52606b;
    border-color: #52606b;
}

.submitToInsuranceForm .btn-outline-primary:hover,.submitToInsuranceForm .btn-outline-primary:active,.submitToInsuranceForm .btn-outline-primary:focus,
.submitToInsuranceForm .btn-outline-primary:not(:disabled):not(.disabled):active
{
	color: #fff;
    background-color: #52606b;
    border-color: #52606b;
}
.process-insurance-modal-btn-container {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    margin: 20px;
}
.process-insurance-modal-primary-btn {
    background-color: #303e67;
    width: 50% !important;
    color: #ffffff;
    height: 150px !important;
    font-size: large;
    border-radius: 10px;
    font-weight: 400;
    text-transform: uppercase;
    opacity: 0.7;
    border: none;
}
#process-insurance-modal-popover {
    width: 100%;
    margin-bottom: 10px;
    border: solid 1px #303e67;
}
#process-insurance-modal-popover-header {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    text-transform: uppercase;
}
#process-insurance-modal-popover-body {
    font-size: 13px;
}
.process-insurance-modal-primary-btn {
    background-color: #303e67;
    border-radius: 7px;
    font-size: 13px;
    font-weight: 400;
    color: white;
    cursor: pointer;
}
.process-insurance-modal-secondary-btn {
    background-color: white;
    border-radius: 7px;
    font-size: 13px;
    font-weight: 400;
    color: black;
    cursor: pointer;
}
.process-insurance-modal-danger-btn {
    background-color: red;
    border-radius: 7px;
    font-size: 13px;
    font-weight: 400;
    color: white;
    cursor: pointer;
    border: #ffffff;
}
.insurance-detail-section-primary-btn {
	background-color: #303e67 !important;
	border-radius: 7px !important;
	font-size: 13px !important;
	font-weight: 400 !important;
	color: white !important;
	cursor: pointer !important;
}
.insurance-detail-section-secondary-btn {
	background-color: white !important;
	border-radius: 7px !important;
	font-size: 13px !important;
	font-weight: 400 !important;
	color: black !important;
	cursor: pointer !important;
}
.insurance-detail-section-danger-btn {
	background-color: red !important;
	border-radius: 7px !important;
	font-size: 13px !important;
	font-weight: 400 !important;
	color: white !important;
	cursor: pointer !important;
	border: #ffffff !important;
}
.insurance-detail-section-success-btn {
	background-color: #28a745 !important;
	border-radius: 7px !important;
	font-size: 13px !important;
	font-weight: 400 !important;
	color: white !important;
	cursor: pointer !important;
	border: #ffffff !important;
}
.document-box {
    padding: 1.25rem 0.25rem;
    overflow: hidden;
}
.document-box .document-main {
    background-color: #D6D6D6;
    box-shadow: 3px 4px 12px 3px rgba(0, 0, 0, 0.07);
    border-radius: 10px;
    padding: 20px 7px 10px 7px;
    margin: 0rem;
}
.document-box .document-main:hover {
    cursor: pointer;
}

.document-box .document-main .arrow-up-down-button {
    background-color: white;
    padding: 8px 11px;
    border-radius: 25px;
}
.document-box .document-main.active {
    background-color: #2DAFD3;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
}
.document-box .document-popup a {
    cursor: pointer;
    color: #007bff;
}
.document-box .document-popup a:hover {
    color: #007bff;
    text-decoration: underline;
}
.document-box .document-main label {
    opacity: 0.85;
    letter-spacing: 0.75px;
    text-transform: uppercase;
    font-size: 80%;
    font-weight: 500;
    line-height: 18.75px;
    color: #000000;
}
.document-box .document-main .row {
    margin: 0px;
}
.document-box .document-main .row p:nth-child(2) {
    margin-left: auto;
}
.document-box .document-main .fa-file-pdf-o, .fa-picture-o {
    cursor: default;
    margin-left: auto;
}
.document-box .document-main .selected-document {
    color: #fff
}
.document-box .document-main .not-selected-document {
    color: #000;
}
.document-box .document-popup {
    margin: 0;
    background-color: #e0e0e0;
    border-radius: 10px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border: 1px solid #E7E9EC;
    padding: 12px;
}

.document-box .document-popup .document-popup-button-container button{
        background: none!important;
        border: none;
        padding-right: 1.25rem;
        padding-left: 0rem;
        font-family: arial, sans-serif;
        color: #069;
        cursor: pointer;
}
.document-box .document-popup .document-popup-button-container button:hover{
    text-decoration: underline;
}
.document-box .document-popup .document-button .remove-open {
    border-radius: 8px;
    height: 38px;
    text-align: center;
    font-size: 11px;
    font-weight: 700;
    margin-right: 0px;
}
.equipments-tab .toggle-switch-inner:after, .toggle-switch-inner:after {
    content: "";
    text-transform: uppercase;
    padding-right: 10px;
    background-color: #2acd72;
    color: #fff;
    text-align: right;
}
.toggle-switch-switch {
    display: block;
    width: 18px;
    margin: 3px;
    background: #fff;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 25px;
    border: 0 solid #bbb;
    border-radius: 20px;
    transition: all 0.1s ease-in 0s;
}
.toggle-switch-checkbox:checked + .toggle-switch-label .toggle-switch-inner {
    margin-left: 0;
}
.toggle-switch-checkbox:checked + .toggle-switch-label .toggle-switch-switch {
    right: 10px;
}

.documents-tab .upload-document, .equipments-tab .install-equipment {
    border-radius: 12px;
    background-color: #1B4D7E;
    width: 180px;
    height: 45px;
    text-align: center;
    font-size: 13px;
    font-weight: 600;
}
.cdw-signup-modal-primary-btn {
	background-color: #303e67;
	border-radius: 7px;
	font-size: 13px;
	font-weight: 400;
	color: white;
	cursor: pointer;
}
.cdw-signup-modal-secondary-btn {
	background-color: white;
	border-radius: 7px;
	font-size: 13px;
	font-weight: 400;
	color: black;
	cursor: pointer;
}
.cdw-signup-modal-danger-btn {
	background-color: red;
	border-radius: 7px;
	font-size: 13px;
	font-weight: 400;
	color: white;
	cursor: pointer;
	border: #ffffff;
}
.dnr-modal-btn {
    background-color: #0B80F9;
    border: 1px solid #0B80F9;
    border-radius: 7px;
}
.dnr-modal-btn:hover {
    color: #41505d;
    background-color: transparent;
    border: 1px solid #41505d;
}
.dnr-modal .btn {
    border-radius: 7px;
    font-size: 13px;
    font-weight: 400 !important;
}
.dnr-modal .btn.btn-primary {
    background-color: #0B80F9;
}
.dnr-modal .modal-content {
    background-color: white;
    border-radius: 0px;
    color: black;
}
.dnr-modal .modal-content .modal-body {
    max-height: 75vh;
    overflow-y: auto;
    overflow-x: hidden;
}
.dnr-modal .modal-content .modal-body::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}
.dnr-modal .modal-content .modal-body::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3);
}
.dnr-modal .modal-content .modal-body::-webkit-scrollbar-thumb {
    background-color: #303e67;
    outline: 1px solid #303e67;
}
.dnr-modal .modal-content small,
.dnr-modal .modal-content label {
    color: rgb(73, 73, 73);
    text-transform: uppercase;
    letter-spacing: 0.75px;
}
.dnr-modal .modal-content .modal-header {
    -webkit-justify-content: center;
            justify-content: center;
}
.dnr-modal .modal-content .modal-header .modal-title {
    font-weight: 400;
    font-size: 1.75em;
    text-transform: uppercase;
}
.dnr-modal .modal-content .issue-modal-jobs-wrapper label {
    margin-bottom: 0;
}
/* .pricing-list-container .row{
    margin-bottom: 0rem;
} */

.pricing-list-container .card {
    margin-bottom: .5rem;
    box-shadow: 3px 3px 9px 2px hsl(0deg 0% 79% / 21%);
    border: none;
    border-radius: 8px;
}

.prices-table-wrapper {
    /* max-height: 67vh;
	overflow-y: auto; */
}

.prices-table-wrapper::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

.prices-table-wrapper::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3);
}

.prices-table-wrapper::-webkit-scrollbar-thumb {
    background-color: #303e67;
    outline: 1px solid #303e67;
}

.prices-table-wrapper .progress {
    height: 0.5vh;
}

.prices-table-wrapper table tr {
    border-bottom: 1px solid hsla(0, 0%, 79.2%, .21);
    margin: 10px;
    color: rgba(39, 56, 70, .8);
}
.prices-table-wrapper table th {
    border: none;
    text-transform: uppercase;
    color: rgba(42, 53, 84, .52);
    font-size: .8em;
    font-weight: 500;
    vertical-align: middle;
    letter-spacing: .005em;
}

.prices-table-wrapper table td {
    color: #525f7f;
}

.prices-table-wrapper .fa {
    color: #303E67
}






.edit-pricing-modal.modal-dialog {
    min-width: 520px !important;
}
.edit-pricing-modal .modal-content {
    background-color: white !important;
    color: #525f6f !important;
}
.edit-pricing-modal .modal-content .modal-body {
    max-height: 75vh !important;
    overflow-y: auto !important;
    overflow-x: hidden !important;
}
.edit-pricing-modal .modal-content label {
    color: #525f6f !important;
    text-transform: uppercase !important;
    letter-spacing: 0.5px !important;
    font-size: 80% !important;
    font-weight: 700 !important;
}
.edit-pricing-modal .modal-content p {
    margin-bottom: 0.25rem !important;

}
.edit-pricing-modal .modal-content .modal-header {
    -webkit-justify-content: center !important;
            justify-content: center !important;
}

.edit-pricing-modal .modal-content .modal-header .modal-title {
    font-weight: 400 !important;
    font-size: 1.75em !important;
    text-transform: uppercase !important;
    color: #303e67 !important;
}
.edit-pricing-modal-secondary-btn {
    background-color: white !important;
    border-radius: 7px !important;
    font-size: 13px !important;
    font-weight: 400 !important;
    color: black !important;
    cursor: pointer !important;
}

.edit-pricing-modal .modal-content .toggle-section-heading i {
    float: right;
    margin-top: 0.25rem;
}
.edit-pricing-modal .modal-content .toggle-section-heading {
    display: block;
    color: white !important;
    border-radius: 10px;
    padding: 0.5rem 1rem;
    font-size: 1em;
    margin-bottom: 1rem;
}

.edit-pricing-modal .modal-content .toggle-section-heading.active {
    /* background-color: #1A1C1E; */
    background-color: #303e67;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    color: #ffffff;
}
.permission-box {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    border: 1px solid #ccc;
    border-radius: 20px;
    box-shadow: 10px 5px 20px 2px rgba(0, 0, 0, 0.2);
    padding: 20px;
    margin: 50px auto;
    max-width: 500px;
    text-align: center;
    height: 300px;
}

.permission-box-button {
    display: inline-block;
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border-radius: 5px;
    text-decoration: none;
    cursor: pointer;
    margin: 0;
    transition: background-color 0.2s ease;
    text-transform: uppercase;
}

.permission-box-button:hover {
    background-color: #007bff;
    ;
}

.permission-box-title {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 10px;
    text-transform: uppercase;
    color: red;
}

.permission-box-message {
    font-size: 1.2rem;
    margin-bottom: 20px;
}

.permission-box-footer {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
}
.CarIssueModal {
    color: #273846cc;
    font-family: 'Rubik', 'San serif';
}
.CarIssueModal .modal-content {
    background-color: transparent;
}
.CarIssueModal .nav-tabs {
    border-bottom: 1px solid #e2e8ed;
}
.CarIssueModal .nav.nav-tabs .nav-link.active {
    background-color: #344A5C;
    color: #ffffff;
}

.CarIssueModal .modal-header {
    font-weight: 500;
    background-color: #344A5C;
    color: #ffffff;
}
.CarIssueModal .btn-link {
    color: #344A5C;
}
.CarIssueModal .modal-header a {
    color: #fff;
}
.CarIssueModal .modal-header .phone {
    padding-left: 20px;
}
.CarIssueModal .modal-body {
    background-color: #fff;
}
.CarIssueModal .modal-footer {
    background-color: #fff;
}
.CarIssueModal .row {
    border: 1px solid #91a8bb78;
    border-radius: 5px;
    margin: 10px;
}
.CarIssueModal .card-title {
    margin-bottom: 0.2rem;
    font-size: .75em;
    opacity: .6;
}
.CarIssueModal .danger.row {
    border: 2px solid #ff2f2fab;
}
.CarIssueModal .danger .card-title {
    color: #273846cc;
    opacity: .8;
}
.CarIssueModal .card-body {
    padding: 0.9rem;
}
.CarIssueModal .card {
    border: none;
}
.CarIssueModal a, .CarIssueModal a:hover, .CarIssueModal a:link, .CarIssueModal a:visited {
    text-decoration: none;
}
.create-issue-btn {
    background-color: #0B80F9;
    border: 1px solid #0B80F9;
    border-radius: 7px;
}
.create-issue-btn:hover {
    color: #41505d;
    background-color: transparent;
    border: 1px solid #41505d;
}
.car-issue-modal .btn {
    border-radius: 7px;
    font-size: 13px;
    font-weight: 400 !important;
}
.car-issue-modal .btn.btn-primary {
    background-color: #0B80F9;
}
.car-issue-modal .modal-content {
    background-color: white;
    border-radius: 0px;
    color: black;
}
.car-issue-modal .modal-content .modal-body {
    max-height: 75vh;
    overflow-y: auto;
    overflow-x: hidden;
}
.car-issue-modal .modal-content .modal-body::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}
.car-issue-modal .modal-content .modal-body::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3);
}
.car-issue-modal .modal-content .modal-body::-webkit-scrollbar-thumb {
    background-color: #303e67;
    outline: 1px solid #303e67;
}
.car-issue-modal .modal-content small,
.car-issue-modal .modal-content label {
    color: rgb(73, 73, 73);
    text-transform: uppercase;
    letter-spacing: 0.75px;
}
.car-issue-modal .modal-content .modal-header {
    -webkit-justify-content: center;
            justify-content: center;
}
.car-issue-modal .modal-content .modal-header .modal-title {
    font-weight: 400;
    font-size: 1.75em;
    text-transform: uppercase;
}
.car-issue-modal .modal-content .issue-modal-jobs-wrapper .card {
    margin: 7px 0px;
}
.car-issue-modal .modal-content .issue-modal-jobs-wrapper .card-body {
    padding: 7px 15px;
    background-color: #0000000e;
}
.car-issue-modal .modal-content .issue-modal-jobs-wrapper .card-body .row {
    padding-top: 0.45rem;
}
.car-issue-modal .modal-content .issue-modal-jobs-wrapper label {
    margin-bottom: 0;
}
.car-issue-modal .modal-content .issue-modal-collapse-btn {
    width: 100%;
    border-radius: 0px;
    text-transform: uppercase;
    background-color: #0000008c;
    border: none;
}
.car-issue-modal .issue-extra-info-wrapper {
    margin-top: 1.5rem;
    padding-top: 1.25rem;
    border-top: 1px solid #dee2e6;
}
.work-order {
    background-color: #0B80F9;
    border: 1px solid #0B80F9;
    border-radius: 7px;
    margin-left: 15px;
    margin-right: 15px;
    text-align: right;
}

.work-order:hover {
    color: #41505d;
    background-color: transparent;
    border: 1px solid #41505d;
}
.work-order:focus {
    color: #41505d;
    background-color: transparent;
    border: 1px solid #41505d;
}
.WorkOrderModal {
    width: 100%;
    width: 800px !important;
}
.WorkOrderModal .modal-content small,
.WorkOrderModal .modal-content label {
    color: rgb(73, 73, 73);
    text-transform: uppercase;
    letter-spacing: 0.75px;
}
.WorkOrderModal .modal-content .modal-header {
    -webkit-justify-content: center;
            justify-content: center;
}
.WorkOrderModal .modal-content .modal-header .modal-title {
    font-weight: 400;
    font-size: 1.75em;
    text-transform: uppercase;
}
.WorkOrderModal .modal-body {
    background-color: #fff;
}
.WorkOrderModal .modal-footer {
    background-color: #fff;
}
.WorkOrderModal .modal-content .modal-body .small-preview {
    max-height: 100%;
    max-width: 100%;
}
.WorkOrderModal .edit-item {
    position: relative;
}
.WorkOrderModal .edit-item .edit-icon {
    position: absolute;
    right: 10px;
    top: 10px;
}
.WorkOrderModal .edit-item .edit-icon {
    position: relative;
    position: absolute;
    right: 10px;
    top: 10px;
}

.work-order-item {
    margin-bottom: 2rem;
}
.add-remove-work-order:last-child {
    margin-left: 1rem;
    -webkit-align-self: center;
            align-self: center;
}
.add-remove-work-order {
    -webkit-align-self: center;
            align-self: center;
}
.bos-listing-container,
.bos-listing-container tr td {
    font-size: 12px !important;
    font-family: "Poppins";
}

.bos-listing-container .card{
    box-shadow: 3px 3px 9px 2px hsl(0deg 0% 79% / 21%);
    border: none;
    border-radius: 8px;
    margin-bottom: 0.5rem;
}

.bos-listing-container .table th {
    text-transform: uppercase;
    color: rgba(42,53,84,.52);
    font-size: .8em;
    font-weight: 500;
    /* vertical-align: middle; */
    letter-spacing: .005em;
}

.bos-listing-container .table th, .table td {
    border: none;
    padding-bottom: 10px;
    padding-top: 10px;
}
.bos-listing-container .table td {
    font-size: 12px !important;
}

.bos-listing-container .table tr {
    border-bottom: 1px solid hsla(0,0%,79.2%,.21);
    margin: 10px;
    color: rgba(39,56,70,.8);
}

.bos-listing-container .table .bos-table-td-left-box i{
    font-size: 1.25rem; 
    margin-top: 1.25rem;
}
.bos-listing-container .table .bos-table-td-left-box{
    text-align: center;
    float: left;
    padding-right: 10px;
}
.bos-listing-container .table .bos-table-td-right-box{
    float: left;
    text-align: left;
    padding-left: 10px;
    border-left: 1px solid #e1e1e1;
}
.bos-listing-container .table .bos-table-td-right-box-actions-wrapper{
    float: right;
    padding-top: 12px;
    padding-right: 1.5rem;
}

.bos-listing-container .table .bos-table-td-right-box-actions-wrapper ul{
    padding: 0;
    margin: 0;
}
.bos-listing-container .table .bos-table-td-right-box-actions li:not(:first-child){
    border-left: 1px solid #e1e1e1;
    padding-left: 10px;
}
.bos-listing-container .table .bos-table-td-right-box-actions li {
    display: inline-block;
    padding-right: 10px;
}
/* .bos-listing-container .table .bos-table-td-right-box-actions li .fa{
    font-size: 18px;
} */

.bos-listing-container,
.bos-listing-container tr td {
    font-size: 12px !important;
    font-family: "Poppins";
}

.bos-listing-container .card{
    box-shadow: 3px 3px 9px 2px hsl(0deg 0% 79% / 21%);
    border: none;
    border-radius: 8px;
    margin-bottom: 0.5rem;
}

.bos-listing-container .table th {
    text-transform: uppercase;
    color: rgba(42,53,84,.52);
    font-size: .8em;
    font-weight: 500;
    /* vertical-align: middle; */
    letter-spacing: .005em;
}

.bos-listing-container .table th, .table td {
    border: none;
    padding-bottom: 10px;
    padding-top: 10px;
}
.bos-listing-container .table td {
    font-size: 12px !important;
}

.bos-listing-container .table tr {
    border-bottom: 1px solid hsla(0,0%,79.2%,.21);
    margin: 10px;
    color: rgba(39,56,70,.8);
}

.bos-listing-container .table .bos-table-td-left-box i{
    font-size: 1.25rem; 
    margin-top: 1.25rem;
}
.bos-listing-container .table .bos-table-td-left-box{
    text-align: center;
    float: left;
    padding-right: 10px;
}
.bos-listing-container .table .bos-table-td-right-box{
    float: left;
    text-align: left;
    padding-left: 10px;
    border-left: 1px solid #e1e1e1;
}
.bos-listing-container .table .bos-table-td-right-box-actions-wrapper{
    float: right;
    padding-top: 12px;
    padding-right: 1.5rem;
}

.bos-listing-container .table .bos-table-td-right-box-actions-wrapper ul{
    padding: 0;
    margin: 0;
}
.bos-listing-container .table .bos-table-td-right-box-actions li:not(:first-child){
    border-left: 1px solid #e1e1e1;
    padding-left: 10px;
}
.bos-listing-container .table .bos-table-td-right-box-actions li {
    display: inline-block;
    padding-right: 10px;
}
/* .bos-listing-container .table .bos-table-td-right-box-actions li .fa{
    font-size: 18px;
} */
.bos-listing-container .table a {
	color: #007bff;
	text-decoration: none;
	background-color: transparent;
}
.bos-listing-container .table a:hover {
	color: #0056b3;
	text-decoration: underline;
}
.bos-create-branch-modal {
	min-width: 60%;
}
.members-options-wrapper {
	max-height: 20vh;
	overflow-y: scroll;
}
.members-options-wrapper::-webkit-scrollbar {
	width: 5px;
	height: 5px;
}
.members-options-wrapper::-webkit-scrollbar-track {
	box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3);
}

.members-options-wrapper::-webkit-scrollbar-thumb {
	background-color: #303e67;
	outline: 1px solid #303e67;
}

.new-branch-modal-primary-btn {
	background-color: #303e67;
	border-radius: 7px;
	font-size: 13px;
	font-weight: 400;
	color: white;
	cursor: pointer;
}
.new-branch-modal-secondary-btn {
	background-color: white;
	border-radius: 7px;
	font-size: 13px;
	font-weight: 400;
	color: black;
	cursor: pointer;
}
.new-branch-modal-danger-btn {
	background-color: red;
	border-radius: 7px;
	font-size: 13px;
	font-weight: 400;
	color: white;
	cursor: pointer;
	border: #ffffff;
}
.missing-required-settings-tooltip {
	opacity: 1 !important;
}
.missing-required-settings-tooltip .tooltip-inner {
	min-width: 175px;
	max-width: 300px;
	padding: 0.25rem 1rem 0.5rem 1rem;
	width: auto;
	text-align: left;
	background-color: #F2F2F2;
	color: black;
	border: 1px solid #d7dce2;
	max-height: 200px;
	overflow: auto;
}
.missing-required-settings-tooltip label {
	display: block;
	text-align: center;
	font-size: 1em;
	text-transform: uppercase;
	font-weight: bold;
}
.missing-required-settings-tooltip ul {
	padding-left: 1rem;
	padding-bottom: 0px;
}
.bos-object-detail-container {
    font-family: "Poppins", "Rubik", "San serif" !important;
    color: #000;
    font-size: 12px;
    background-color: #f2f3f6;
    min-height: 50vh;
}
.bos-object-detail-container .bos-object-detail-header {
    background-color: #e0e0e0;
    padding: 5rem 1rem 0rem 1rem;
}
.bos-object-detail-container .bos-object-detail-header .box-object-icon {
    background-color: #c6cbde;
    border: 7px solid #f2f3f6;
    padding: 14px 16px;
    border-radius: 50%;
    position: relative;
    bottom: -15px;
}
.bos-object-detail-container .bos-object-detail-header .bos-object-title-wrapper label {
    font-size: 24px;
    font-weight: 500;
    /* margin-bottom: 0px !important; */
}
.bos-object-detail-container .bos-object-detail-header .bos-object-property-wrapper label {
    margin-bottom: 12px !important;
}
.bos-object-detail-container .bos-object-detail-header .bos-object-property-wrapper {
    -webkit-align-self: flex-end;
            align-self: flex-end;
}
.bos-object-detail-container .bos-object-detail-body {
    padding: 35px 0px 20px 0px;
}

.bos-object-detail-container .bos-object-detail-body .nav-link {
    font-weight: 500;
    border-top-left-radius: 10px !important;
    border-top-right-radius: 10px !important;
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    background-color: #c6cbde;
    text-transform: uppercase;
    padding: 0.5rem 2.25rem !important;
}

.bos-object-detail-container .bos-object-detail-body .nav-link.active {
    background-color: #fff;
    color: #000;
    border: 1px solid #dadada;
}

.bos-object-detail-container .bos-object-detail-body .bos-object-nav-content {
    width: 100%;
    background-color: #fff;
    /* max-height: 50vh; */
    max-height: 80vh;
    overflow-y: auto;
}
.bos-object-detail-container .bos-object-detail-body .bos-object-nav-content .container-fluid {
    padding: 35px 45px;
}
.bos-object-detail-container .bos-object-detail-body .bos-object-nav-content .box-object-property-input.form-control[multiple] {
    min-height: 225px;
}
.bos-object-detail-container .bos-object-detail-body .bos-object-nav-content .box-object-property-input:disabled {
    background-color: #fff;
}
.bos-object-detail-container .bos-object-detail-body .bos-object-nav-content .bos-object-section-wrapper .bos-object-section {
    background-color: #f0f0eb;
    padding: 1rem 1rem 2rem 1rem;
    border-radius: 10px;
    box-shadow: 0 13px 10px 1px rgb(0 0 0 / 25%);
    -webkit-box-shadow: 0 13px 10px 1px rgb(0 0 0 / 25%);
    -moz-box-shadow: 0 13px 10px 1px rgba(0, 0, 0, .25);
    margin: 0px 15px 25px 0px;
}

.bos-object-detail-container .bos-object-detail-body .bos-object-nav-content .bos-object-section-wrapper label:not(.toggle-switch-label) {
    opacity: .65;
    display: block;
    letter-spacing: .75px;
    margin-top: 1rem;
    margin-bottom: 0px;
    text-transform: uppercase;
    font-size: 80%;
    font-weight: 500;
}
.bos-object-detail-container .bos-object-detail-body .bos-object-nav-content .bos-object-section-wrapper label:not(.toggle-switch-label) i {
    float: right;
    margin-right: 0.25rem;
}
.bos-object-detail-container .bos-object-detail-body .bos-object-nav-content .bos-object-section-wrapper .table a {
    color: #007bff;
    text-decoration: none;
    background-color: transparent;
}
.bos-object-detail-container .bos-object-detail-body .bos-object-nav-content .bos-object-section-wrapper .table a:hover {
    color: #0056b3;
    text-decoration: underline;
}
.bos-object-detail-container .bos-object-detail-body .bos-object-nav-content .bos-object-section-wrapper .table {
    margin: 2rem 0rem;
}
.bos-object-detail-container .bos-object-detail-body .bos-object-nav-content .bos-object-section-wrapper .table tr {
    border-bottom: 1px solid hsla(0, 0%, 79.2%, .21);
    margin: 10px;
    color: rgba(39, 56, 70, .8);
}
.bos-object-detail-container .bos-object-detail-body .bos-object-nav-content .bos-object-section-wrapper .table thead th {
    border: none;
    opacity: 0.5;
    text-transform: uppercase;
    font-size: 80%;
}
.bos-object-detail-container .bos-object-detail-body .bos-object-nav-content .bos-object-section-wrapper .table td {
    font-size: 12px !important;
    max-width: 700px;
}
.bos-object-detail-container .bos-listing-container .table th {
    color: black;
}
.arrow-btn-container {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
}
.arrow-btn {
    background-color: #303e67;
    color: white;
}
.overview-primary-btn {
    background-color: #303e67;
    color: white;
    border-radius: 5px;
}
.bos-attributes-listing-table-wrapper{
    /* max-height: 65vh;
    overflow-y: auto; */
    overflow-x: hidden;
}
.bos-attributes-listing-table-wrapper table thead th{
    color: black !important;
}
.bos-attributes-listing-table-wrapper::-webkit-scrollbar{
	width: 5px;
	height: 5px;
}
.bos-attributes-listing-table-wrapper::-webkit-scrollbar-track{
	box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3);
}
   
.bos-attributes-listing-table-wrapper::-webkit-scrollbar-thumb{
	background-color: #303e67;
	outline: 1px solid #303e67;
}
.car-registration-container h4 {
	font-weight: 500;
	font-size: 1.1em;
	margin: 0;
}
.car-registration-container .car-registration-filters-container .form-group {
	margin-bottom: 0;
}

.car-registration-container .car-registration-filters-container .searchterm-wrapper {
	position: relative;
}

.car-registration-container .car-registration-filters-container .searchterm-wrapper .btn {
	position: absolute;
	right: 2%;
	top: 0;
	background-color: #303e67;
	z-index: 111;
}

.car-registration-container .car-registration-table-container {
	max-height: 65vh;
	overflow-y: auto;
}
.car-registration-container .car-registration-table-container thead th {
	border: none !important;
}
.car-registration-container .car-registration-table-container tr {
	border-bottom: 1px solid hsla(0, 0%, 79.2%, .21);
}
.car-registration-container .car-registration-table-container th {
	text-transform: uppercase;
	color: rgba(42, 53, 84, .52);
	font-size: .8em;
	font-weight: 500;
	vertical-align: middle;
	letter-spacing: .005em;
}
.car-registration-container .car-registration-table-container .car-details-wrapper,
.car-registration-container .car-registration-table-container .registration-details-wrapper {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: row;
	        flex-direction: row;
	-webkit-align-items: center;
	        align-items: center;
}
.car-registration-container .car-registration-table-container .car-details-wrapper .left-box,
.car-registration-container .car-registration-table-container .registration-details-wrapper .left-box {
	padding-right: 1rem;
	text-align: center;
	border-right: 1px solid #e1e1e1;
}
.car-registration-container .car-registration-table-container .car-details-wrapper .right-box,
.car-registration-container .car-registration-table-container .registration-details-wrapper .right-box {
	padding-left: 1rem;
}
.car-registration-container .car-registration-table-container .right-actions {
	display: -webkit-flex;
	display: flex;
	-webkit-flex: 1 1;
	        flex: 1 1;
	-webkit-justify-content: flex-end;
	        justify-content: flex-end;
	-webkit-align-self: flex-end;
	        align-self: flex-end;
}
.car-registration-container .car-registration-table-container .right-actions ul {
	padding-right: 3rem;
	padding-left: 2px;
}
.car-registration-container .car-registration-table-container .right-actions ul li:not(:first-child) {
	border-left: 1px solid #e1e1e1;
	padding-left: 10px;
}
.car-registration-container .car-registration-table-container .right-actions ul li {
	display: inline-block;
	margin-right: 10px;
}
.car-registration-container .car-registration-table-container .right-actions ul li a {
	cursor: pointer;
}
.car-registration-container .car-registration-table-container .car-details-section {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: row;
	        flex-direction: row;
	-webkit-align-items: center;
	        align-items: center;
}
.car-registration-container .car-registration-table-container .car-details-section .left-box__ {
	padding-right: 1rem;
	text-align: center;
}

.bos-registration-modal .modal-content small,
.bos-registration-modal .modal-content label {
	color: rgb(73, 73, 73);
	text-transform: uppercase;
	letter-spacing: 0.75px;
}

.registration-activity-modal {
	min-width: 850px;
	font-size: 13px;
}
.registration-activity-modal .modal-header {
	-webkit-align-self: center;
	        align-self: center;
}
.registration-activity-modal .modal-title {
	text-transform: uppercase;
}
.registration-activity-modal table thead th {
	border: none;
	text-transform: uppercase;
	font-size: 11px;
	opacity: 0.6;
	padding-bottom: 0;
}
.registration-activity-modal table thead,
.registration-activity-modal table tr {
	border-bottom: 1px solid rgba(202, 202, 202, 0.606);
}
.registration-activity-modal table tr td {
	padding-top: 1rem;
	padding-bottom: 1rem;
}
.car-registration-container .registration-tabs.nav-pills .nav-item .btn.btn-primary {
	padding: 9px 25px;
	font-size: 16px;
	color: #303e67;
	background-color: #c6ccde;
	border: none;
	border-radius: 8px;
	margin-top: -10px;
}
.car-registration-container .registration-tabs.nav-pills .nav-item .btn.btn-primary:focus {
	box-shadow: none;
}
.car-registration-container .registration-tabs.nav-pills .nav-item .btn.btn-primary.active {
	background: #fff;
}
.car-registration-container .registration-tabs.nav-pills .nav-item {
	margin: 0.5rem 1.75rem 2rem 1.75rem;
	margin-left: 0;
}
.car-detail {
    color: #525f7f;
    font-family: 'Poppins', 'Rubik', 'San serif' !important;
    font-size: 13px;
}
.car-detail .badge {
    font-size: 14px;
    padding: 6px 10px;
    border-radius: 6px;
    font-weight: 400;
}
.car-detail .badge.black {
    background-color: #263456;
    color: #FFF;
}
.car-detail .badge.success {
    background-color: #02BD7D;
    color: #FFF;
}
.car-detail-container .badge.danger {
    background-color: #FF0000;
    color: #FFF;
}
.car-detail-container .badge.warning {
    background-color: #FFA500;
    color: #FFF;
}
.car-document-box {
    padding: 1.25rem 0.25rem;
}
.car-document-box .document-main {
    background-color: #D6D6D6;
    box-shadow: 3px 4px 12px 3px rgba(0, 0, 0, 0.07);
    border-radius: 10px;
    padding: 20px 7px 10px 7px;
    margin: 0rem;
}
.car-document-box .document-main:hover {
    cursor: pointer;
}
.car-document-box .document-main.active {
    background-color: #2DAFD3;
}
.car-document-box .document-popup a {
    cursor: pointer;
    color: #007bff;
}
.car-document-box .document-popup a:hover {
    color: #007bff;
    text-decoration: underline;
}
.car-document-box .document-main label {
    opacity: 0.85;
    letter-spacing: 0.75px;
    text-transform: uppercase;
    font-size: 80%;
    font-weight: 500;
    line-height: 18.75px;
    color: #000000;
}
.car-document-box .document-main .row {
    margin: 0px;
}
.car-document-box .document-main .row p:nth-child(2) {
    margin-left: auto;
}
.car-document-box .document-main .fa-file-pdf-o, .fa-picture-o {
    cursor: default;
    margin-left: auto;
}
.car-document-box .document-main .selected-document {
    color: #fff
}
.car-document-box .document-main .not-selected-document {
    color: #000;
}
.documents-container {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}
.documents-tab {
    width: 100%;
    max-height: 800px;
    background-color: #FFFFFF;
    padding: 2.5rem 2.8rem;
    margin-bottom: 2rem;
    overflow-x: hidden;
    overflow-y: auto;
}
.documents-tab::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}
.documents-tab::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px rgb(0, 0, 0, 0.3);
}
.documents-tab::-webkit-scrollbar-thumb {
    background-color: #303e67;
    outline: 1px solid #303e67;
}
.agreement-detail .detailSection {
    padding: 10px 20px;
}
.pricing-tab-container, .equipments-tab {
    width: 100%;
    max-height: 55rem;
    background-color: #FFFFFF;
    padding: 2rem;
    margin-bottom: 2rem;
    overflow-x: hidden;
    overflow-y: auto;
}
.insurance-tab-container table {
    width: 100%;
}
.pricing-tab-container table thead, .equipments-tab table thead, .insurance-tab-container table thead {
    background-color: #F0F0EB;
    color: #000000;
}
.pricing-tab-container table th, .equipments-tab table th, .insurance-tab-container table th {
    border: none;
    padding-bottom: 10px;
    padding-top: 10px;
    padding-left: 15px;
    font-size: 15px;
    font-weight: 500;
}
.pricing-tab-container table tr, .equipments-tab table tr, .insurance-tab-container table tr {
    border-bottom: 1px solid hsla(0, 0%, 79.2%, .21);
    margin: 10px;
}
.pricing-tab-container::-webkit-scrollbar, .equipments-tab::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}
.pricing-tab-container::-webkit-scrollbar-track, .equipments-tab::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px rgb(0, 0, 0, 0.3);
}
.pricing-tab-container::-webkit-scrollbar-thumb, .equipments-tab::-webkit-scrollbar-thumb {
    background-color: #303e67;
    outline: 1px solid #303e67;
}
.cardRow {
    padding: 20px;
}
.car-detail .danger {
    color: #FF5661
}
.car-detail .success {
    color: #02BD7D
}
.car-detail .black {
    color: #263456
}
.car-detail a.ghost, .car-detail .btn.ghost {
    background-color: transparent;
    color: #525f7f;
    border: 1px solid #525f7f;
    border-radius: 4px;
    padding: 4px 8px;
    text-decoration: none;
}
.car-detail .btn.primary {
    background-color: #525f7f;
    color: #FFF;
    border-color: transparent;
}
.car-detail .btn.success {
    background-color: #02BD7D;
    color: #FFF;
    border-color: transparent;
}
.car-detail-container .info-container {
    background-color: #F3F4F6;
    /* background-color: #F0F0EB; */
    /* border: 1px solid #D6D6D6; */
    padding: 2rem;
    border-radius: 10px;
    box-shadow: 0px 5px 5px -1px rgb(0 0 0 / 14%);
    -webkit-box-shadow: 0px 5px 5px -1px rgb(0 0 0 / 14%);
    -moz-box-shadow: 0px 5px 5px -1px rgb(0 0 0 / 14%);
}
.car-detail-container .info-container:nth-child(1) {
    margin-bottom: 2rem;
}
.car-detail-container .enable-disable {
    max-height: 25vh;
    overflow: auto;
    border-top-right-radius: 0px;
    border-top-left-radius: 0px;
}
.tab-container .car-rental-container {
    background-color: #F0F0EB;
    border: 1px solid #D6D6D6;
    padding: 2rem;
    /* box-shadow: 3px 4px 12px 3px rgba(0, 0, 0, 0.07); */
    box-shadow: 0px 13px 10px 1px rgba(0, 0, 0, 0.25);
    -webkit-box-shadow: 0px 13px 10px 1px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0px 13px 10px 1px rgba(0, 0, 0, 0.25);
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    margin-bottom: 2rem;
}
.car-header {
    font-family: 'Poppins';
    display: -webkit-flex;
    display: flex;
    background-color: #E0E0E0;
    color: black;
    margin-bottom: 54px;
    padding: 0px 50px 10px 50px;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
    position: relative;
}
.car-header .btn.ghost:hover, .car-header .btn.ghost:focus, .car-header .btn.ghost:active {
    background-color: #263456;
    color: #FFF;
    border-color: transparent;
}
.car-header .btn.ghost {
    background-color: transparent;
    color: #525f7f;
    border: 1px solid #525f7f;
    border-radius: 4px;
    padding: 4px 8px;
    text-decoration: none;
}
.car-header div {
    -webkit-align-self: flex-end;
            align-self: flex-end;
}
.car-header .car-image .fa {
    border: 7px solid #f2f3f6;
    padding: 17px;
    border-radius: 50%;
    position: relative;
    bottom: -47px;
    background-color: #C6CBDE;
}
.car-header .status-stage .badge {
    font-size: 0.65em;
    padding: 6px 10px;
    border-radius: 6px;
    font-weight: 400;
}
.car-header .status-stage .badge.black {
    background-color: #263456;
    color: #FFF;
}
.car-header .status-stage .badge.success {
    background-color: #02BD7D;
    color: #FFF;
}
.car-header .car-id-wrapper {
    background-color: #2DAFD3;
    color: white;
    top: 15%;
    left: 0%;
    padding: 0.25rem 0.75rem 0.25rem 1.5rem;
    border-top-right-radius: 7px;
    border-bottom-right-radius: 7px;
    position: absolute;
}
.car-header .badge.success {
    background-color: #02BD7D;
    color: #FFF;
}
.car-header .status-stage .badge.danger {
    background-color: #FF5661;
    color: #FFF;
}
.car-header .badge.black {
    background-color: #263456;
    color: #FFF;
}
.car-header .status-stage {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
}
.car-header .header-detail {
    font-size: 14px;
    margin-bottom: 0;
}
.car-header .reserve-car {
    cursor: pointer;
}
.car-header .car-name small,
.car-header .car-name {
    margin-bottom: 0;
    font-size: 14px !important;
}
.car-header .car-name label {
    font-size: 24px !important;
    font-weight: 500;
    margin-bottom: 0;
}
.zero-padding .agreement-detail .car-rental-header {
    background-color: #2DAFD3;
    text-align: left;
    height: 45px;
    color: white;
    padding: 0.8rem;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: space-between;
            justify-content: space-between
}
.zero-padding .agreement-detail .rental-pricing {
    background-color: #F0F0EB;
    border: 1px solid #D6D6D6;
    box-shadow: 0px 13px 10px 1px rgba(0, 0, 0, 0.25);
    -webkit-box-shadow: 0px 13px 10px 1px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0px 13px 10px 1px rgba(0, 0, 0, 0.25);
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    max-height: 180px;
    min-height: 180px;
    overflow-x: hidden;
    overflow-y: auto;
}
.zero-padding .agreement-detail .rental-pricing::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}
.zero-padding .agreement-detail .rental-pricing::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px rgb(0, 0, 0, 0.3);
}
.zero-padding .agreement-detail .rental-pricing::-webkit-scrollbar-thumb {
    background-color: #303e67;
    outline: 1px solid #303e67;
}
.zero-padding .agreement-detail .rental-pricing .row {
    margin-bottom: 0.8rem;
}
.zero-padding .agreement-detail > .row {
    margin-bottom: 1.5rem
}
.zero-padding .rental-pricing .activePrice {
    background-color: #008000;
    color: #ffffff;
}
.car-detail-container .tab-container {
    width: 100%;
    background-color: #FFFFFF;
    padding: 2.5rem;
    border: 1px solid #DADADA;
    min-height: 150px;
}
.car-detail-container .tab-container .car-rental-header {
    background-color: #2DAFD3;
    color: white;
    font-size: 16px !important;
    font-weight: 500;
    text-transform: uppercase;
    padding: 0.5rem 1.5rem;
}
.car-detail-container .tab-container .info-container button {
    background-color: #263456;
    border-radius: 6px;
    padding: 6px 10px;
}
.car-detail-container .tab-container .info-container button:hover {
    background-color: #f2f3f6;
    color: #000000
}
.car-detail-container .tab-container .car-rental-container .car-rental {
    background-color: #FFFFFF;
    padding: 1.2rem 2.5rem;
    border-radius: 10px;
}
.car-detail-container .tab-container .info-container .data-wrapper {
    position: relative;
}
.car-detail-container .tab-container .info-container .data-wrapper .edit-value {
    position: absolute;
    top: 10px;
    right: 10px;
}
.car-detail-container .tab-container .info-container .data-wrapper .edit-actions-wrapper {
    position: absolute;
    top: 10px;
    right: 10px;
}
.car-detail-container .tab-container .info-container .tracker-actions {
    margin: 0px;
    padding: 0px;
}
.car-detail-container .tab-container .tracker-wrapper {
    margin: 0rem 0rem;
}
.tab-container .info-container .tracker-actions li {
    display: inline-block;
    padding-right: 10px;
    text-align: center;
    text-transform: uppercase;
}
.car-detail .nav-item {
    font-size: 14px;
    font-weight: 400;
    text-transform: uppercase;
    font-family: "Poppins";
    color: #000000;
    text-align: center;
    margin-right: 0.75rem;
    border: 1px solid #DADADA;
    border-top-left-radius: 10px !important;
    border-top-right-radius: 10px !important;
    background-color: #F0F0EB;
}
.car-detail .nav-tabs {
    border: none;
}
.car-detail .nav-tabs .nav-item .nav-link {
    padding: 0.5rem 2.25rem !important;
}
.car-detail .nav-item:nth-child(n+2):after {
    margin-left: 2rem;
}
.rental-container, .inspection-container {
    width: 100%;
    min-height: 22vh;
    max-height: 75vh;
    background-color: #FFFFFF;
    padding: 1rem 2rem 0rem;
    overflow-x: hidden;
    overflow-y: auto;
}
.rental-container h5, .inspection-container h5 {
    color: #000000;
}
.rental-container .rental-overview {
    margin-bottom: 1rem
}
.rental-container .agreements-list, .inspection-container .inspections-list {
    overflow: auto;
    /* height: 70vh; */
    padding: 0px;
}
.rental-container .agreements-list .more-agreements, .inspection-container .inspections-list, .more-inspections {
    position: relative;
    /* margin-top: 10px; */
    cursor: pointer;
}
.rental-container .agreements-list a {
    position: absolute;
    right: 0;
}
.rental-container .agreements-list a:hover, .rental-container .agreements-list a:active, .rental-container .agreements-list a:focus {
    opacity: 1;
    font-weight: 500;
}
.rental-container .agreements-list::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}
.rental-container .agreements-list::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px rgb(0, 0, 0, 0.3);
}
.rental-container .agreements-list::-webkit-scrollbar-thumb {
    background-color: #303e67;
    outline: 1px solid #303e67;
}
.rental-container .agreement-detail, .inspection-container .inspection-detail {
    border: 1px solid #D6D6D6;
    border-left: none;
    padding: 2.5rem 5rem;
    overflow: auto;
    min-height: 65vh;
    max-height: 70vh;
}
.inspection-container .inspection-detail table {
    width: 100%;
}
.inspection-container .inspection-detail table th, table td {
    border: none;
}
.rental-container .agreement-detail::-webkit-scrollbar, .inspection-container .inspection-detail::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}
.rental-container .agreement-detail::-webkit-scrollbar-track, .inspection-container .inspection-detail::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px rgb(0, 0, 0, 0.3);
}
.rental-container .agreement-detail::-webkit-scrollbar-thumb, .inspection-container .inspection-detail::-webkit-scrollbar-thumb {
    background-color: #303e67;
    outline: 1px solid #303e67;
}
.rental-container .agreement-detail hr, .inspection-container .inspection-detail hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid #ccc;
    margin: 1em 0;
    padding: 0;
}
.car-detail .tab-container .car-deal-nav {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
}
.rental-container .agreement-detail .row .navRow h4, .car-detail-container .tab-container .car-deal-nav h4, .inspection-container .inspection-detail .row .navRow h4 {
    display: inline-block;
    padding-right: 25px;
    margin: 0;
    opacity: .8;
    cursor: pointer;
}
.rental-container .agreement-detail .row .navRow h4.active, .car-detail-container .tab-container .car-deal-nav h4.active, .inspection-container .inspection-detail .row .navRow h4.active {
    opacity: 1;
    font-weight: 500;
}
.rental-container .agreement-detail .row .navRow h4:hover, .car-detail-container .tab-container .car-deal-nav h4:hover, .rental-container .agreement-detail .row .navRow h4:active, .car-detail-container .tab-container .car-deal-nav h4:active, .rental-container .agreement-detail .row .navRow h4:focus, .car-detail-container .tab-container .car-deal-nav h4:focus {
    opacity: 1;
    font-weight: 500;
}
.rental-container .agreement-detail .info-container .email {
    word-wrap: break-word;
}
.rental-container .car-agreement, .inspection-container .car-inspection {
    border-bottom: 1px solid #D6D6D6;
    padding: 0.5rem 1.5rem;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
}
.rental-container .car-agreement.active, .inspection-container .car-inspection.active,
.rental-container .car-agreement:focus, .inspection-container .car-inspection:focus,
.rental-container .car-agreement:hover, .inspection-container .car-inspection:hover {
    background-color: #F0F0EB !important;
}
.rental-container .car-agreement span, .inspection-container .car-inspection span {
    -webkit-align-self: center;
            align-self: center;
}
.rental-container .car-agreement h4, .inspection-container .car-inspection h4 {
    font-family: "Poppins";
    padding-left: 1rem;
    font-weight: 500;
    padding-top: 0.35rem;
    max-width: 175px;
    font-size: 15px;
}
.info-container label {
    opacity: 0.85;
    letter-spacing: 0.75px;
    text-transform: uppercase;
    font-size: 80%;
    font-weight: 500;
    line-height: 18.75px;
    color: #000000;
}
.tab-container .car-rental-container .car-rental label {
    opacity: 0.85;
    letter-spacing: 0.75px;
    text-transform: uppercase;
    font-size: 80%;
    font-weight: 500;
    color: #000000;
}
.tab-container .carissue-open {
    background-color: transparent;
    color: #DC3546;
    display: block;
    width: 100%;
    text-align: left;
    padding: 15px 30px;
    text-transform: uppercase;
    border: 1px solid#DC3546;
    border-radius: 6px;
    font-weight: 500;
}
.tab-container .carissue-open:hover, .tab-container .carissue-open:focus, .tab-container .carissue-open:active {
    box-shadow: none;
    background-color: #dc354648 !important;
    border-color: #DC3546 !important;
}
.tab-container .carissue-open span {
    float: right;
    font-weight: 400;
}
.tab-container .carissue-open span strong {
    font-weight: 700;
}
.tab-container .carissue-closed {
    background-color: transparent;
    color: #198754;
    display: block;
    width: 100%;
    text-align: left;
    padding: 15px 30px;
    text-transform: uppercase;
    border: 1px solid #198754;
    border-radius: 6px;
}
.tab-container .carissue-closed:hover, .tab-container .carissue-closed:focus, .tab-container .carissue-closed:active {
    box-shadow: none;
    background-color: #1987543c !important;
    border-color: #198754 !important;
}
.tab-container .carissue-closed span {
    float: right;
    font-weight: 400;
}
.tab-container .carissue-closed span strong {
    font-weight: 700;
}
.rental-container label {
    opacity: 0.85;
    letter-spacing: 0.75px;
    text-transform: uppercase;
    font-size: 80%;
    font-weight: 500;
    color: #000000;
}
.car-detail-container .tab-container label {
    opacity: 0.85;
    letter-spacing: 0.75px;
    text-transform: uppercase;
    font-size: 80%;
    font-weight: 500;
    color: #000000;
}
.car-detail-container .tab-container .car-rental-container .car-rental button {
    background-color: #1B4D7E;
    margin: 0px;
    width: 15.4rem;
}
.car-detail-container .tab-container .info-container > .row {
    margin-bottom: 1rem;
}
.car-detail-container .tab-container .info-container .equipments {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap
}
.car-detail-container .tab-container .info-container .status-stage {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
}
.car-detail-container .tab-container .info-container .row:last-child {
    margin-bottom: 0rem;
}
.car-detail-container .tab-container .info-container input[type="text"]:disabled {
    background-color: #FFFFFF;
}
.car-detail-container .tab-container .enable-disable-history {
    background-color: #F0F0EB;
    border: 1px solid #D6D6D6;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    box-shadow: 0px 13px 10px 1px rgba(0, 0, 0, 0.25);
    -webkit-box-shadow: 0px 13px 10px 1px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0px 13px 10px 1px rgba(0, 0, 0, 0.25);
    margin-bottom: 2rem;
    min-height: 155px;
    max-height: 185px;
    overflow: auto;
}
.car-detail-container .tab-container .enable-disable-history::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}
.car-detail-container .tab-container .enable-disable-history h5 {
    color: #000000;
}
.car-detail-container .tab-container .enable-disable-history::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px rgb(0, 0, 0, 0.3);
}
.car-detail-container .tab-container .enable-disable-history::-webkit-scrollbar-thumb {
    background-color: #303e67;
    outline: 1px solid #303e67;
}
.documents-tab .upload-document, .equipments-tab .install-equipment {
    border-radius: 12px;
    background-color: #1B4D7E;
    width: 180px;
    height: 45px;
    text-align: center;
    font-size: 13px;
    font-weight: 600;
}
.zero-padding {
    padding: 0px
}
.documents-tab .view-more {
    text-align: right;
    margin-right: 1rem;
}
.documents-tab .car-document-box .document-popup {
    margin: 0;
    background-color: #ffffff;
    border-radius: 10px;
    border: 1px solid #E7E9EC;
    padding: 10px;
    -webkit-justify-content: center;
            justify-content: center;
    text-align: center;
}
.documents-tab .car-document-box .document-popup .document-button .remove-open {
    border-radius: 8px;
    height: 38px;
    text-align: center;
    font-size: 11px;
    font-weight: 700;
    margin-right: 0px;
}
.documents-tab .car-document-box .document-popup .document-button .download {
    background-color: rgb(27, 77, 126);
}
.documents-tab .car-document-box .document-popup .document-button .remove {
    background-color: rgb(218, 218, 218);
    color: rgb(0, 0, 0);
}
.documents-tab .car-document-box .document-popup .document-button .open {
    background-color: #2DAFD3;
    color: rgb(0, 0, 0);
}
.documents-tab .car-document-box .document-popup .document-image {
    width: 300px;
    max-height: 200px;
    cursor: pointer;
    object-fit: contain;
}
.documents-tab .car-document-box .document-button {
    padding: 1rem
}
.car-detail .tab-container:last-child {
    margin-bottom: 0rem;
}
.car-detail .tab-container table {
    width: 100%;
}
.car-detail .tab-container table thead {
    background-color: #F0F0EB;
    color: #000000;
}
.car-detail .tab-container table tr {
    border: 1px solid rgba(202, 202, 202, 0.21);
    margin: 10px;
}
.car-detail .tab-container table th, table td {
    border: none;
    padding-bottom: 10px;
    padding-top: 10px;
    padding-left: 15px;
}
.car-detail .tab-container table th {
    font-size: 15px;
    font-weight: 500;
}
.car-detail .tab-container table td {
    padding-left: 20px;
}
.rental-container table {
    width: 100%;
}
.rental-container .history-container table thead {
    background-color: #F0F0EB;
    color: #000000;
}
.rental-container .history-container .danger {
    color: #FF5661 !important;
}
.rental-container .history-container .success {
    color: #02BD7D !important;
}
.rental-container .history-container {
    max-height: 225px;
    overflow: auto;
}
.rental-container .history-container::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}
.rental-container .history-container::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px rgb(0, 0, 0, 0.3);
}
.rental-container .history-container .table-responvsie {
    max-height: 250px;
    overflow: auto;
}
.rental-container .history-container .table-responvsie .limit-text {
    max-width: 125px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block
}
.rental-container .history-container .table-responvsie::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}
.rental-container .history-container .table-responvsie::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px rgb(0, 0, 0, 0.3);
}
.rental-container .history-container .table-responvsie::-webkit-scrollbar-thumb {
    background-color: #303e67;
    outline: 1px solid #303e67;
}
.rental-container .history-container::-webkit-scrollbar-thumb {
    background-color: #303e67;
    outline: 1px solid #303e67;
}
.rental-container .rental-pricing-container table th, table td {
    border: none;
    padding-bottom: 10px;
    padding-top: 10px;
    padding-left: 15px;
}
.rental-container .rental-pricing-container table th {
    font-size: 15px;
    font-weight: 500;
    background-color: #F0F0EB;
    color: #000000;
}
.rental-container .rental-pricing-container table td {
    padding-left: 20px;
}
.CarHeader {
    border-radius: 18px;
    border: 1px solid #E0E0E0;
    background: #E0E0E0;
    min-height: 20rem;
    height: 25rem;
    margin-bottom: 15px;
}
.car-header .car-image {
    -webkit-align-self: baseline;
            align-self: baseline;
}
.car-detail .btn.ghost:hover, .car-detail .btn.ghost:focus, .car-detail .btn.ghost:active {
    background-color: #263456;
    color: #FFF;
    border-color: transparent;
}
.rentalContainer .car-agreement .btn.ghost:hover, .rentalContainer .car-agreement .btn.ghost:focus, .rentalContainer .car-agreement .btn.ghost:active {
    background-color: #525f7f;
    color: #FFF;
    border-color: transparent;
}
.toggle-switch {
    position: relative;
    width: 60px;
    padding: 0px 9px;
    display: inline-block;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    text-align: left;
}
.toggle-switch-checkbox {
    display: none;
}
.toggle-switch-label {
    opacity: 0.85;
    display: block;
    overflow: hidden;
    cursor: pointer;
    border: 0 solid #bbb;
    border-radius: 20px;
    margin: 0;
}
.toggle-switch-inner {
    display: block;
    width: 200%;
    margin-left: -100%;
    transition: margin 0.1s ease-in 0s;
}
.toggle-switch-inner:before, .toggle-switch-inner:after {
    display: block;
    float: left;
    width: 50%;
    height: 24px;
    padding: 0;
    line-height: 24px;
    font-size: 14px;
    color: white;
    font-weight: 500;
    box-sizing: border-box;
}
.toggle-switch-inner:before {
    content: "";
    text-transform: uppercase;
    padding-left: 10px;
    background-color: #DC3546;
    color: #fff;
}
.toggle-switch-disabled {
    background-color: #ddd;
    cursor: not-allowed;
}
.toggle-switch-disabled:before {
    background-color: #ddd;
    cursor: not-allowed;
}
.equipments-tab .toggle-switch-inner:after, .toggle-switch-inner:after {
    content: "";
    text-transform: uppercase;
    padding-right: 10px;
    background-color: #2acd72;
    color: #fff;
    text-align: right;
}
.toggle-switch-switch {
    display: block;
    width: 18px;
    margin: 3px;
    background: #fff;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 25px;
    border: 0 solid #bbb;
    border-radius: 20px;
    transition: all 0.1s ease-in 0s;
}
.toggle-switch-checkbox:checked + .toggle-switch-label .toggle-switch-inner {
    margin-left: 0;
}
.toggle-switch-checkbox:checked + .toggle-switch-label .toggle-switch-switch {
    right: 10px;
}
.documents-tab .documents-container .document-popup .document-button .remove-open {
    border-radius: 8px;
    height: 38px;
    text-align: center;
    font-size: 11px;
    font-weight: 700;
    margin-right: 0px;
}
.documents-tab .documents-container .document-popup .document-button .download {
    background-color: rgb(27, 77, 126);
}
.documents-tab .documents-container .document-popup .document-button .remove {
    background-color: rgb(218, 218, 218);
    color: rgb(0, 0, 0);
}
.documents-tab .documents-container .document-popup .document-button .open {
    background-color: #2DAFD3;
    color: rgb(0, 0, 0);
}
/* .confirmation-popup .modal-header {
    background-color: #1B4D7E;
    color: #fff;
}
.confirmation-popup .modal-content {
    padding: 0px;
}
.confirmation-popup .modal-footer button {
    width: 65px;
    font-size: 11px;
    font-weight: 700;
    height: 38px;
}
.confirmation-popup .modal-body p {
    font-size: 15px;
    font-weight: 500;
} */
.insurance-tab-container {
    width: 100%;
    background-color: #FFFFFF;
    padding: 2.5rem;
    border: 1px solid #DADADA;
    min-height: 150px;
}
.insurance-tab-container .active-price {
    background-color: #008000;
    color: #ffffff;
}
.confirm, .confirm:hover {
    background-color: #dc3545;
}
.cancel, .cancel:hover {
    background-color: rgb(27, 77, 126);
}
.car-detail-container .change-view {
    margin-bottom: 15px;
}
.rental-container .no-rentals {
    font-size: 18px;
    font-weight: 500;
}
.car-detail i.switch {
    font-size: 25px;
    border: none;
}
.car-tracker-modal .modal-content .modal-body > .row:hover .selectButtonParent, .car-tracker-modal .modal-content .modal-body > .row:active .selectButtonParent {
    left: 0;
    opacity: 1;
}
.car-tracker-modal p {
    margin-top: .25rem;
    margin-bottom: .25rem;
    word-wrap: break-word;
    font-size: .8em;
}
.car-tracker-modal .label {
    font-size: .75em;
    opacity: .8;
}
.car-tracker-modal {
    color: #273846cc;
    font-family: 'Rubik', 'San serif';
}
.car-tracker-modal .modal-content {
    background-color: transparent;
}
.car-tracker-modal .modal-content .modal-body, .car-tracker-modal .modal-content .modal-footer {
    background-color: #fff;
}
.car-tracker-modal .modal-content .modal-header {
    font-weight: 500;
    background-color: #344A5C;
    color: #ffffff;
}
.car-tracker-modal .selectButtonParent {
    display: inline-block;
    width: 100%;
    height: 100%;
    position: absolute;
    transition: all .4s ease-out;
    left: -101%;
    top: 0;
    opacity: 0;
    background-color: rgba(80, 97, 111, 0.68);
    z-index: 1;
}
.collectionsListContainer .row{
	margin-bottom:25px;
	font-size: 0.93em;
}
.collectionsListContainer{
	color:#273846cc;
	font-family: 'Rubik', 'San serif';

}
.collectionsListContainer .btn-outline-primary, .collectionsListContainer .btn-outline-primary{
	color: #52606b;
	border-color: #52606b;
	background-color: transparent;
}
.collectionsListContainer .btn-outline-primary:not(:disabled):not(.disabled):active, .collectionsListContainer .btn-outline-primary:not(:disabled):not(.disabled).active, .collectionsListContainer .show > .btn-outline-primary.dropdown-toggle{
	color: #fff;
    background-color: #52606b;
    border-color: #52606b;
}

.collectionsListContainer .btn-outline-primary:hover,.collectionsListContainer .btn-outline-primary:active,.collectionsListContainer .btn-outline-primary:focus,
.collectionsListContainer .btn-outline-primary:not(:disabled):not(.disabled):active
{
	color: #fff;
    background-color: #52606b;
    border-color: #52606b;
}

.collectionsListContainer .nav-pills .nav-item{
	margin: 10px;
}
.popover{
	max-width: 500px;
} 
.collectionsListContainer .btn-link, .collectionsListContainer a{
	color: #52606b;
	text-decoration: none;
}
.collectionsListContainer .uppercase{
	text-transform:uppercase;
}
.collectionsListContainer .capitalize{
	text-transform:lowercase;
}
.collectionsListContainer .capitalize::first-letter{
	text-transform:capitalize;
}
.collectionsListContainer .linkHoverEffect{
	position: relative;
}
.collectionsListContainer .linkHoverEffect:hover:after{
	width: 100%;
	transition: all 0.2s ease-in-out;
}
.collectionsListContainer .linkHoverEffect:after{
	position: absolute;
	content: "";
	width: 0%;
	margin-top: 4px;
	height: 2px;
	background-color: #52606b;
	left: 0;
	bottom: 0;
	transition: all 0.2s ease-in-out;
}
i{
	cursor: pointer;
}
.collectionsListContainer .alert{
	text-align: center;
	border:none;
}
.collectionsListContainer .alert-danger{
	background-color:#FF4F4F;
	color:#fff;
	
}
.collectionsListContainer .alert-primary{
	background-color:#273846cc;
	color:#fff;
}
.collectionsListContainer .card{
	box-shadow: 3px 3px 9px 2px rgba(202, 202, 202, 0.21);
	border:none;
	border-radius: 8px;
}

.collectionsListContainer .table th, .table td{
	border:none;
	padding-bottom: 10px;
	padding-top: 10px;
}

.collectionsListContainer .table tr{
	border-bottom: 1px solid rgba(202, 202, 202, 0.21);
	margin: 10px;
	color:#273846cc;
}
.collectionsListContainer .table th{
	text-transform:uppercase;
	color: rgba(42, 53, 84, 0.52);
	font-size: 0.8em;
	font-weight: 500;
	vertical-align: middle;
	letter-spacing: 0.005em;
}

.shortText{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 300px;
}

/* Style Input Range Slider */

input[type=range] {
	-webkit-appearance: none;
	width: 100%;
  }
  input[type=range]:focus {
	outline: none;
  }
  input[type=range]::-webkit-slider-runnable-track {
	width: 100%;
	height: 8.4px;
	cursor: pointer;
	animate: 0.2s;
	background: #273846cc;
	border-radius: 2px;
  }
  input[type=range]::-webkit-slider-thumb {
	border: 1px solid #273846cc;
	height: 25px;
	width: 25px;
	border-radius: 8px;
	background: #ffffff;
	cursor: pointer;
	-webkit-appearance: none;
	margin-top:-8px;
  }
  input[type=range]:focus::-webkit-slider-runnable-track {
	background: #273846cc;
  }
  input[type=range]::-moz-range-track {
	width: 100%;
	height: 8.4px;
	cursor: pointer;
	animate: 0.2s;
	background: #273846cc;
	border-radius: 2px;
  }
  input[type=range]::-moz-range-thumb {
	border: 1px solid #273846cc;
	height: 25px;
	width: 25px;
	border-radius: 8px;
	background: #ffffff;
	cursor: pointer;
  }
  input[type=range]::-ms-track {
	width: 100%;
	height: 8.4px;
	cursor: pointer;
	animate: 0.2s;
	background: transparent;
	border-color: transparent;
	border-width: 16px 0;
	color: transparent;
  }
  input[type=range]::-ms-fill-lower {
	background: #273846cc;
	border-radius: 8px;
	box-shadow: 1px 1px 1px #273846cc, 0px 0px 1px #273846cc;
  }
  input[type=range]::-ms-fill-upper {
	background: #273846cc;
	border-radius: 8px;
	box-shadow: 1px 1px 1px #273846cc, 0px 0px 1px #0d0d0d;
  }
  input[type=range]::-ms-thumb {
	box-shadow: 1px 1px 1px #273846cc, 0px 0px 1px #000000;
	height: 25px;
	width: 25px;
	border-radius: 8px;
	background: #ffffff;
	cursor: pointer;
  }
  input[type=range]:focus::-ms-fill-lower {
	background: #273846cc;
  }
  input[type=range]:focus::-ms-fill-upper {
	background: #273846cc;
  }
  /* End of Input Range Slider style */
.insurance-preview-modal.modal-dialog {
	max-width: 900px !important;
}
.insurance-preview-modal .modal-content {
	background-color: white;
	color: #525f6f;
}
.insurance-preview-modal .modal-content .modal-body {
	max-height: 75vh;
	overflow-y: auto;
	overflow-x: hidden;
}
.insurance-preview-modal .modal-content .modal-body::-webkit-scrollbar {
	width: 5px;
	height: 5px;
	/* display: none; */
}
.insurance-preview-modal .modal-content .modal-body::-webkit-scrollbar-thumb {
	background-color: #303e67;
	outline: 1px solid #303e67;
	/* display: none; */
}

.insurance-preview-modal .modal-content .toggle-section-heading i {
	float: right;
	margin-top: 0.25rem;
}
.insurance-preview-modal .modal-content .toggle-section-heading {
	display: block;
	color: #525f6f;
	/* color: white; */
	background-color: #D6D6D6;
	/* background-color: #565d60; */
	border-radius: 10px;
	padding: 0.5rem 1rem;
	font-size: 1em;
	margin-bottom: 1rem;
}
.insurance-preview-modal .modal-content .toggle-section-heading.active {
	/* background-color: #1A1C1E; */
	background-color: #303e67;
	border-bottom-left-radius: 0px;
	border-bottom-right-radius: 0px;
	color: #ffffff;
}

.insurance-preview-modal .modal-content label {
	color: #525f6f;
	text-transform: uppercase;
	letter-spacing: 0.5px;
	font-size: 80%;
	font-weight: 600;
	margin-bottom: 0.25rem;
}
.insurance-preview-modal .modal-content p {
	margin-bottom: 0.25rem;

}
.insurance-preview-modal.modal-dialog row {
	margin-bottom: 15px !important;
}

.insurance-preview-modal .modal-content .modal-header {
	-webkit-justify-content: center;
	        justify-content: center;
	/* background-color: #303e67;
    width: 100% !important;
    border-radius: 5px;
    margin-bottom: 5px; */
}

.insurance-preview-modal .modal-content .modal-header .modal-title {
	font-weight: 400;
	font-size: 1.75em;
	text-transform: uppercase;
	/* color: white; */
}
.insurance-preview-modal-primary-btn {
	background-color: #303e67;
	border-radius: 7px;
	font-size: 13px;
	font-weight: 400;
	color: white;
	cursor: pointer;
}
.insurance-preview-modal-secondary-btn {
	background-color: white;
	border-radius: 7px;
	font-size: 13px;
	font-weight: 400;
	color: black;
	cursor: pointer;
}
.insurance-preview-modal-danger-btn {
	background-color: red;
	border-radius: 7px;
	font-size: 13px;
	font-weight: 400;
	color: white;
	cursor: pointer;
	border: #ffffff;
}
.insurance-preview-modal-success-btn {
	background-color: #28a745;
	border-radius: 7px;
	font-size: 13px;
	font-weight: 400;
	color: white;
	cursor: pointer;
	border: #ffffff;
}
.btn-container {
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-align-items: center;
	        align-items: center;
	margin: 20px;
}
.insurance-primary-btn {
	background-color: #303e67;
	width: 50% !important;
	color: #ffffff;
	height: 150px !important;
	font-size: large;
	border-radius: 10px;
	font-weight: 400;
	text-transform: uppercase;
	opacity: 0.7;
	border: none;
}
#insurance-preview-modal-popover {
	width: 100%;
	margin-bottom: 10px;
	border: solid 1px #303e67;
}
#insurance-preview-modal-popover-header {
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: center;
	        align-items: center;
	-webkit-justify-content: center;
	        justify-content: center;
	text-transform: uppercase;
}
#insurance-preview-modal-popover-body {
	font-size: 13px;
}
.insurance-preview-modal .insurance-preview-modal-nav {
	background-color: #ffffff;
}
.insurance-preview-modal .insurance-preview-modal-nav-item {
	border: solid 0px #303e67;
	background-color: #D6D6D6;
	color: #303e67;
}

.insurance-preview-modal .insurance-preview-modal-nav-item .active {
	background-color: #303e67;
	border: solid 1px #303e67;
}
.customer-handle-modal.modal-dialog {
	max-width: 800px !important;
}
.customer-handle-modal .modal-content {
	background-color: white;
	color: #525f6f;
}
.customer-handle-modal .modal-content .modal-body {
	max-height: 75vh;
	overflow-y: auto;
	overflow-x: hidden;
}

.customer-handle-modal-primary-btn {
	background-color: #303e67;
	border-radius: 7px;
	font-size: 13px;
	font-weight: 400;
	color: white;
	cursor: pointer;
}
.customer-handle-modal-secondary-btn {
	background-color: white;
	border-radius: 7px;
	font-size: 13px;
	font-weight: 400;
	color: black;
	cursor: pointer;
}
.customer-handle-modal-danger-btn {
	background-color: red;
	border-radius: 7px;
	font-size: 13px;
	font-weight: 400;
	color: white;
	cursor: pointer;
	border: #ffffff;
}


.lead-status-badge {
    font-size: 1em;
    font-weight: 400 !important;
    text-transform: uppercase;
}
.pdf-container {
    background-color: rgba(48, 49, 48, 0.42);
    z-index: 1000;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    transition: all 1.3s;
    width: 100%;
}

.pdf-container .pdf-canvas {
    width: 70%;
    height: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex: 1 1;
            flex: 1 1;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    -webkit-flex-direction: column;
            flex-direction: column;
    position: relative;
    left: 10%;
}

.pdf-container .close {
    position: absolute;
    top: 15px;
    right: 20px;
    color: white
}

.application-form a {
    color: #ffffff;
    text-decoration: none;
}

.pdf-container .pagination p {
    color: white;
    margin: 0 !important;
}

.pdf-container .pagination button {
    color: #4338CA;
}
.bos-listing-container .table a {
    color: #007bff;
    text-decoration: none;
    background-color: transparent;
}
.bos-listing-container .table a:hover {
    color: #0056b3;
    text-decoration: underline;
}
.bos-create-tenant-modal {
    max-width: 50%;
}
.bos-create-tenant-modal.modal-dialog {
    max-width: 800px !important;
}
.bos-create-tenant-modal .modal-content {
    background-color: white;
    color: #525f6f;
}
.members-options-wrapper {
    max-height: 20vh;
    overflow-y: scroll;
}
.members-options-wrapper::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}
.members-options-wrapper::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3);
}

.members-options-wrapper::-webkit-scrollbar-thumb {
    background-color: #303e67;
    outline: 1px solid #303e67;
}

.new-tenant-modal-primary-btn {
    background-color: #303e67;
    border-radius: 7px;
    font-size: 13px;
    font-weight: 400;
    color: white;
    cursor: pointer;
}
.new-tenant-modal-secondary-btn {
    background-color: white;
    border-radius: 7px;
    font-size: 13px;
    font-weight: 400;
    color: black;
    cursor: pointer;
}
.new-tenant-modal-danger-btn {
    background-color: red;
    border-radius: 7px;
    font-size: 13px;
    font-weight: 400;
    color: white;
    cursor: pointer;
    border: #ffffff;
}

#box-object-icon {
    background-color: white;
    border: 7px solid #f2f3f6;
    padding: 32px 5px;
    border-radius: 50%;
    position: relative;
    bottom: -15px;
}
.more-info-tooltip {
    opacity: 1 !important;
}
.more-info-tooltip .tooltip-inner {
    padding: 0.5rem 1rem;
    text-align: left;
    background-color: #F2F2F2;
    color: black;
    border: 1px solid #d7dce2;
    font-size: 80%;
    max-height: 80px;
    overflow-y: auto;
}
.create-permission-modal.modal-dialog {
    max-width: 800px !important;
}
.create-permission-modal .modal-content {
    background-color: white;
    color: #525f6f;
}
/* .create-permission-modal .modal-content .modal-body {
	max-height: 75vh;
	overflow-y: auto;
	overflow-x: hidden;
} */
.create-permission-modal .modal-content .modal-body::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    /* display: none; */
}
.create-permission-modal .modal-content .modal-body::-webkit-scrollbar-thumb {
    background-color: #303e67;
    outline: 1px solid #303e67;
    /* display: none; */
}

.create-permission-modal .modal-content .toggle-section-heading i {
    float: right;
    margin-top: 0.25rem;
}
.create-permission-modal .modal-content .toggle-section-heading {
    display: block;
    color: #525f6f;
    background-color: #D6D6D6;
    border-radius: 10px;
    padding: 0.5rem 1rem;
    font-size: 1em;
    margin-bottom: 1rem;
}

.create-permission-modal .modal-content .toggle-section-heading.active {
    /* background-color: #1A1C1E; */
    background-color: #303e67;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    color: #ffffff;
}

.create-permission-modal .modal-content label {
    color: #525f6f;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    font-size: 80%;
    font-weight: 600;
    margin-bottom: 0.25rem;
}
.create-permission-modal .modal-content p {
    margin-bottom: 0.25rem;

}
.create-permission-modal.modal-dialog row {
    margin-bottom: 15px !important;
}

.create-permission-modal .modal-content .modal-header {
    -webkit-justify-content: center;
            justify-content: center;
}

.create-permission-modal .modal-content .modal-header .modal-title {
    font-weight: 400;
    font-size: 1.75em;
    text-transform: uppercase;
    /* color: white; */
}
.create-permission-modal .issue-extra-info-wrapper {
    margin-top: 1.5rem;
    padding-top: 1.25rem;
    border-top: 1px solid #dee2e6;
}

.create-permission-modal-primary-btn {
    background-color: #303e67;
    border-radius: 7px;
    font-size: 13px;
    font-weight: 400;
    color: white;
    cursor: pointer;
}
.create-permission-modal-secondary-btn {
    background-color: white;
    border-radius: 7px;
    font-size: 13px;
    font-weight: 400;
    color: black;
    cursor: pointer;
}
.create-permission-modal-danger-btn {
    background-color: red;
    border-radius: 7px;
    font-size: 13px;
    font-weight: 400;
    color: white;
    cursor: pointer;
    border: #ffffff;
}
.arrow-btn-container {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
}
.arrow-btn {
    background-color: #303e67;
    color: white;
}
.overview-primary-btn {
    background-color: #303e67;
    color: white;
    border-radius: 5px;
}
.create-group-modal.modal-dialog {
    max-width: 800px !important;
}
.create-group-modal .modal-content {
    background-color: white;
    color: #525f6f;
}
/* .create-group-modal .modal-content .modal-body {
	max-height: 75vh;
	overflow-y: auto;
	overflow-x: hidden;
} */
.create-group-modal .modal-content .modal-body::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    /* display: none; */
}
.create-group-modal .modal-content .modal-body::-webkit-scrollbar-thumb {
    background-color: #303e67;
    outline: 1px solid #303e67;
    /* display: none; */
}

.create-group-modal .modal-content .toggle-section-heading i {
    float: right;
    margin-top: 0.25rem;
}
.create-group-modal .modal-content .toggle-section-heading {
    display: block;
    color: #525f6f;
    background-color: #D6D6D6;
    border-radius: 10px;
    padding: 0.5rem 1rem;
    font-size: 1em;
    margin-bottom: 1rem;
}

.create-group-modal .modal-content .toggle-section-heading.active {
    /* background-color: #1A1C1E; */
    background-color: #303e67;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    color: #ffffff;
}

.create-group-modal .modal-content label {
    color: #525f6f;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    font-size: 80%;
    font-weight: 600;
    margin-bottom: 0.25rem;
}
.create-group-modal .modal-content p {
    margin-bottom: 0.25rem;

}
.create-group-modal.modal-dialog row {
    margin-bottom: 15px !important;
}

.create-group-modal .modal-content .modal-header {
    -webkit-justify-content: center;
            justify-content: center;
}

.create-group-modal .modal-content .modal-header .modal-title {
    font-weight: 400;
    font-size: 1.75em;
    text-transform: uppercase;
    /* color: white; */
}
.create-group-modal .issue-extra-info-wrapper {
    margin-top: 1.5rem;
    padding-top: 1.25rem;
    border-top: 1px solid #dee2e6;
}

.create-group-modal-primary-btn {
    background-color: #303e67;
    border-radius: 7px;
    font-size: 13px;
    font-weight: 400;
    color: white;
    cursor: pointer;
}
.create-group-modal-secondary-btn {
    background-color: white;
    border-radius: 7px;
    font-size: 13px;
    font-weight: 400;
    color: black;
    cursor: pointer;
}
.create-group-modal-danger-btn {
    background-color: red;
    border-radius: 7px;
    font-size: 13px;
    font-weight: 400;
    color: white;
    cursor: pointer;
    border: #ffffff;
}
.arrow-btn-container {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
}
.arrow-btn {
    background-color: #303e67;
    color: white;
}
.overview-primary-btn {
    background-color: #303e67;
    color: white;
    border-radius: 5px;
}
.subscriptionDetail label {
    opacity: .85;
    letter-spacing: .75px;
    text-transform: uppercase;
    font-size: 80%;
    font-weight: 500;
    color: #000;
}

.subscriptionDetail .modal-header .modal-title button {
    position: absolute;
    right: 2%;
    color: white;
    font-size: 25px;
    top: 5px;
    border: none;
}
.subscriptionDetail .modal-header {
    -webkit-justify-content: center;
            justify-content: center;
}
.subscriptionDetail .modal-header .modal-title {
    font-weight: 400;
    font-size: 2em;
    text-transform: uppercase;
}
.argyle-account-detail-modal .modal-content {
    background-color: white;
    border-radius: 0px;
    color: black;
    width: 800px;
}
.argyle-account-detail-modal .modal-content .modal-body {
    max-height: 75vh;
    overflow-y: auto;
    overflow-x: hidden;
}
.argyle-account-detail-modal .modal-content .modal-body::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}
.argyle-account-detail-modal .modal-content .modal-body::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3);
}
.argyle-account-detail-modal .modal-content .modal-body::-webkit-scrollbar-thumb {
    background-color: #303e67;
    outline: 1px solid #303e67;
}
.argyle-account-detail-modal .modal-content label {
    color: rgb(73, 73, 73);
    text-transform: uppercase;
    letter-spacing: 0.2px;
    font-weight: 400;
}
.argyle-account-detail-modal .modal-content .modal-header {
    -webkit-justify-content: center;
            justify-content: center;
}
.argyle-account-detail-modal .modal-content .modal-header .modal-title {
    font-weight: 400;
    font-size: 1.75em;
    text-transform: uppercase;
}

.argyle-account-detail-modal-detail-box {
    border-radius: 10px;
    margin-top: 10px;
    margin-left: 0px;
    margin-right: 0px;
    background-color: #FFFFFF;
    -webkit-justify-content: space-evenly;
            justify-content: space-evenly;
    padding: 2rem;
    border-radius: 10px;
    border-top: solid 0px #C9C9C9;
    box-shadow: 0px 5px 5px 1px rgba(0, 0, 0, 0.25);
    -webkit-box-shadow: 0px 5px 10px 1px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0px 5px 3px 1px rgba(0, 0, 0, 0.25);
}
.bos-login-container {
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    position: fixed;
    z-index: 99;
    -webkit-backdrop-filter: blur(7px);
            backdrop-filter: blur(7px);
}
.bos-login-title-container {
    font-size: 2em;
    font-weight: 500;
    letter-spacing: 1px;
    text-transform: uppercase;
    text-align: center;
    background-color: #303e67;
    color: #FFFFFF;
    padding: 15px;
    padding-bottom: 0px;
}
.bos-login-options-container {
    text-align: center;
    margin: 7rem 0px;
}
.bos-login-options-container .bos-login-option {
    display: inline-block;
    border: 1px solid #273846cc;
    border-radius: 17px;
    color: #273846cc;
    cursor: pointer;
    transition: all 0.4s ease 0s;
}
.bos-login-options-container .bos-login-option:hover {
    background-color: #ffffff;
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
}
.bos-login-options-container .bos-login-option img {
    width: 300px;
    height: auto;
    margin-bottom: 55px;
}
.bos-login-options-container .bos-login-option label {
    font-size: 1em;
    display: block;
    padding-top: 17px;
    text-transform: uppercase;
    font-weight: 500;
    padding: 10px 20px;
    cursor: pointer;
}
.bos-login-options-container .bos-login-option:hover label {
    background: #303e67;
    color: #FFFFFF;
    text-decoration: none;
}
.ListContainer .row{
	margin-bottom:50px;
	font-size: 0.93em;
}
.ListContainer{
	color:#273846cc;
	font-family: 'Rubik', 'San serif';
}
.ListContainer .btn-outline-primary, .ListContainer .btn-outline-primary{
	color: #52606b;
	border-color: #52606b;
	background-color: transparent;
}
.ListContainer .btn-outline-primary:not(:disabled):not(.disabled):active, .ListContainer .btn-outline-primary:not(:disabled):not(.disabled).active, .ListContainer .show > .btn-outline-primary.dropdown-toggle{
	color: #fff;
    background-color: #52606b;
    border-color: #52606b;
}

.ListContainer .btn-outline-primary:hover,.ListContainer .btn-outline-primary:active,.ListContainer .btn-outline-primary:focus,
.ListContainer .btn-outline-primary:not(:disabled):not(.disabled):active
{
	color: #fff;
    background-color: #52606b;
    border-color: #52606b;
}

.ListContainer .nav-pills .nav-item{
	margin: 10px;
}
.popover{
	max-width: 500px;
} 
.ListContainer .btn-link, .ListContainer a{
	color: #52606b;
	text-decoration: none;
}
.ListContainer .uppercase{
	text-transform:uppercase;
}
.ListContainer .capitalize{
	text-transform:lowercase;
}
.ListContainer .capitalize::first-letter{
	text-transform:capitalize;
}
.ListContainer .linkHoverEffect{
	position: relative;
}
.ListContainer .linkHoverEffect:hover:after{
	width: 100%;
	transition: all 0.2s ease-in-out;
}
.ListContainer .linkHoverEffect:after{
	position: absolute;
	content: "";
	width: 0%;
	margin-top: 4px;
	height: 2px;
	background-color: #52606b;
	left: 0;
	bottom: 0;
	transition: all 0.2s ease-in-out;
}
i{
	cursor: pointer;
}
.ListContainer .alert{
	text-align: center;
	border:none;
}
.ListContainer .alert-danger{
	background-color:#FF4F4F;
	color:#fff;
	
}
.ListContainer .alert-primary{
	background-color:#273846cc;
	color:#fff;
}
.ListContainer .card{
	box-shadow: 3px 3px 9px 2px rgba(202, 202, 202, 0.21);
	border:none;
	border-radius: 8px;
}

.ListContainer .table th, .table td{
	border:none;
	padding-bottom: 10px;
	padding-top: 10px;
}

.ListContainer .table tr{
	border-bottom: 1px solid rgba(202, 202, 202, 0.21);
	margin: 10px;
	color:#273846cc;
}
.ListContainer .table th{
	text-transform:uppercase;
	color: rgba(42, 53, 84, 0.52);
	font-size: 0.8em;
	font-weight: 500;
	vertical-align: middle;
	letter-spacing: 0.005em;
}

.shortText{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 300px;
}

/* Style Input Range Slider */

input[type=range] {
	-webkit-appearance: none;
	width: 100%;
  }
  input[type=range]:focus {
	outline: none;
  }
  input[type=range]::-webkit-slider-runnable-track {
	width: 100%;
	height: 8.4px;
	cursor: pointer;
	-webkit-animation: 0.2s;
	        animation: 0.2s;
	background: #273846cc;
	border-radius: 2px;
  }
  input[type=range]::-webkit-slider-thumb {
	border: 1px solid #273846cc;
	height: 25px;
	width: 25px;
	border-radius: 8px;
	background: #ffffff;
	cursor: pointer;
	-webkit-appearance: none;
	margin-top:-8px;
  }
  input[type=range]:focus::-webkit-slider-runnable-track {
	background: #273846cc;
  }
  input[type=range]::-moz-range-track {
	width: 100%;
	height: 8.4px;
	cursor: pointer;
	animation: 0.2s;
	background: #273846cc;
	border-radius: 2px;
  }
  input[type=range]::-moz-range-thumb {
	border: 1px solid #273846cc;
	height: 25px;
	width: 25px;
	border-radius: 8px;
	background: #ffffff;
	cursor: pointer;
  }
  input[type=range]::-ms-track {
	width: 100%;
	height: 8.4px;
	cursor: pointer;
	animation: 0.2s;
	background: transparent;
	border-color: transparent;
	border-width: 16px 0;
	color: transparent;
  }
  input[type=range]::-ms-fill-lower {
	background: #273846cc;
	border-radius: 8px;
	box-shadow: 1px 1px 1px #273846cc, 0px 0px 1px #273846cc;
  }
  input[type=range]::-ms-fill-upper {
	background: #273846cc;
	border-radius: 8px;
	box-shadow: 1px 1px 1px #273846cc, 0px 0px 1px #0d0d0d;
  }
  input[type=range]::-ms-thumb {
	box-shadow: 1px 1px 1px #273846cc, 0px 0px 1px #000000;
	height: 25px;
	width: 25px;
	border-radius: 8px;
	background: #ffffff;
	cursor: pointer;
  }
  input[type=range]:focus::-ms-fill-lower {
	background: #273846cc;
  }
  input[type=range]:focus::-ms-fill-upper {
	background: #273846cc;
  }
  /* End of Input Range Slider style */
.dtac-history-tooltip {
    opacity: 1 !important;
}
.dtac-history-tooltip .tooltip-inner {
    min-width: 175px;
    max-width: 300px;
    padding: 0.25rem 1rem 0.5rem 1rem;
    width: auto;
    text-align: left;
    background-color: #F2F2F2;
    color: black;
    border: 1px solid #d7dce2;
    max-height: 200px;
    overflow: auto;
}
.dtac-history-tooltip label {
    display: block;
    text-align: center;
    font-size: 1em;
    text-transform: uppercase;
    font-weight: bold;
}
.dtac-history-tooltip ul {
    padding-left: 1rem;
    padding-bottom: 0px;
}
.AutomationContainer .info-container label {
    opacity: 0.85;
    letter-spacing: 0.75px;
    text-transform: uppercase;
    font-size: 80%;
    font-weight: 500;
    line-height: 18.75px;
    color: #000000;
}

.AutomationContainer .info-container {
    background-color: #f0f0eb !important;
    padding: 2rem;
    border-radius: 10px;
    box-shadow: 0px 5px 5px -1px rgb(0 0 0 / 14%);
    -webkit-box-shadow: 0px 5px 5px -1px rgb(0 0 0 / 14%);
    -moz-box-shadow: 0px 5px 5px -1px rgb(0 0 0 / 14%);
}

.AutomationContaine .info-container:nth-child(1) {
    margin-bottom: 2rem;
}

.AutomationContainer .info-container h4 {
    opacity: 0.85;
    letter-spacing: 0.75px;
    text-transform: uppercase;
    font-weight: 500;
    line-height: 18.75px;
}
.ChatBotContainer {
    color: #273846cc;
    font-family: 'Rubik', 'San serif';
    position: relative;
    overflow: hidden;
    min-height: 4100px;
    width: 100%;
}

.ChatBotContainer .btn-outline-primary, .ChatBotContainer .btn-outline-primary {
    color: #52606b;
    border-color: #52606b;
    background-color: transparent;
}
.ChatBotContainer .btn-outline-primary:not(:disabled):not(.disabled):active, .ChatBotContainer .btn-outline-primary:not(:disabled):not(.disabled).active, .ChatBotContainer .show > .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #52606b;
    border-color: #52606b;
}

.ChatBotContainer .btn-outline-primary:hover, .ChatBotContainer .btn-outline-primary:active, .ChatBotContainer .btn-outline-primary:focus,
.ChatBotContainer .btn-outline-primary:not(:disabled):not(.disabled):active {
    color: #fff;
    background-color: #52606b;
    border-color: #52606b;
}

.ChatBotContainer .nav-pills .nav-item {
    margin: 10px;
}
.popover {
    max-width: 500px;
}
.ChatBotContainer .uppercase {
    text-transform: uppercase;
}
.ChatBotContainer .capitalize {
    text-transform: lowercase;
}
.ChatBotContainer .capitalize::first-letter {
    text-transform: capitalize;
}
.ChatBotContainer .linkHoverEffect {
    position: relative;
}
.ChatBotContainer .linkHoverEffect:hover:after {
    width: 100%;
    transition: all 0.2s ease-in-out;
}
.ChatBotContainer .linkHoverEffect:after {
    position: absolute;
    content: "";
    width: 0%;
    margin-top: 4px;
    height: 2px;
    background-color: #52606b;
    left: 0;
    bottom: 0;
    transition: all 0.2s ease-in-out;
}
i {
    cursor: pointer;
}
.ChatBotContainer .alert {
    text-align: center;
    border: none;
}
.ChatBotContainer .alert-danger {
    background-color: #FF4F4F;
    color: #fff;

}
.ChatBotContainer .alert-primary {
    background-color: #273846cc;
    color: #fff;
}

.ChatBotContainer .table th, .table td {
    vertical-align: middle;
    border: none;
    padding-bottom: 10px;
    padding-top: 10px;
}

.ChatBotContainer .table tr {
    border-bottom: 1px solid rgba(202, 202, 202, 0.21);
    margin: 10px;
    color: #273846cc;
}
.ChatBotContainer .table th {
    text-transform: uppercase;
    color: rgba(42, 53, 84, 0.52);
    font-size: 0.8em;
    font-weight: 500;
    vertical-align: middle;
    letter-spacing: 0.005em;
}

.shortText {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 300px;
}

input[type=range] {
    -webkit-appearance: none;
    width: 100%;
}
input[type=range]:focus {
    outline: none;
}
input[type=range]::-webkit-slider-runnable-track {
    width: 100%;
    height: 8.4px;
    cursor: pointer;
    animate: 0.2s;
    background: #273846cc;
    border-radius: 2px;
}
input[type=range]::-webkit-slider-thumb {
    border: 1px solid #273846cc;
    height: 25px;
    width: 25px;
    border-radius: 8px;
    background: #ffffff;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -8px;
}
input[type=range]:focus::-webkit-slider-runnable-track {
    background: #273846cc;
}
input[type=range]::-moz-range-track {
    width: 100%;
    height: 8.4px;
    cursor: pointer;
    animate: 0.2s;
    background: #273846cc;
    border-radius: 2px;
}
input[type=range]::-moz-range-thumb {
    border: 1px solid #273846cc;
    height: 25px;
    width: 25px;
    border-radius: 8px;
    background: #ffffff;
    cursor: pointer;
}
input[type=range]::-ms-track {
    width: 100%;
    height: 8.4px;
    cursor: pointer;
    animate: 0.2s;
    background: transparent;
    border-color: transparent;
    border-width: 16px 0;
    color: transparent;
}
input[type=range]::-ms-fill-lower {
    background: #273846cc;
    border-radius: 8px;
    box-shadow: 1px 1px 1px #273846cc, 0px 0px 1px #273846cc;
}
input[type=range]::-ms-fill-upper {
    background: #273846cc;
    border-radius: 8px;
    box-shadow: 1px 1px 1px #273846cc, 0px 0px 1px #0d0d0d;
}
input[type=range]::-ms-thumb {
    box-shadow: 1px 1px 1px #273846cc, 0px 0px 1px #000000;
    height: 25px;
    width: 25px;
    border-radius: 8px;
    background: #ffffff;
    cursor: pointer;
}
input[type=range]:focus::-ms-fill-lower {
    background: #273846cc;
}
input[type=range]:focus::-ms-fill-upper {
    background: #273846cc;
}
.microDashboard card {
    border: none;
}


.ChatBotList {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    margin: 0px;
    padding: 0px;
    list-style-type: none;
    max-height: 50vh;
    max-width: 100%;
    overflow-y: auto;
}

.ChatBotList li {
    margin: 0 0 10px 0;
    display: block;
    width: 100%;
    display: -webkit-flex;
    display: flex;
    color: #273846cc;
    border-radius: 5px;
}
.ChatBotList li.active, .ChatBotList li.active:hover, .ChatBotList li.active:focus {
    color: #f8f9fa;
    text-decoration: none;
    background-color: #273846cc;
}
.ChatBotList li .icon {
    width: 20%;
    box-sizing: content-box;
    display: inline-block;

}
.ChatBotList li .icon i {
    padding: 10px;
}
.ChatBotList li a {
    width: 80%;
    height: 100%;

    display: inline-block;
    text-decoration: none;
    padding: 10px;
    color: inherit;
}



.ChatBotSession .ChatBotMessage {
    padding: 10px;
    margin: 10px;
    border-radius: 10px;
    color: #273846cc;
    max-width: 70%;
    word-wrap: break-word;

}

.ChatBotSession .ChatBotMessage.ChatBotMessageBot {
    color: #273846cc;
    border: 1px solid rgba(39, 56, 70, 0.041);
}

.ChatBotSession .ChatBotMessage.ChatBotMessageUser {
    background-color: #f8f9fa;
    color: #273846cc;
    margin-left: auto;
}

.ChatBotSessionBody {
    min-height: 80vh;
    max-height: 80vh;
    overflow-y: hidden;
    position: relative;
}

.ChatBotSessionMessages {
    max-height: 80vh;
    padding-bottom: 20vh;
    overflow-y: scroll;
}
.ChatBotSessionMessages code {
    display: block;
}
.ChatBotSessionInput {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 80%;
    background-color: #FFFFFF;
    border-radius: 5px;
    padding: 10px;
    border-top: 1px solid rgba(202, 202, 202, 0.21);
    margin-left: auto;
}

.ChatBotSessionInput textarea {
    width: 100%;
    border: none;
    padding: 10px;
    border-radius: 5px;

}

.ChatBotSessionInput textarea:focus {
    outline: none;
}

.ChatBotSessionInput button {
    border: none;
    background-color: #52606b;
    color: #fff;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    float: right;
}
.ChatBotSessionMessageActions {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: end;
            justify-content: end;
    margin: 10px;
    grid-gap: 5px;
    gap: 5px;
}
.fa-thumbs-up.active {
    color: #429faa
}
.fa-thumbs-down.active {
    color: #FF4F4F
}
.TaskManagementContainer .row {
    margin: 5px 0px 10px;
    font-size: 0.93em;
}
.TaskManagementContainer {
    color: #273846cc;
    font-family: 'Rubik', 'San serif';

}
.TaskManagementContainer .btn-outline-primary, .TaskManagementContainer .btn-outline-primary {
    color: #52606b;
    border-color: #52606b;
    background-color: transparent;
}
.TaskManagementContainer .btn-outline-primary:not(:disabled):not(.disabled):active, .TaskManagementContainer .btn-outline-primary:not(:disabled):not(.disabled).active, .TaskManagementContainer .show > .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #52606b;
    border-color: #52606b;
}

.TaskManagementContainer .btn-outline-primary:hover, .TaskManagementContainer .btn-outline-primary:active, .TaskManagementContainer .btn-outline-primary:focus,
.TaskManagementContainer .btn-outline-primary:not(:disabled):not(.disabled):active {
    color: #fff;
    background-color: #52606b;
    border-color: #52606b;
}

.TaskManagementContainer .nav-pills .nav-item {
    margin: 10px;
}
.popover {
    max-width: 500px;
}
.TaskManagementContainer .uppercase {
    text-transform: uppercase;
}
.TaskManagementContainer .capitalize {
    text-transform: lowercase;
}
.TaskManagementContainer .capitalize::first-letter {
    text-transform: capitalize;
}
.TaskManagementContainer .linkHoverEffect {
    position: relative;
}
.TaskManagementContainer .linkHoverEffect:hover:after {
    width: 100%;
    transition: all 0.2s ease-in-out;
}
.TaskManagementContainer .linkHoverEffect:after {
    position: absolute;
    content: "";
    width: 0%;
    margin-top: 4px;
    height: 2px;
    background-color: #52606b;
    left: 0;
    bottom: 0;
    transition: all 0.2s ease-in-out;
}
i {
    cursor: pointer;
}
.TaskManagementContainer .alert {
    text-align: center;
    border: none;
}
.TaskManagementContainer .alert-danger {
    background-color: #FF4F4F;
    color: #fff;

}
.TaskManagementContainer .alert-primary {
    background-color: #273846cc;
    color: #fff;
}

.TaskManagementContainer .table th, .table td {
    vertical-align: middle;
    border: none;
    padding-bottom: 10px;
    padding-top: 10px;
}

.TaskManagementContainer .table tr {
    border-bottom: 1px solid rgba(202, 202, 202, 0.21);
    margin: 10px;
    color: #273846cc;
}
.TaskManagementContainer .table th {
    text-transform: uppercase;
    color: rgba(42, 53, 84, 0.52);
    font-size: 0.8em;
    font-weight: 500;
    vertical-align: middle;
    letter-spacing: 0.005em;
}

.shortText {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 300px;
}

/* Style Input Range Slider */

input[type=range] {
    -webkit-appearance: none;
    width: 100%;
}
input[type=range]:focus {
    outline: none;
}
input[type=range]::-webkit-slider-runnable-track {
    width: 100%;
    height: 8.4px;
    cursor: pointer;
    animate: 0.2s;
    background: #273846cc;
    border-radius: 2px;
}
input[type=range]::-webkit-slider-thumb {
    border: 1px solid #273846cc;
    height: 25px;
    width: 25px;
    border-radius: 8px;
    background: #ffffff;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -8px;
}
input[type=range]:focus::-webkit-slider-runnable-track {
    background: #273846cc;
}
input[type=range]::-moz-range-track {
    width: 100%;
    height: 8.4px;
    cursor: pointer;
    animate: 0.2s;
    background: #273846cc;
    border-radius: 2px;
}
input[type=range]::-moz-range-thumb {
    border: 1px solid #273846cc;
    height: 25px;
    width: 25px;
    border-radius: 8px;
    background: #ffffff;
    cursor: pointer;
}
input[type=range]::-ms-track {
    width: 100%;
    height: 8.4px;
    cursor: pointer;
    animate: 0.2s;
    background: transparent;
    border-color: transparent;
    border-width: 16px 0;
    color: transparent;
}
input[type=range]::-ms-fill-lower {
    background: #273846cc;
    border-radius: 8px;
    box-shadow: 1px 1px 1px #273846cc, 0px 0px 1px #273846cc;
}
input[type=range]::-ms-fill-upper {
    background: #273846cc;
    border-radius: 8px;
    box-shadow: 1px 1px 1px #273846cc, 0px 0px 1px #0d0d0d;
}
input[type=range]::-ms-thumb {
    box-shadow: 1px 1px 1px #273846cc, 0px 0px 1px #000000;
    height: 25px;
    width: 25px;
    border-radius: 8px;
    background: #ffffff;
    cursor: pointer;
}
input[type=range]:focus::-ms-fill-lower {
    background: #273846cc;
}
input[type=range]:focus::-ms-fill-upper {
    background: #273846cc;
}
.microDashboard card {
    border: none;
}
/* End of Input Range Slider style */

.TaskBoardList {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    margin: 0px;
    padding: 0px;
    list-style-type: none;
    max-height: 50vh;
    max-width: 100%;
    overflow-y: auto;
}

.TaskBoardList li {
    margin: 0 0 10px 0;
    display: block;
    width: 100%;
    display: -webkit-flex;
    display: flex;
    color: #273846cc;
    border-radius: 5px;
}
.TaskBoardList li.active, .TaskBoardList li.active:hover, .TaskBoardList li.active:focus {
    color: #f8f9fa;
    text-decoration: none;
    background-color: #273846cc;
}
.TaskBoardList li .icon {
    width: 20%;
    box-sizing: content-box;
    display: inline-block;

}
.TaskBoardList li .icon i {
    padding: 10px;
}
.TaskBoardList li a {
    width: 80%;
    height: 100%;

    display: inline-block;
    text-decoration: none;
    padding: 10px;
    color: inherit;
}
/* .TaskBoardList li a:hover, .TaskBoardList li a:focus, .TaskBoardList li a:active, .TaskBoardList li a.active {
    color: #f8f9fa;
    text-decoration: none;
    background-color: #273846cc;
} */
/* .TaskBoardList li a:hover, .TaskBoardList li a:focus, .TaskBoardList li a:active, .TaskBoardList li a.active {
    color: #f8f9fa;
    text-decoration: none;
    background-color: #273846cc;
} */
.TaskDetailsSidePanelContainer {
    position: relative;
    font-size: 1.0em;
}
.TaskDetailsSidePanel {
    box-shadow: -8px 10px 5px 0px rgba(0, 0, 0, 0.16);
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    padding: 20px 20px;
    width: 50%;
    height: 100%;
    margin-right: -100%;
    background-color: #f8f9fa;
    border-right: 1px solid #e9ecef;
    z-index: 1000;
    opacity: 0;
    -webkit-transform: translate3d(200px, 0, 0);
            transform: translate3d(200px, 0, 0);
    transition: all 150ms cubic-bezier(0, 0, 0.35, 1);
}

.TaskDetailsSidePanel.show {
    opacity: 1;
    margin-right: 0%;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
    overflow: scroll;
}

.CommentCard .card-header, .CommentCard .card-footer {
    background-color: #F8F9FA;
    border-bottom: none;
    border-top: none;
    font-size: 1.0em;
}

.CommentCard .card-header {
    font-style: italic;

}

.CommentCard .card-body {
    background-color: #FFFFFF;
    font-size: 1.0em;
}

.CommentCard .like .liked {
    text-shadow: 0 0 0 #392c00;
    color: #ffb62c;
}
.NewCommentForm {
    font-size: 1.0em;
}
.TaskDetailsSidePanel .tab-content {
    padding-top: 20px;
    padding-bottom: 20px;
}

.NewCommentTextAreaFormGroup .MentionsInputContainer {
    position: relative;
}
.NewCommentTextAreaFormGroup .text-area--multiLine {
    position: relative;
    min-height: 100px;
    background-color: #FFFFFF;
}

.NewCommentTextAreaFormGroup .text-area__input {
    min-width: 100%;
    max-width: 100%;
    resize: auto !important;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.NewCommentTextAreaFormGroup .text-area__input:focus {
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.NewCommentTextAreaFormGroup .user-suggestion {
    background-color: #f3f5f7;
    border-bottom: 1px solid #e9e9e9;
    padding: 10px;
    min-width: 190px;
}
.TaskList .task-cell {
    min-width: 70px;
    max-width: 70px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.TaskList .task-cell.title {
    max-width: 120px;
}

.TaskList .dropdown-menu {
    margin: 0;
    padding: 0;
    text-align: center;
}

.TaskList .dropdown-item {
    padding: 10px 15px;
    cursor: pointer;
}

/* Give border radius to first and last item */
.TaskList .dropdown-item:first-child {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.TaskList .dropdown-item:last-child {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.popover-custom-width.popover {
    width: 100% !important;
}
