@import url('https://fonts.googleapis.com/css?family=Rubik:400,500&display=swap');
.RunPickupModal label {
    margin-bottom: .2rem;
    font-size: .85em;
    opacity: .6;
    color: rgba(39, 56, 70, .8);
    font-family: "Rubik", "San serif";
    font-weight: 500;
}
.RunPickupModal .form-group {
    margin-bottom: 2rem;
}
.RunPickupModal .modal-body {
    padding: 20px 30px;
}
.RunPickupModal .modal-header {
    font-weight: 500;
    background-color: #344A5C;
    color: #ffffff;
}
.RunPickupModal {
    font-family: "Rubik", "San serif";
}
.RunPickupModal .modal-content {
    padding: 0;
}



.pickup-model {
    margin-bottom: .2rem;
    font-size: .85em;
    opacity: .6;
    color: rgba(39, 56, 70, .8);
    font-family: "Rubik", "San serif";
    font-weight: 500;
}
.pickup-model .modal-header {
    font-weight: 500;
    background-color: #344A5C;
    color: #ffffff;
}

.payment-method {
    border: 1px solid #dadada;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    padding: 5px;
}
.pickup-modal label {
    opacity: .85;
    letter-spacing: .75px;
    text-transform: uppercase;
    font-size: 80%;
    font-weight: 500;
    color: #000;
}
.pickup-model .nav-item {
    text-transform: uppercase;
    font-family: Poppins;
    color: #000;
    text-align: center;
    margin-right: 0.75rem;
    border: 1px solid #dadada;
    border-top-left-radius: 10px !important;
    border-top-right-radius: 10px !important;
    background-color: #c6cbde;
}

.nav-item {
    font-size: 14px;
    font-weight: 400;
    text-transform: uppercase;
    font-family: "Poppins";
    color: #000000;
    text-align: center;
    margin-right: 0.75rem;
    border: 1px solid #DADADA;
    border-top-left-radius: 10px !important;
    border-top-right-radius: 10px !important;
    background-color: #C6CBDE;
}
.nav-tabs {
    border: none;
}
.nav-item:hover {
    border-top-left-radius: 10px !important;
    border-top-right-radius: 10px !important;
}
.nav-tabs .nav-link.active {
    font-weight: 500;
    background-color: white;
    border: 1px solid #DADADA;
    border-top-left-radius: 10px !important;
    border-top-right-radius: 10px !important;
}

.pickup-model .nav-tabs .nav-item .nav-link {
    padding: 0.5rem 2.25rem !important;
}
.pickup-model-primary-btn {
	background-color: #303e67;
	border-radius: 7px;
	font-size: 13px;
	font-weight: 400;
	color: white;
	cursor: pointer;
}
.pickup-model-secondary-btn {
	background-color: white;
	border-radius: 7px;
	font-size: 13px;
	font-weight: 400;
	color: black;
	cursor: pointer;
}
.pickup-model-danger-btn {
	background-color: red;
	border-radius: 7px;
	font-size: 13px;
	font-weight: 400;
	color: white;
	cursor: pointer;
	border: #ffffff;
}