.vgm-primary-listing-container .vgm-primary-listing-container__body {
    overflow-y: auto !important;
    max-height: 65vh !important;
    display: flex !important;
    flex-direction: column !important;
    background-color: white;
    border-radius: 10px;
    border: 1px solid #d7dce2;
}

.vgm-primary-listing-container .vgm-primary-listing-container__body table {
    width: 100% !important;
}
.vgm-primary-listing-container .vgm-primary-listing-container__body thead th {
    position: sticky !important;
    background-color: #f2f2f2 !important;
    top: -1px !important;
    cursor: pointer !important;
    padding-bottom: 15px !important;
    padding-top: 15px !important;
    border: none;
    text-transform: uppercase;
    color: rgba(42, 53, 84, .52);
    font-size: .8em;
    font-weight: 500;
    vertical-align: middle;
    letter-spacing: .005em;

}
.vgm-primary-listing-container .vgm-primary-listing-container__body thead tr th:first-child {
    border-top-left-radius: 10px;
}
.vgm-primary-listing-container .vgm-primary-listing-container__body thead tr th:last-child {
    border-top-right-radius: 10px;
}
.vgm-primary-listing-container .vgm-primary-listing-container__body tr:hover {
    background-color: #f5f5f5 !important;
}
.vgm-primary-listing-container .vgm-primary-listing-container__body::-webkit-scrollbar {
    width: 5px !important;
    height: 5px !important;
}
.vgm-primary-listing-container .vgm-primary-listing-container__body::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3) !important;
}
.vgm-primary-listing-container .vgm-primary-listing-container__body::-webkit-scrollbar-thumb {
    background-color: #303e67 !important;
    outline: 1px solid #303e67 !important;
}
.vgm-primary-listing-container .vgm-primary-listing-container__body::-webkit-scrollbar {
    margin-left: 10px !important;
}
.vgm-primary-listing-container .vgm-primary-listing-container__footer {
    margin-top: 2rem;
    margin-right: 1rem;
    margin-left: 1rem;
    text-align: center;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 1px;
}
.vgm-primary-listing-container .vgm-primary-listing-container__footer a {
    cursor: pointer;
    color: #007bff;
    text-decoration: none;
    background-color: initial;
}
.vgm-primary-listing-container .vgm-primary-listing-container__footer a:hover {
    color: #0056b3;
    text-decoration: underline;
    background-color: initial;
}